import React from 'react';
import styled from 'styled-components';

import { showCallbackForm } from 'stores/callbackForm';

import { Icon } from 'ui/atoms/Icon';
import { Highlight } from 'ui/atoms/Highlight';
import { ButtonGhost, ButtonIcon } from 'ui/atoms/Button';
import { BREAKPOINTS, COLORS } from 'ui/constants';
import { ExplanationBreadcrumbsStep } from 'ui/molecules/ExplanationBreadcrumbs';
import { navigate } from '@reach/router';
import { useMediaBreakpoint } from 'hooks/useMediaBreakpoint';

const BoringHint: React.FC = () => (
  <HintContent>
    <h1>Расчет стоимости</h1>
    <main>
      <Highlight>
        В зависимости от района глубина залегания водоносного известняка и геологическое строение меняется. В результате{' '}
        <strong>сложность бурения и конструкция скважины могут</strong> значительно <strong>отличаться</strong>.
      </Highlight>
    </main>
  </HintContent>
);

const WaterSupplyHint: React.FC = () => (
  <HintContent>
    <h1>Расчет стоимости</h1>
    <main>
      <Highlight>
        В зависимости от района глубина залегания водоносного известняка и геологическое строение меняется. В результате{' '}
        <strong>сложность бурения и конструкция скважины могут</strong> значительно <strong>отличаться</strong>.
      </Highlight>
    </main>
  </HintContent>
);

const SewerSystemHint: React.FC = () => (
  <HintContent>
    <h1>Расчет стоимости</h1>
    <main>
      <Highlight>
        В зависимости от района глубина залегания водоносного известняка и геологическое строение меняется. В результате{' '}
        <strong>сложность бурения и конструкция скважины могут</strong> значительно <strong>отличаться</strong>.
      </Highlight>
    </main>
  </HintContent>
);

interface ExplanationHintProps {
  currentStep: ExplanationBreadcrumbsStep;
}

export const ExplanationHint: React.FC<ExplanationHintProps> = ({ currentStep }) => {
  const { isTablet, isDesktop } = useMediaBreakpoint();

  const onCloseClick = () => {
    navigate('/');
  };

  const HintComponent = {
    [ExplanationBreadcrumbsStep.boring]: BoringHint,
    [ExplanationBreadcrumbsStep.waterSupply]: WaterSupplyHint,
    [ExplanationBreadcrumbsStep.sewerSystem]: SewerSystemHint,
  }[currentStep];
  return (
    <HintContainer>
      <CloseButton onClick={onCloseClick}>
        <Icon
          iconName="IconClose"
          size={
            {
              true: 32,
              [String(isDesktop)]: 24,
              [String(isTablet)]: 20,
            }.true
          }
        />
      </CloseButton>
      <Hint>
        <HintComponent />
      </Hint>
      <QuestionsLabel>
        Если у Вас остались вопросы, наши специалисты
        <br />
        помогут в выборе и сделают необходимые
        <br />
        расчеты с учетом Ваших пожеланий.
      </QuestionsLabel>
      <QuestionsButton
        onClick={() => {
          showCallbackForm();
        }}
      >
        <ButtonIcon>
          <Icon iconName="IconArrow45" size={18} rotate={270} color={COLORS.yellowtech} />
        </ButtonIcon>
        Свяжитесь с нами
      </QuestionsButton>
    </HintContainer>
  );
};

const HintContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0px 0 75px;

  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    padding: 0px 0 50px;

    h1 {
      max-width: 300px;
    }
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    padding-bottom: 50px;
    min-height: 400px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    min-height: 360px;
    padding-bottom: 40px;

    h1 {
      display: none;
    }
  }
`;

const Hint = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: stretch;
  margin-top: 105px;
  margin-bottom: 24px;

  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    margin-top: 60px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-top: 6px;
    margin-bottom: 15px;
  }
`;

const HintContent = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  h1 {
    font-weight: 400;
    font-size: 72px;
    line-height: 72px;
    margin-bottom: 86px;

    @media (max-width: ${BREAKPOINTS.desktopMax}px) {
      font-size: 52px;
      font-weight: 400;
      line-height: 52px;
      margin-bottom: 60px;
    }

    @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
      font-size: 36px;
      font-weight: 400;
      line-height: 36px;
      margin-bottom: 40px;
    }
  }

  main {
    flex: 1;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;

    @media (max-width: ${BREAKPOINTS.desktopMax}px) {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 42px;
    }

    @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 0;
    }
  }
`;

const CloseButton = styled(ButtonGhost)`
  align-self: flex-start;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    align-self: flex-end;
  }
  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    display: none;
  }
`;

const QuestionsLabel = styled.p`
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;

  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const QuestionsButton = styled(ButtonGhost)`
  width: auto;
  align-self: flex-start;
  margin-top: 30px;
  color: ${COLORS.yellowtech};

  > :first-child {
    margin-right: 15px;
  }
`;
