import {
  createEvent,
  createStore,
  restore,
  combine,
  forward,
} from "effector";

import {
  loadLocalStorageFx,
  saveLocalStorageFx,
} from "utils/localStorage";

const LOCAL_STORAGE_KEY = "boring";

export enum PumpPerformanceType {
  normal = 3,
  extended = 5,
};

export const PUMP_PERFORMANCE_OPTIONS: { label: string, value: PumpPerformanceType }[] = [
  { label: '3м³ в час', value: PumpPerformanceType.normal },
  { label: '5м³ в час', value: PumpPerformanceType.extended },
];

export enum PipeMaterialType {
  'metal' = 'metal',
  'metalplastic' = 'metalplastic',
  'pvcu' = 'pvcu',
};

export const PIPE_MATERIAL_OPTIONS: { label: string, value: PipeMaterialType }[] = [
  { label: 'Металлическая труба', value: PipeMaterialType.metal },
  { label: 'Металл + пластик', value: PipeMaterialType.metalplastic },
  { label: 'нПВХ', value: PipeMaterialType.pvcu },
];

loadLocalStorageFx(LOCAL_STORAGE_KEY);

export const resetBoring = createEvent();

export const setBoreholeLocation = createEvent<string>();
export const $boreholeLocation = restore(setBoreholeLocation, '')
  .on(
    loadLocalStorageFx.doneData, (_, { boreholeLocation }) => boreholeLocation,
  )
  .reset(resetBoring);

export const setBoreholeDepth = createEvent<number>();
export const $boreholeDepth = restore(setBoreholeDepth, 0)
  .on(
    loadLocalStorageFx.doneData, (_, { boreholeDepth }) => boreholeDepth,
  )
  .reset(resetBoring);

export const setPumpPerformance = createEvent<PumpPerformanceType>();
export const $pumpPerformance = restore(setPumpPerformance, PUMP_PERFORMANCE_OPTIONS[0].value)
  .on(
    loadLocalStorageFx.doneData, (_, { pumpPerformance }) => pumpPerformance,
  )
  .reset(resetBoring);

export const setPipeMaterial = createEvent<PipeMaterialType>();
export const $pipeMaterial = restore(setPipeMaterial, PIPE_MATERIAL_OPTIONS[1].value)
  .on(
    loadLocalStorageFx.doneData, (_, { pipeMaterial }) => pipeMaterial,
  )
  .reset(resetBoring);

export const toggleAdditionalHint = createEvent();
export const $additionalHintVisible = createStore(false)
  .on(
    toggleAdditionalHint,
    (additionalHintVisible) => !additionalHintVisible,
  )
  .on(
    loadLocalStorageFx.doneData, (_, { additionalHintVisible }) => additionalHintVisible,
  )
  .reset(resetBoring);

export const $boring = combine({
  boreholeLocation: $boreholeLocation,
  boreholeDepth: $boreholeDepth,
  pumpPerformance: $pumpPerformance,
  pipeMaterial: $pipeMaterial,
  additionalHintVisible: $additionalHintVisible,
});

forward({
  from: $boring,
  to: saveLocalStorageFx.prepend((value) => ({ value, localStorageKey: LOCAL_STORAGE_KEY })),
});
