import React, { useState } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from '@reach/router';

import { BREAKPOINTS, COLORS } from 'ui/constants';
import { useMediaBreakpoint } from 'hooks/useMediaBreakpoint';
import { MenuContent } from 'ui/templates/MenuContent';
import { MenuInformationPic } from 'ui/molecules/MenuInformationPic';
import { RoundButton } from 'ui/atoms/RoundButton';
import { Icon } from 'ui/atoms/Icon';
import { ToolTipDot, ToolTipText, ToolTipGuide } from 'ui/atoms/IllustrationToolTip';

import BoringBackURL from 'ui/molecules/MenuInformationPic/static/boring-bg.jpg';
import BGGuideLine1 from './static/guide-line-01.svg';
import BGGuideLine2 from './static/guide-line-02.svg';
import BGGuideLine3 from './static/guide-line-03.svg';
import BGGuideLine4 from './static/guide-line-04.svg';

export const MenuBoring: React.FC<RouteComponentProps> = () => {
  const [secondPageVisible, setSecondPageVisible] = useState(false);
  const toggleSecondPageVisible = () => {
    setSecondPageVisible((v) => !v);
  };
  const { isPhone } = useMediaBreakpoint();

  const Pic = (
    <MenuInformationPic backgroundURL={BoringBackURL}>
      <ToolTipDot1>
        <ToolTipText1>Первый водоносный слой.</ToolTipText1>
        <ToolTipGuide1 />
      </ToolTipDot1>

      <ToolTipDot2>
        <ToolTipText2>Скважина</ToolTipText2>
        <ToolTipGuide2 />
      </ToolTipDot2>

      <ToolTipDot3>
        <ToolTipText3>Водоносный известняк</ToolTipText3>
        <ToolTipGuide3 />
      </ToolTipDot3>

      <ToolTipDot4>
        <ToolTipText4>Водоупорный горизонт</ToolTipText4>
        <ToolTipGuide4 />
      </ToolTipDot4>
    </MenuInformationPic>
  );

  return (
    <MenuContent pic={Pic}>
      <BoringWrapper>
        {(isPhone || !secondPageVisible) && (
          <Text>
            Процесс бурения скважины на воду индивидуален в каждом случае, так как невозможно сопоставить все факторы –
            местность бурения, подземные условия, возможность использования крупной буровой техники. Но всегда
            последовательность этапов сохраняется, позволяя успешно создавать продуктивные скважины на воду.
          </Text>
        )}

        {(isPhone || secondPageVisible) && (
          <Text>
            Выполняется сверление скважинного колодца одним из подходящих способов – роторным, ударно-канатным,
            гидробурением, с использованием соответствующих буровых установок, вышек. В скважину опускаются обсадные
            трубы, выполняется обустройство, монтаж кессона, прокачка воды до чистого состояния с помощью мощного
            насоса.
          </Text>
        )}

        {!isPhone && (
          <ToggleButton
            onClick={toggleSecondPageVisible}
            icon={<Icon iconName="IconArrow" size={20} rotate={secondPageVisible ? 90 : 270} />}
            ghost
            small
          >
            {!secondPageVisible && <ToggleButtonLabel>еще</ToggleButtonLabel>}
          </ToggleButton>
        )}
      </BoringWrapper>
    </MenuContent>
  );
};

const BoringWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Text = styled.p`
  font-size: 16px;
  width: 350px;
  max-width: 100%;

  @media (min-width: ${BREAKPOINTS.desktopMin}px) {
    font-size: 18px;
    width: 480px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 18px;
    line-height: 1.4;
    width: 100%;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-bottom: 30px;
  }

  &:last-child {
    @media (max-width: ${BREAKPOINTS.phoneMax}px) {
      margin-bottom: 0;
    }
  }
`;

const ToggleButton = styled(RoundButton)`
  color: ${COLORS.blacktech};
  opacity: 0.7;
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 1;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: calc(100% + 40px);
  }
`;

const ToggleButtonLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  opacity: 0.65;
`;

const LocalToolTipDot = styled(ToolTipDot)`
  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    transform: scale(0.8);
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    transform: scale(0.9);
  }
`;

const ToolTipDot1 = styled(LocalToolTipDot)`
  left: 43%;
  top: 43%;
`;

const ToolTipText1 = styled(ToolTipText)`
  width: 225px;
  height: 85px;
  clip-path: polygon(100% 0, 100% 50%, 75% 100%, 0 100%, 0 0);
  bottom: 15px;
  right: 131px;
`;

const ToolTipGuide1 = styled(ToolTipGuide)`
  background-image: url(${BGGuideLine1});
  width: 136px;
  height: 78px;
  bottom: 0;
  right: 0;
`;

const ToolTipDot2 = styled(LocalToolTipDot)`
  left: 52%;
  top: 26%;
`;

const ToolTipText2 = styled(ToolTipText)`
  width: 148px;
  height: 62px;
  clip-path: polygon(0% 40%, 30% 100%, 100% 100%, 100% 0%, 0% 0%);
  bottom: -22px;
  left: 165px;
  text-align: right;
`;

const ToolTipGuide2 = styled(ToolTipGuide)`
  background-image: url(${BGGuideLine2});
  width: 171px;
  height: 32px;
  bottom: 0;
  left: 0;
`;

const ToolTipDot3 = styled(LocalToolTipDot)`
  left: 53%;
  top: 71%;
`;

const ToolTipText3 = styled(ToolTipText)`
  width: 214px;
  height: 62px;
  clip-path: polygon(100% 0, 100% 50%, 75% 100%, 0 100%, 0 0);
  bottom: -25px;
  right: 195px;
`;

const ToolTipGuide3 = styled(ToolTipGuide)`
  background-image: url(${BGGuideLine3});
  width: 201px;
  height: 31px;
  bottom: 0;
  right: 0;
`;

const ToolTipDot4 = styled(LocalToolTipDot)`
  left: 50%;
  top: 49%;
`;

const ToolTipText4 = styled(ToolTipText)`
  width: 231px;
  height: 62px;
  clip-path: polygon(0% 40%, 20% 100%, 100% 100%, 100% 0%, 0% 0%);
  bottom: -13px;
  left: 139px;
  text-align: right;
`;

const ToolTipGuide4 = styled(ToolTipGuide)`
  background-image: url(${BGGuideLine4});
  width: 146px;
  height: 44px;
  bottom: 0;
  left: 0;
`;
