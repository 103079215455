import React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';

import { MenuContent } from 'ui/templates/MenuContent';
import { HighlightStyles } from 'ui/atoms/Highlight';
import { BREAKPOINTS } from 'ui/constants';

export enum WaterSystemOption {
  summerOption,
  caisson,
  adapter,
}

interface MenuWaterSupplyProps {
  pic: React.ReactNode;
  description: React.ReactNode;
  currentStep: WaterSystemOption;
}

export const MenuWaterSupply: React.FC<MenuWaterSupplyProps> = ({ pic, currentStep, description }) => (
  <MenuContent
    pic={pic}
    subNavigation={
      <Switcher>
        <SwitchOption active={currentStep === WaterSystemOption.caisson}>
          <SwitchOptionLink to="/menu/water-supply/kesson">1. Кессон</SwitchOptionLink>
        </SwitchOption>
        <SwitchOption active={currentStep === WaterSystemOption.adapter}>
          <SwitchOptionLink to="/menu/water-supply/adapter">2. Адаптер</SwitchOptionLink>
        </SwitchOption>
        <SwitchOption active={currentStep === WaterSystemOption.summerOption}>
          <SwitchOptionLink to="/menu/water-supply/letnik">3. Летник</SwitchOptionLink>
        </SwitchOption>
      </Switcher>
    }
  >
    {description}
  </MenuContent>
);

const Switcher = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 40px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 0;
    padding: 17px 0;
    background-color: #fdfffc;
    border-top: 1px solid #eef0ec;
  }
`;

const SwitchOption = styled.li<{ active: boolean }>`
  display: inline;
  font-size: 20px;
  font-weight: ${(p) => (p.active ? 450 : 300)};
  white-space: nowrap;
  opacity: ${(p) => (p.active ? '1 !important' : '0.6')};
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 0.8;
  }

  :not(:first-of-type) {
    margin-top: 36px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 16px;
    line-height: 16px;
    margin-right: 50px;

    @media (max-width: ${BREAKPOINTS.phoneMax}px) {
      margin-right: 0;
    }

    :not(:first-of-type) {
      margin-top: 0;
    }
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    flex-direction: row;
  }

  &:last-child {
    @media (max-width: ${BREAKPOINTS.tabletMax}px) {
      margin-right: 0;
    }
  }

  ${(p) => p.active && HighlightStyles}
`;

const SwitchOptionLink = styled(Link)`
  font-weight: inherit;
  color: inherit;
`;
