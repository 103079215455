import React, { useRef } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { navigate } from '@reach/router';

import { showCallbackForm } from 'stores/callbackForm';

import { Icon } from 'ui/atoms/Icon';
import { ButtonGhost, ButtonIcon, ButtonPrimary } from 'ui/atoms/Button';
import { COLORS, BREAKPOINTS } from 'ui/constants';
import { useWindowSize } from 'hooks/useWindowSize';

interface PricesWrapperI {
  downloadDocLink?: string;
  title?: string | React.ReactNode;
}

export const PricesWrapper: React.FC<PricesWrapperI> = ({ children, downloadDocLink, title }) => {
  const windowSize = useWindowSize();
  const mainRef = useRef<HTMLDivElement | null>(null);

  const hasYOverflow =
    mainRef && mainRef.current?.scrollHeight && mainRef.current?.offsetHeight
      ? mainRef.current?.scrollHeight > mainRef.current?.offsetHeight
      : false;

  const hasXOverflow =
    mainRef && mainRef.current?.scrollWidth && mainRef.current?.offsetWidth
      ? mainRef.current?.scrollWidth > mainRef.current?.offsetWidth
      : false;

  const onCloseClick = () => {
    navigate('/');
  };

  const onBackClick = () => {
    navigate('/menu');
  };

  const onCalculateClick = () => {
    navigate('/calculator');
  };

  return (
    <Wrapper height={windowSize.height}>
      <MobHead>
        <MobHeadBackButton onClick={onBackClick}>
          <Icon iconName="IconArrow" size={22} rotate={90} />
        </MobHeadBackButton>

        <MobHeadCloseButton onClick={onCloseClick}>
          Вернуться на главную
          <MobHeadCloseButtonIcon iconName="IconClose" size={18} />
        </MobHeadCloseButton>
      </MobHead>

      <Head>
        {title && <Title>{title}</Title>}

        {downloadDocLink && (
          <HeadDownloadLink href={downloadDocLink} target="_blank">
            Скачать Прайс-лист <Icon iconName="IconArrow" />
          </HeadDownloadLink>
        )}
      </Head>

      <MainWrapper hasXOverflow={hasXOverflow} hasYOverflow={hasYOverflow}>
        <Main ref={mainRef}>{children}</Main>
      </MainWrapper>

      <Footer>
        {downloadDocLink && (
          <FooterDownloadLink href={downloadDocLink} target="_blank">
            Скачать Прайс-лист <Icon iconName="IconArrow" />
          </FooterDownloadLink>
        )}

        <ActionButtons>
          <ActionButtonsContactUs
            onClick={() => {
              showCallbackForm();
            }}
          >
            Связаться с нами
            <ButtonIcon>
              <Icon iconName="IconArrow45" size={18} rotate={90} />
            </ButtonIcon>
          </ActionButtonsContactUs>

          <ButtonPrimary onClick={onCalculateClick}>Рассчитать стоимость</ButtonPrimary>
        </ActionButtons>
      </Footer>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ height?: number }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 0 60px;
  padding-right: 40px;
  background-color: #fff;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    padding: 40px 60px;
    max-height: ${({ height }) => (height ? `${height}px` : '100vh')};
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    height: ${({ height }) => (height ? `${height}px` : '100vh')};
    max-height: none;
    padding: 20px;
  }
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 30px;
`;

const Title = styled.h3`
  font-size: 20px;
  line-height: 1.2;

  span {
    font-size: 18px;
    color: #78796d;
    font-weight: 300;

    @media (max-width: ${BREAKPOINTS.phoneMax}px) {
      display: block;
    }
  }
`;

const MainWrapper = styled.div<{ hasYOverflow: boolean; hasXOverflow: boolean }>`
  position: relative;
  overflow: hidden;
  min-height: 0;

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    overflow: auto;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    user-select: none;
    pointer-events: none;
    position: absolute;
    z-index: 1;

    @media (max-width: ${BREAKPOINTS.phoneMax}px) {
      display: none;
    }
  }

  &:before {
    width: ${({ hasYOverflow }) => (hasYOverflow ? 'calc(100% - 6px)' : '100%')};
    height: 50px;
    left: 0;
    bottom: ${({ hasXOverflow }) => (hasXOverflow ? '6px' : '0')};
    background-image: linear-gradient(to bottom, ${rgba('#fff', 0)}, ${rgba('#fff', 1)} 75%);
  }

  &:after {
    width: 50px;
    height: ${({ hasXOverflow }) => (hasXOverflow ? 'calc(100% - 6px)' : '100%')};
    top: 0;
    right: ${({ hasYOverflow }) => (hasYOverflow ? '6px' : '0')};
    background-image: linear-gradient(to right, ${rgba('#fff', 0)}, ${rgba('#fff', 1)} 75%);
  }
`;

const Main = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
  scrollbar-color: ${COLORS.yellowtech} ${COLORS.lightgraytech};
  scrollbar-width: thin;
  display: grid;
  grid-template-columns: 1fr;

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    height: auto;
    overflow: initial;
  }

  :hover {
    ::-webkit-scrollbar {
      display: block;
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${COLORS.lightgraytech};
    border-radius: 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLORS.yellowtech};
    border-radius: 0;
  }
`;

const DownloadLink = styled.a`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: #6f6f63;
  display: flex;
  align-items: center;

  ${Icon} {
    margin-left: 5px;
    width: 16px;
    height: 16px;
  }
`;

const HeadDownloadLink = styled(DownloadLink)`
  margin-left: auto;

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    display: none;
  }
`;

const FooterDownloadLink = styled(DownloadLink)`
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  @media (min-width: ${BREAKPOINTS.tabletMin}px) {
    display: none;
  }
`;

const MobHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 90px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-bottom: 20px;
  }

  @media (min-width: ${BREAKPOINTS.tabletMin}px) {
    display: none;
  }
`;

const MobHeadBackButton = styled.button`
  color: ${COLORS.blacktech};
  opacity: 0.65;
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 1;
  }
`;

const MobHeadCloseButtonIcon = styled(Icon)``;

const MobHeadCloseButton = styled.button`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  opacity: 0.65;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  opacity: 0.65;
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 1;
  }

  ${MobHeadCloseButtonIcon} {
    margin-left: 10px;
  }
`;

const Footer = styled.div`
  padding-top: 30px;
  display: flex;

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-left: 0;
    flex-direction: column;
  }
`;

const ActionButtonsContactUs = styled(ButtonGhost)`
  opacity: 0.65;

  &:not(:last-child) {
    margin-right: 30px;

    @media (max-width: ${BREAKPOINTS.phoneMax}px) {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }

  ${ButtonIcon} {
    margin-left: 10px;
  }
`;
