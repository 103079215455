import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper.scss';

import { API_ROOT } from 'hooks/useApiCall';
import { ReviewI } from 'hooks/useApiCall';
import { COLORS, BREAKPOINTS } from 'ui/constants';
import { Title as TitleDefault } from 'ui/atoms/Typography';
import { Container as ContainerDefault } from 'ui/atoms/Container';
import { Icon } from 'ui/atoms/Icon';
import { randomID } from 'utils/randomID';
import { SwiperArrowPrev, SwiperArrowNext } from 'ui/atoms/SwiperArrow';
import { Highlight } from 'ui/atoms/Highlight';
import { formatText } from 'utils/formatText';

import ImgBackgroudBigDesktop from './static/background-big-desktop.svg';
import ImgBackgroudPrimary from './static/background-primary.svg';
import ImgBackgroudTablet from './static/background-tablet.svg';
import IconQuoteStart from './static/quote-start.svg';

SwiperCore.use([Navigation, Pagination]);

interface ReviewsI {
  reviews: ReviewI[];
}

interface ReviewItemComponentProps {
  review: ReviewI;
}

const ReviewItemComponent: React.FC<ReviewItemComponentProps> = ({ review }) => {
  const { name, object, photo, message } = review;

  return (
    <ObjectItem>
      <ObjectItemHead>
        {photo?.url && (
          <ObjectItemPreview>
            <ObjectItemPreviewImg src={`${API_ROOT}${photo.url}`} alt="" />
          </ObjectItemPreview>
        )}

        <ObjectItemMeta>
          <ObjectItemName>{name}</ObjectItemName>
          {object?.district && <ObjectItemDistrict>{object.district}</ObjectItemDistrict>}
          {object?.objectNumber && object?.id && (
            <ObjectItemLink to={`/objects/${object.id}`}>
              {`Объект №${object.objectNumber}`} <Icon iconName="IconMove" rotate={0} size={24} />
            </ObjectItemLink>
          )}
        </ObjectItemMeta>
      </ObjectItemHead>

      {message && (
        <ObjectItemMessage>
          <ObjectItemMessageInner>{formatText(message)}</ObjectItemMessageInner>
        </ObjectItemMessage>
      )}
    </ObjectItem>
  );
};

export const Reviews: React.FC<ReviewsI> = (props) => {
  const { reviews } = props;
  // force reinitialize swiper for fix styles issue
  const [swiperKey, setSwiperKey] = useState(randomID(5));

  useEffect(() => {
    // reinitialize swiper
    setSwiperKey(randomID(5));
  }, [reviews]);

  // slider settings
  const sliderSettings: Swiper = {
    direction: 'vertical',
    spaceBetween: 20,
    slidesPerColumn: 1,
    slidesPerView: 4,
    slidesPerColumnFill: 'row',
    loop: false,
    allowTouchMove: false,
    navigation: {
      nextEl: `.${SliderArrowNext.styledComponentId}`,
      prevEl: `.${SliderArrowPrev.styledComponentId}`,
    },
    pagination: {
      el: `.${SliderCounter.styledComponentId}`,
      type: 'fraction',
      renderFraction(currentClass, totalClass) {
        return `<span class="${currentClass}"></span><span class="${totalClass}"></span>`;
      },
    },
    breakpoints: {
      [BREAKPOINTS.tabletMin]: {
        slidesPerColumn: 2,
        slidesPerView: 2,
        spaceBetween: 50,
      },
      [BREAKPOINTS.laptopSmallMin]: {
        allowTouchMove: true,
        slidesPerColumn: 2,
        slidesPerView: 2,
        spaceBetween: 50,
      },
    },
  };

  return (
    <Body id="reviews">
      <Container>
        <Title>Все клиенты довольны выполненной работой</Title>

        <SliderMeta>
          <SliderCounter />

          <SliderArrows>
            <SliderArrowNext>next</SliderArrowNext>
            <SliderArrowPrev>prev</SliderArrowPrev>
          </SliderArrows>
        </SliderMeta>

        <ObjectSliderWrapper key={swiperKey}>
          <ObjectSlider {...sliderSettings}>
            {reviews.map((review) => (
              <SwiperSlide key={review.id}>
                <ReviewItemComponent review={review} />
              </SwiperSlide>
            ))}
          </ObjectSlider>
        </ObjectSliderWrapper>
      </Container>
    </Body>
  );
};

const Body = styled.div`
  position: relative;
  z-index: 0;
  padding: 80px 0;
  background-color: #fff;
  background-image: url(${ImgBackgroudPrimary});
  background-position: center;
  background-size: cover;
  overflow: hidden;
  border-bottom: 4px solid #f8faf7;

  @media (min-width: ${BREAKPOINTS.desktopMin}px) {
    background-image: url(${ImgBackgroudBigDesktop});
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    background-image: url(${ImgBackgroudTablet});
    padding: 60px 0;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    background-image: url(${ImgBackgroudPrimary});
    padding: 40px 0;
  }
`;

const Container = styled(ContainerDefault)`
  display: grid;
  grid-template-areas:
    'title slider'
    'meta slider'
    'meta slider';
  grid-template-columns: 40% 60%;
  grid-template-rows: 1fr auto auto;

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    grid-template-columns: 37% 63%;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    grid-template-areas:
      'title title'
      'slider slider'
      'meta meta';
    grid-template-columns: 50% 50%;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    grid-template-areas:
      'title'
      'slider'
      'meta';
    grid-template-columns: 100%;
  }
`;

const Title = styled(TitleDefault)`
  width: 295px;
  grid-area: title;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    width: 190px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    width: 150px;
  }
`;

const SliderMeta = styled.div`
  grid-area: meta;
  align-self: end;
  margin-bottom: 80px;
  display: flex;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-bottom: 0;
    grid-column: 1/2 span;
    align-items: flex-end;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    justify-self: stretch;
  }
`;

const SliderCounter = styled.div`
  position: relative;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  height: 80px;
  width: 85px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    height: 115px;
    width: 50px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-left: 0;
    height: 42px;
    width: 50px;
  }

  &::before {
    content: '';
    display: block;
    width: 60px;
    height: 1px;
    background-color: #9a9c92;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);

    @media (max-width: ${BREAKPOINTS.tabletMax}px) {
      width: 75px;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    @media (max-width: ${BREAKPOINTS.phoneMax}px) {
      width: 30px;
    }
  }

  .swiper-pagination-current {
    align-self: flex-end;
    font-weight: 700;

    @media (max-width: ${BREAKPOINTS.tabletMax}px) {
      align-self: flex-start;
    }
  }

  .swiper-pagination-total {
    align-self: flex-start;

    @media (max-width: ${BREAKPOINTS.tabletMax}px) {
      align-self: flex-end;
    }
  }
`;

const SliderArrows = styled.div`
  display: flex;
  margin-left: 130px;
  justify-content: space-between;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-left: 75px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    flex-direction: column;
    margin-left: auto;
    flex-direction: row;
  }
`;

const SliderArrowNext = styled(SwiperArrowPrev)`
  transform: rotate(45deg);
  width: 64px;
  height: 64px;
  border-radius: 999px;
  background-size: 30% auto;
  border: solid 1px #c3c4bd;
  margin-right: 10px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-bottom: 10px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-right: 20px;
    margin-bottom: 0;
  }

  &:last-child {
    margin-right: 0;

    @media (max-width: ${BREAKPOINTS.tabletMax}px) {
      margin-bottom: 0;
    }

    @media (max-width: ${BREAKPOINTS.phoneMax}px) {
      margin-right: 0;
    }
  }
`;

const SliderArrowPrev = styled(SwiperArrowNext)`
  transform: rotate(-135deg);
  width: 64px;
  height: 64px;
  border-radius: 999px;
  background-size: 30% auto;
  border: solid 1px #c3c4bd;
  margin-right: 10px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-bottom: 10px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-right: 20px;
    margin-bottom: 0;
  }

  &:last-child {
    margin-right: 0;

    @media (max-width: ${BREAKPOINTS.tabletMax}px) {
      margin-bottom: 0;
    }

    @media (max-width: ${BREAKPOINTS.phoneMax}px) {
      margin-right: 0;
    }
  }
`;

const ObjectSliderWrapper = styled.div`
  grid-area: slider;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin: 90px 0 20px;
  }
`;

const ObjectSlider = styled(Swiper)`
  height: 640px;
  padding-top: 40px;
  margin-top: -40px;
  margin-right: -60px;
  padding-left: 30px;

  @media (max-width: ${BREAKPOINTS.laptopMax}px) {
    margin-right: -20px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-left: -30px;
    margin-right: -40px;
    height: 600px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    height: 1100px;
    margin-right: -20px;
  }

  .swiper-slide {
    width: 50%;
    margin-left: 0 !important;

    @media (max-width: ${BREAKPOINTS.phoneMax}px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    width: 50px;
    height: 50px;
  }
`;

const ObjectItemHead = styled.div`
  display: flex;
  align-items: center;
`;

const ObjectItemMeta = styled.div``;

const ObjectItemPreview = styled.div`
  max-width: 120px;
  width: 108px;
  height: 108px;
  flex: 0 0 108px;
  margin-right: 24px;
`;

const ObjectItemPreviewImg = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ObjectItemName = styled.h3`
  font-size: 22px;
  font-weight: 500;
  line-height: 1.3;
  text-transform: uppercase;
`;

const ObjectItemDistrict = styled.p`
  font-size: 20px;
  opacity: 0.7;
`;

const ObjectItemLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
  color: ${COLORS.navytech};
  padding-right: 30px;
  transition: color 0.2s ease-out;

  &:hover {
    color: ${COLORS.yellowtech};
  }

  &:not(:first-child) {
    margin-top: 20px;
  }

  ${Icon} {
    margin-left: 12px;
  }
`;

const ObjectItemMessage = styled.div`
  margin-top: 30px;
`;

const ObjectItemMessageInner = styled(Highlight)`
  position: relative;
  display: inline-block;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 116px;

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    height: 96px;
  }

  &::before {
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    position: absolute;
    z-index: 0;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(${IconQuoteStart});
    transform: rotate(180deg);
    right: 5px;
    top: -20px;
  }
`;

const ObjectItem = styled.div`
  position: relative;
  z-index: 5;

  &::before {
    content: '';
    display: block;
    width: 50px;
    height: 1px;
    background-color: ${COLORS.navytech};
    transform: rotate(-45deg) translate(-30px, -10px);
    transform-origin: left top;
    transition: background-color 0.2s ease-out;
  }

  &:hover {
    z-index: 10;

    &::before {
      background-color: ${COLORS.yellowtech};
    }
  }
`;
