import * as React from 'react';
import { Router, RouteComponentProps } from "@reach/router";

import { Explanation } from "components/Explanation";
import { CalculatorSewerSystem } from "./CalculatorSewerSystem";
import { CalculatorBoring } from "./CalculatorBoring";
import { CalculatorWaterSupply } from "./CalculatorWaterSupply";

export const Calculator: React.FC<RouteComponentProps> = () => (
  <Router>
    <CalculatorBoring path="/" />
    <CalculatorWaterSupply path="water-supply" />
    <CalculatorSewerSystem path="sewer-system" />
    <Explanation path="details/*" />
  </Router>
);
