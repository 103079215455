import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from '@reach/router';

import { BREAKPOINTS, COLORS } from 'ui/constants';
import { Container as ContainerDefault } from 'ui/atoms/Container';
import { Highlight } from 'ui/atoms/Highlight';
import { ButtonGhost, ButtonIcon } from 'ui/atoms/Button';
import { Icon } from 'ui/atoms/Icon';

import { showCallbackForm } from 'stores/callbackForm';
import ImgBanner1 from './static/banner-1.png';
import ImgBackgroundPhone from './static/background-phone.svg';
import ImgBackgroundTablet from './static/background-tablet.svg';
import ImgBackgroundPrimary from './static/background-primary.svg';

export const UnsupportedBrowser: React.FC<RouteComponentProps> = () => (
  <Wrapper>
    <Container>
      <TextWrapper>
        <Text>
          К сожалению, данный раздел сайта <TextDanger>не поддерживается вашим устройством.</TextDanger>{' '}
          <strong>Воспользуйтесь настольным компьютером или ноутбуком.</strong>
        </Text>
      </TextWrapper>

      <ContactsBlock>
        <ContactsLabel>Контакты</ContactsLabel>
        <ContactsList>
          <PhoneText href="tel:+74951015060">+7 (495) 101-50-60</PhoneText>
          <EmailText href="mailto:info@hydrotechpro.ru">info@hydrotechpro.ru</EmailText>
        </ContactsList>
      </ContactsBlock>

      <AddressBlock>
        <ContactsLabel>Адрес офиса</ContactsLabel>
        <AddressText>МО, г. Чехов, ул. Cенная, 20</AddressText>
      </AddressBlock>

      <Controls>
        <ControlsLink
          onClick={() => {
            showCallbackForm();
          }}
        >
          <span>Свяжитесь с нами</span>
          <ButtonIcon>
            <Icon iconName="IconArrow45" size={20} rotate={90} />
          </ButtonIcon>
        </ControlsLink>
      </Controls>

      <Preview>
        <PreviewImg src={ImgBanner1} />
      </Preview>
    </Container>
  </Wrapper>
);

const Wrapper = styled.div`
  background-color: #fff;
  background-image: url(${ImgBackgroundPrimary});
  background-position: top center;
  background-size: cover;
  min-height: 100vh;
  padding: 80px 0;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    background-image: url(${ImgBackgroundTablet});
    padding: 60px 0;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    background-image: url(${ImgBackgroundPhone});
    padding: 40px 0;
  }
`;

const Container = styled(ContainerDefault)`
  width: 500px;
`;

const TextWrapper = styled.div`
  width: 295px;
  max-width: 100%;
`;

const Text = styled(Highlight)`
  font-size: 20px;
  line-height: 30px;
`;

const TextDanger = styled.span`
  color: #e83b24;
`;

const ContactsLabel = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  color: ${COLORS.blacktech};
  opacity: 0.45;
`;

const AddressBlock = styled.div`
  margin-top: 55px;
`;

const AddressText = styled.div`
  margin-top: 5px;
  font-size: 14px;
  width: 220px;
  max-width: 100%;
`;

const ContactsBlock = styled.div`
  margin-top: 35px;
`;

const ContactsList = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-top: 5px;
  }
`;

const PhoneText = styled.a`
  font-size: 14px;
`;

const EmailText = styled.a`
  font-size: 14px;
  margin-top: 5px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-top: 5px;
  }
`;

const Preview = styled.div`
  margin-top: 45px;
`;

const PreviewImg = styled.img`
  display: block;
`;

const Controls = styled.div`
  margin-top: 45px;
  display: flex;
  justify-content: flex-end;
`;

const ControlsLink = styled(ButtonGhost)`
  color: ${COLORS.yellowtech};
  font-size: 14px;
  font-weight: 500;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    width: auto;
  }

  ${Icon} {
    color: ${COLORS.yellowtech};
  }
`;
