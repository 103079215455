import styled from 'styled-components';

import { BREAKPOINTS } from 'ui/constants';

import IconButtonArrow from './static/button-arrow.svg';

const SwiperArrow = styled.button`
  width: 24px;
  height: 24px;
  font-size: 0;
  background-color: transparent;
  background-image: url(${IconButtonArrow});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 95% auto;
  opacity: 0.7;
  transition: opacity 0.2s ease-out;
  cursor: pointer;
  user-select: none;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    width: 64px;
    height: 64px;
    border-radius: 999px;
    background-size: 30% auto;
    border: solid 1px #c3c4bd;
  }

  &:hover {
    opacity: 1;
  }
  
  :disabled {
    opacity: 0.3;
  }

  &.swiper-button-disabled {
    opacity: 0.3 !important;
  }
`;

export const SwiperArrowPrev = styled(SwiperArrow)`
  transform: rotate(-45deg);
`;

export const SwiperArrowNext = styled(SwiperArrow)`
  transform: rotate(135deg);
`;
