import React, {
  ReactNode,
  useImperativeHandle,
  useState,
} from 'react';

import styled, { keyframes } from 'styled-components';
import { rgba } from "polished";
import { COLORS } from "ui/constants";

const ANIMATION_DURATION = 300;

interface OrderFormInputProps {
  title: string,
  children: ReactNode,
  className?: string,
}

export const OrderFormInput = React.forwardRef(({
  title,
  children,
  className,
}: OrderFormInputProps, ref) => {
  const [accent, setAccent] = useState(false);

  const shake = () => {
    if (!accent) {
      setAccent(true);
      setTimeout(() => setAccent(false), ANIMATION_DURATION);
    }
  };

  useImperativeHandle(ref, () => ({
    shake,
  }));

  return (
    <OrderFormInputStyled className={className}>
      <Title accent={accent}>{title}</Title>
      {children}
    </OrderFormInputStyled>
  );
});

const OrderFormInputStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const shakeAnimation = keyframes`
  0% { margin-left: 0rem; }
  25% { margin-left: -0.25rem; }
  75% { margin-left: 0.25rem; }
  100% { margin-left: 0rem; }
`;

const Title = styled.p<{accent: boolean}>`
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 15px;
  color: ${(p) => (p.accent ? COLORS.redtech : rgba(COLORS.blacktech, 0.65))};
  
  animation: ${shakeAnimation} 300ms ease-in-out 0s ${(p) => (p.accent ? 'infinite' : 0)};
  
  transition: 
    color 
    ${(p) => (p.accent ? 0 : ANIMATION_DURATION)}ms 
    linear;
`;
