import * as React from 'react';
import styled from 'styled-components';
import { Icon } from "ui/atoms/Icon";
import { CustomCheckbox, Label } from "ui/atoms/Checkbox";

interface RadioSelectOption<T> {
  value: T,
  label: string,
};

interface RadioSelectProps<T> {
  value: T,
  name: string,
  options: Array<RadioSelectOption<T>>,
  onChange: (newValue: T) => void,
};

export function RadioSelect<T>({
  value,
  name,
  options,
  onChange,
}: RadioSelectProps<T>) {
  const onRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value as unknown as T);
  }

  return (
    <RadioGroup>
      {options.map(({
        label,
        value: optionValue,
      }) => {
        const checked = optionValue === value;
        return (
          <Label
            key={String(optionValue)}
            checked={checked}
          >
            <input
              type="radio"
              value={String(optionValue)}
              name={name}
              checked={checked}
              onChange={onRadioChange}
            />
            <CustomCheckbox checked={checked}>
              <Icon iconName="IconDone" />
            </CustomCheckbox>
            {label}
          </Label>
        );
      })}
    </RadioGroup>
  );
};

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
`;
