import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/swiper.scss';

import { Container as ContainerDefault } from 'ui/atoms/Container';
import { Title as TitleDefault, TitleSecondary as TitleSecondaryDefault } from 'ui/atoms/Typography';
import { COLORS, BREAKPOINTS } from 'ui/constants';
import { SwiperArrowPrev, SwiperArrowNext } from 'ui/atoms/SwiperArrow';
import { randomID } from 'utils/randomID';
import { useMediaBreakpoint } from 'hooks/useMediaBreakpoint';

import ImgBackgroundPrimary from './static/background-primary.svg';
import ImgBackgroundTablet from './static/background-tablet.svg';
import ImgBackgroundPhone from './static/background-phone.svg';

SwiperCore.use([Navigation, Pagination, Autoplay]);

export interface PartnersI {
  slides: { url: string; pic: string }[];
}

export const Partners: React.FC<PartnersI> = (props) => {
  const { slides } = props;
  // force reinitialize swiper for fix styles issue
  const [swiperKey, setSwiperKey] = useState(randomID(5));
  const { isPhone, isTablet, isTabletStrict, isLaptopSmall, isLaptop, isDesktop } = useMediaBreakpoint();

  useEffect(() => {
    setTimeout(() => {
      const k = randomID(5);
      setSwiperKey(k);
    }, 2000);
  }, [isPhone, isTablet, isTabletStrict, isLaptopSmall, isLaptop, isDesktop]);

  const sliderSettings: Swiper = {
    direction: 'vertical',
    spaceBetween: 60,
    slidesPerView: 2,
    slidesPerColumn: 2,
    slidesPerColumnFill: 'row',
    slidesPerGroup: 2,
    loop: false,
    autoplay: {
      delay: 6000,
    },
    navigation: {
      nextEl: `.${SliderArrowNext.styledComponentId}`,
      prevEl: `.${SliderArrowPrev.styledComponentId}`,
    },
    pagination: {
      el: `.${SliderCounter.styledComponentId}`,
      type: 'fraction',
      renderFraction(currentClass, totalClass) {
        return `<span class="${currentClass}"></span><span class="${totalClass}"></span>`;
      },
    },
    breakpoints: {
      [BREAKPOINTS.tabletMin]: {
        slidesPerColumn: 3,
      },
      [BREAKPOINTS.laptopSmallMin]: {
        slidesPerColumn: 3,
        pagination: {
          el: `.${SliderDots.styledComponentId}`,
          type: 'bullets',
          renderBullet(index, className) {
            return `<span class="${className}"></span>`;
          },
        },
      },
    },
  };

  return (
    <Body>
      <Container>
        <Main>
          <Title>Только проверенные поставщики и лидеры отрасли</Title>

          <DescBlockDesktop>
            <DescLabel>Наши партнеры</DescLabel>
          </DescBlockDesktop>

          <SliderCounter />
        </Main>

        <SliderWrapper key={swiperKey}>
          <SliderMain {...sliderSettings}>
            {slides.map(({ url, pic }, idx) => (
              <SwiperSlide key={idx}>
                <SliderItem href={url} target="_blank">
                  <SliderItemImg src={pic} />
                </SliderItem>
              </SwiperSlide>
            ))}
          </SliderMain>

          <SliderArrows>
            <SliderArrowPrev />
            <SliderArrowNext />
          </SliderArrows>

          <SliderDots />
        </SliderWrapper>

        <DescBlockMobile>
          <DescLabel>Наши партнеры</DescLabel>
        </DescBlockMobile>
      </Container>
    </Body>
  );
};

const Body = styled.div`
  position: relative;
  z-index: 0;
  padding: 80px 0;
  background-color: ${COLORS.secondaryBackground};
  background-image: url(${ImgBackgroundPrimary});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    padding: 60px 0;
    background-image: url(${ImgBackgroundTablet});
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    background-image: url(${ImgBackgroundPhone});
  }
`;

const Container = styled(ContainerDefault)`
  display: flex;
  align-items: flex-start;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    flex-direction: column;
  } ;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 42%;
  flex: 0 1 42%;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    flex-direction: row;
    align-items: center;
    width: 100%;
    flex: 0 1 100%;
  }
`;

const SliderWrapper = styled.div`
  position: relative;
  width: 58%;
  flex: 0 1 58%;
  padding-right: 100px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-top: 60px;
    width: 100%;
    flex: 0 1 100%;
    padding-right: 200px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    padding-right: 0;
  }
`;

const SliderMain = styled(Swiper)`
  height: 200px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    height: 130px;
  }

  .swiper-slide {
    width: 33.3333333%;
    margin-left: 0 !important;

    @media (max-width: ${BREAKPOINTS.phoneMax}px) {
      width: 50%;
    }
  }
`;

const SliderArrows = styled.div`
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    top: auto;
    bottom: 0;
  }
`;

const SliderArrowPrev = styled(SwiperArrowPrev)`
  transform: rotate(135deg);
  margin-bottom: 5px;
  width: 48px;
  height: 48px;
  border-radius: 999px;
  background-size: 30% auto;
  border: solid 1px #c3c4bd;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    display: none !important;
  }
`;

const SliderArrowNext = styled(SwiperArrowNext)`
  transform: rotate(-45deg);
  margin-bottom: 5px;
  width: 48px;
  height: 48px;
  border-radius: 999px;
  background-size: 30% auto;
  border: solid 1px #c3c4bd;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    display: none !important;
  }
`;

const SliderItem = styled.a`
  width: 230px;
  height: 50px;
  max-width: calc(100% - 40px);
  max-height: 100%;
  margin: 0 20px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  outline: none !important;
  user-select: none;

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    height: 35px;
  }
`;

const SliderItemImg = styled.img`
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
`;

const Title = styled(TitleDefault)`
  width: 300px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    width: 180px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    width: 140px;
  }
`;

const DescLabel = styled(TitleSecondaryDefault)``;

const DescBlockDesktop = styled.div`
  margin-top: 190px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-top: 0;
    margin-left: auto;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    display: none;
  }
`;

const DescBlockMobile = styled.div`
  display: none;

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    display: block;
    margin-top: 60px;
  }
`;

const SliderCounter = styled.div`
  position: relative;
  margin-left: auto;
  display: flex;
  height: 40px;

  @media (min-width: ${BREAKPOINTS.tabletMin}px) {
    display: none;
  }

  &::before {
    content: '';
    display: block;
    width: 30px;
    height: 1px;
    background-color: #9a9c92;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(40deg);
  }

  .swiper-pagination-current {
    font-size: 12px;
    font-weight: 700;
  }

  .swiper-pagination-total {
    font-size: 12px;
    align-self: flex-end;
    margin-left: 35px;
    opacity: 0.65;
  }
`;

const SliderDots = styled.div`
  position: absolute;
  z-index: 1;
  right: 22px;
  bottom: 0;
  display: flex;
  flex-direction: column;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    display: none !important;
  }

  .swiper-pagination-bullet {
    margin: 8px 0;
    width: 4px;
    height: 4px;
    flex: 0 0 4px;
    background-color: #c4c4c4;
    transition: margin 0.2s ease-out, height 0.2s ease-out;

    &:first-child {
      margin-top: 0 !important;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }

    &.swiper-pagination-bullet-active {
      margin: 16px 0;
      background-color: #ffa400;
      height: 18px;
      flex: 0 0 18px;
    }
  }
`;
