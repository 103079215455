import styled from 'styled-components';

import { BREAKPOINTS, COLORS } from 'ui/constants';

const Button = styled.button`
  border-radius: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    width: 100%;
  }
`;

export const ButtonGhost = styled(Button)`
  height: 14px;
  font-weight: 450;
  color: ${COLORS.blacktech};
  opacity: 0.65;
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 1;
  }
`;

export const ButtonPrimary = styled(Button)`
  height: 48px;
  padding: 14px 30px;
  color: ${COLORS.whitetech};
  background-color: ${COLORS.yellowtech};
  font-weight: 600;
  transition: background-color 0.2s ease-out, opacity 0.2s ease-out;

  &:hover {
    background-color: #ffba3d;
  }

  &:focus {
    background-color: #f9a207;
  }

  &:disabled {
    opacity: 0.3 !important;
    background-color: #ffba3d !important;
  }
`;

export const ButtonPrimarySmall = styled(ButtonPrimary)`
  height: 42px;
  padding: 14px 20px;
`;

export const ButtonIcon = styled.div`
  color: ${COLORS.blacktech};
  :first-child:not(:only-child) {
    margin-right: 12px;
  }

  :last-child:not(:only-child) {
    margin-left: 12px;
  }
`;
