import { StageI } from './StagesWork';

import ImgStage1 from './static/1_call_order.jpg';
import ImgStage2 from './static/2_expert_visit.jpg';
import ImgStage3 from './static/3_drilling.jpg';
import ImgStage4 from './static/4_additional_services.jpg';
import ImgStage5 from './static/5_water_supplied.jpg';

export const STAGES_WORK: StageI[] = [
  {
    label: 'Заявка',
    title: 'Звонок \n или онлайн заявка',
    description: 'Вы звоните или оставляете заявку на сайте, менеджер связывается с Вами для уточнения информации',
    previewIMG: ImgStage1,
  },
  {
    label: 'Смета',
    title: 'Выезд специалиста \n для составления сметы',
    description:
      'Наш специалист поможет определиться с фронтом работ на объекте, составит график их проведения и подробную смету',
    previewIMG: ImgStage2,
  },
  {
    label: 'Бурение',
    title: 'Заезд техники \n и бурение скважины',
    description:
      'Буровая бригада пробурит скважину с питьевой водой согласно графику работ и после принятия её клиентом выдаст паспорт скважины',
    previewIMG: ImgStage3,
  },
  {
    label: 'Обустройство',
    title: 'Обустройство \n и другие услуги',
    description:
      'Бригада монтажа установит водоподъемное оборудование, проведет коммуникации и смонтирует систему автономной канализации согласно смете и графику',
    previewIMG: ImgStage4,
  },
  {
    label: 'Прием и оплата',
    title: 'Акты приема \n и оплата',
    description:
      'Мы выдаем и подписываем акты на все выполненные работы, а вы проводите оплату согласно договору, с этого момента начинается срок выполнения гарантийных обязательств',
    previewIMG: ImgStage5,
  },
];
