import styled from 'styled-components';

import { BREAKPOINTS, COLORS } from 'ui/constants';
import { HighlightStyles } from 'ui/atoms/Highlight';

export const StyledText = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
    line-height: 1;
    display: block;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1 {
    font-size: 56px;

    @media (max-width: ${BREAKPOINTS.tabletMax}px) {
      font-size: 36px;
    }
  }

  h2 {
    font-size: 48px;

    @media (max-width: ${BREAKPOINTS.tabletMax}px) {
      font-size: 28px;
    }
  }

  h3 {
    font-size: 36px;

    @media (max-width: ${BREAKPOINTS.tabletMax}px) {
      font-size: 24px;
    }
  }

  h4 {
    font-size: 24px;

    @media (max-width: ${BREAKPOINTS.tabletMax}px) {
      font-size: 20px;
    }
  }

  h5 {
    font-size: 18px;

    @media (max-width: ${BREAKPOINTS.tabletMax}px) {
      font-size: 16px;
    }
  }

  h6 {
    font-size: 14px;
  }

  p {
    font-size: 24px;
    line-height: 1.3;
    font-weight: 300;
    margin-bottom: 30px;

    @media (max-width: ${BREAKPOINTS.tabletMax}px) {
      font-size: 18px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    li {
      font-size: 24px;
      font-weight: 500;
      color: ${COLORS.navytech};
      margin-bottom: 20px;
      list-style: inside '– ';

      @media (max-width: ${BREAKPOINTS.tabletMax}px) {
        font-size: 18px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  strong,
  a {
    ${HighlightStyles}
  }

  table {
    margin-bottom: 40px;
    border-collapse: collapse;

    &:last-child {
      margin-bottom: 0;
    }

    th {
      text-align: left;
      position: sticky;
      top: 0;
      background-color: #fff;
      padding: 15px 10px;

      &:first-child{
        padding-left: 0;
      }

      &:last-child{
        padding-right: 0;
      }
    }

    td {
      padding: 5px 10px;
      border-top: 1px solid #ffa400;
      vertical-align: top;

      &:first-child{
        padding-left: 0;
      }

      &:last-child{
        padding-right: 0;
      }

      &.nowrap {
        white-space: nowrap;
      }
    }
  }
`;
