import rehau from './static/logo-1.svg';
import buderus from './static/logo-2.svg';
import grundfos from './static/logo-4.svg';
import uponor from './static/logo-5.svg';
import severstal from './static/logo-7.svg';
import aquacenter from './static/logo-8.svg';
import tzpt from './static/logo-9.svg';
import reflex from './static/logo-10.svg';
import belamos from './static/logo-11.svg';
import topolEko from './static/logo-12.svg';
import wwsystem from './static/logo-13.svg';
import systemaPlasicovyhTruboprovodov from './static/logo-14.svg';
import unidelta from './static/logo-15.svg';
import ekogrand from './static/logo-16.svg';
import SBMGroup from './static/logo-17.svg';
import aquario from './static/logo-18.svg';

export const PARTNERS = [
  {
    url: 'https://uponor.ru',
    pic: uponor,
  },
  {
    url: 'https://www.buderus.ru/',
    pic: buderus,
  },
  {
    url: 'https://www.ac66.ru/',
    pic: aquacenter,
  },
  {
    url: 'https://rehau.com',
    pic: rehau,
  },
  {
    url: 'http://www.belamos.ru/',
    pic: belamos,
  },
  {
    url: 'http://www.unidelta.com/ru',
    pic: unidelta,
  },
  {
    url: 'https://ru.grundfos.com/',
    pic: grundfos,
  },
  {
    url: 'https://aquario.ru/',
    pic: aquario,
  },
  {
    url: 'https://sbm-group.ru',
    pic: SBMGroup,
  },
  {
    url: 'http://www.eco-grand.ru/',
    pic: ekogrand,
  },
  {
    url: 'https://www.topol-eco.ru/',
    pic: topolEko,
  },
  {
    url: 'https://www.reflex-winkelmann.com/ru/contact/reflex-v-rossii/',
    pic: reflex,
  },
  {
    url: 'https://wwsystem.ru/',
    pic: wwsystem,
  },
  {
    url: 'https://www.severstal.com/',
    pic: severstal,
  },
  {
    url: 'https://tz-pt.ru/',
    pic: tzpt,
  },
  {
    url: 'https://yartruba.com',
    pic: systemaPlasicovyhTruboprovodov,
  },
];
