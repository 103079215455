import React from 'react';
import reactStringReplace from 'react-string-replace';

export const formatText = (s: string) => {
  let result = reactStringReplace(s, /\*\*(.+?)\*\*/gm, (match, i, offset) => (
    <strong key={match + i + offset}>{match}</strong>
  ));

  result = reactStringReplace(result, /(\[.+?]\(.+?\))/gm, (match, i, offset) => {
    const [text, link] = /(\[(.+?)]\((.+?)\))/.exec(match)?.slice(2) || [];
    return (
      <a key={match + i + offset} href={link}>
        {text}
      </a>
    );
  });

  result = reactStringReplace(result, /(\n)/gm, (match, i, offset) => <br key={match + i + offset} />);
  result = reactStringReplace(result, /(\\n)/gm, (match, i, offset) => <br key={match + i + offset} />);

  return result;
};

export const formatCost = (v: number) => v > 1000 ? `${Math.floor(v / 1000)} тыс.` : v;

export const pluralizeMeters = (val: number) => {
  const s = String(val);
  return ({
    true: 'метров',
    [String(s.endsWith('1'))]: 'метр',
    [String(['2', '3', '4'].some((v) => s.endsWith(v)))]: 'метра',
    [String(['11', '12', '13', '14'].some((v) => s.endsWith(v)))]: 'метров',
  }.true);
};
