import * as React from 'react';
import Select from 'react-select';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { BREAKPOINTS, COLORS } from 'ui/constants';
import IconDropdownURL from 'ui/atoms/Icon/static/Dropdown.svg';

export interface DropdownSelectOption<T> {
  label: string;
  value: T;
}

interface DropdownSelectProps<T> {
  value: T;
  options: Array<DropdownSelectOption<T>>;
  onChange: (newValue: T) => void;
}

export function DropdownSelect<T>({ value, options, onChange }: DropdownSelectProps<T>) {
  const onDropdownChange = (v: DropdownSelectOption<T>) => {
    onChange(v.value);
  };

  return (
    <StyledSelect
      classNamePrefix="react-select"
      value={options.find((o) => o.value === value)}
      name="color"
      options={options}
      onChange={onDropdownChange}
      isSearchable={false}
    />
  );
}

export const ReactSelectStyles = css`
  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-top: -10px;
  }

  .react-select__clear-indicator {
  }
  .react-select__container {
  }
  .react-select__control {
    cursor: pointer;
    border: none;
    box-shadow: none;

    :hover {
      color: ${COLORS.navytech};
    }

    &--menu-is-open .react-select__single-value {
      ::after {
        transform: rotate(90deg);
      }
    }

    &--is-focused {
      .react-select__value-container {
        border-color: orange;
      }
    }
  }
  .react-select__dropdown-indicator {
    display: none;
  }
  .react-select__group {
  }
  .react-select__group-heading {
  }
  .react-select__indicators-container {
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__input {
    font-size: 28px;
    font-weight: 400;
    color: ${COLORS.blacktech};
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;

    @media (max-width: ${BREAKPOINTS.desktopMax}px) {
      font-size: 18px;
      line-height: 18px;
    }

    @media (max-width: ${BREAKPOINTS.tabletMax}px) {
      font-size: 16px;
      line-height: 16px;
    }

    & > input {
      width: 100% !important;
    }
  }
  .react-select__loading-indicator {
  }
  .react-select__loading-message {
  }
  .react-select__menu {
    margin-top: 20px;
    border-radius: 0;
    border: none;
    box-shadow: 0px 17px 30px 0px ${rgba('#0A0908', 0.05)};

    @media (max-width: ${BREAKPOINTS.phoneMax}px) {
      margin-top: -5px;
    }
  }
  .react-select__menu-list {
    padding: 10px 0;
  }
  .react-select__menu-portal {
  }
  .react-select__multi-value {
  }
  .react-select__multi-value-label {
  }
  .react-select__multi-value-remove {
  }
  .react-select__no-options-message {
  }
  .react-select__option {
    padding: 15px 0 15px 28px;
    &.react-select__option--is-selected,
    &.react-select__option--is-focused {
      color: ${COLORS.navytech};
      background-color: ${rgba(COLORS.navytech, 0.05)};
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }
  .react-select__placeholder {
    font-size: 26px;
    font-weight: 400;
    color: ${COLORS.blacktech};
    opacity: 0.45;
    position: relative;
    top: 0;
    left: 0;
    transform: none;

    @media (max-width: ${BREAKPOINTS.desktopMax}px) {
      font-size: 18px;
      line-height: 18px;
    }

    @media (max-width: ${BREAKPOINTS.tabletMax}px) {
      font-size: 16px;
      line-height: 16px;
    }
  }
  .react-select__single-value {
    font-size: 28px;
    font-weight: 400;
    line-height: 28px;
    margin: 0;
    display: flex;
    align-items: flex-end;
    color: inherit;
    padding-right: 25px;
    overflow: visible;
    text-overflow: clip;
    white-space: normal;
    position: relative;
    top: 0;
    left: 0;
    transform: none;

    &::after {
      content: '';
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('${IconDropdownURL}');
      background-size: contain;
      margin-left: 12px;
      font-size: 18px;
      width: 20px;
      height: 24px;
      position: absolute;
      top: 2px;
      right: 0;
      transition: transform 0.2s ease-out;

      @media (max-width: ${BREAKPOINTS.desktopMax}px) {
        height: 16px;
      }

      @media (max-width: ${BREAKPOINTS.tabletMax}px) {
        height: 14px;
      }
    }

    @media (max-width: ${BREAKPOINTS.desktopMax}px) {
      font-size: 18px;
      line-height: 18px;
    }

    @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
      font-size: 16px;
      line-height: 16px;
    }
  }

  .react-select__value-container {
    padding: 1px 0;
    min-height: 42px;
    border-bottom: 2px solid #eee;
    transition: border-color 0.2s ease;

    @media (max-width: ${BREAKPOINTS.desktopMax}px) {
      min-height: 28px;
    }

    @media (max-width: ${BREAKPOINTS.tabletMax}px) {
      min-height: 24px;
    }
  }
`;

const StyledSelect = styled(Select)`
  ${ReactSelectStyles}
`;
