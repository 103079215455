import {createEvent, restore, combine, forward} from "effector";
import {
  loadLocalStorageFx,
  saveLocalStorageFx,
} from "utils/localStorage";

const LOCAL_STORAGE_KEY = "userInfo";

loadLocalStorageFx(LOCAL_STORAGE_KEY);

export const setPhoneNumber = createEvent<string>();
export const $phoneNumber = restore(setPhoneNumber, '')
  .on(
    loadLocalStorageFx.doneData, (_, { phoneNumber }) => phoneNumber,
  );

export const setEmail = createEvent<string>();
export const $email = restore(setEmail, '')
  .on(
    loadLocalStorageFx.doneData, (_, { email }) => email,
  );

export const $userInfo = combine({
  phoneNumber: $phoneNumber,
  email: $email,
});

forward({
  from: $userInfo,
  to: saveLocalStorageFx.prepend((value) => ({ value, localStorageKey: LOCAL_STORAGE_KEY })),
});
