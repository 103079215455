import React from 'react';
import { RouteComponentProps } from '@reach/router';

import { ObjectInfo } from 'ui/organisms/ObjectInfo';
import { Header } from 'ui/organisms/Header';
import { useObjectApi, useObjectsApi } from 'hooks/useApiCall';

interface ObjectPageProps extends RouteComponentProps {
  objectId?: string;
}

export const ObjectPage = ({ objectId }: ObjectPageProps) => {
  const id = objectId || '';
  const { data } = useObjectApi(id);
  const { data: objects } = useObjectsApi();
  const currentObjectIndex = objects?.findIndex((o) => o.id === Number(id));
  const nextObject = currentObjectIndex === undefined ? undefined : objects?.[currentObjectIndex + 1] || objects?.[0];

  return (
    <>
      <Header />
      <ObjectInfo
        objectNumber={data?.objectNumber || 0}
        name={data?.name || ''}
        feedbackText={data?.feedbackText || ''}
        feedbackLink={data?.feedbackLink || ''}
        wasBoring={data?.wasBoring || false}
        wasWaterSupply={data?.wasWaterSupply || false}
        wasSewerSystem={data?.wasSewerSystem || false}
        photos={data?.photos || []}
        boringCost={data?.boringCost || 0}
        boringDuration={data?.boringDuration || 0}
        boringInstallation={data?.boringInstallation || ''}
        boringProcedures={data?.boringProcedures || ''}
        waterSupplyCost={data?.waterSupplyCost || 0}
        waterSupplyDuration={data?.waterSupplyDuration || 0}
        waterSupplyInstallation={data?.waterSupplyInstallation || ''}
        waterSupplyProcedures={data?.waterSupplyProcedures || ''}
        sewerSystemCost={data?.sewerSystemCost || 0}
        sewerSystemDuration={data?.sewerSystemDuration || 0}
        sewerSystemInstallation={data?.sewerSystemInstallation || ''}
        sewerSystemProcedures={data?.sewerSystemProcedures || ''}
        nextObject={nextObject}
      />
    </>
  );
};
