import styled, { css } from 'styled-components';
import { COLORS } from 'ui/constants';
import { rgba } from 'polished';

export const HighlightStyles = css`
  color: ${COLORS.navytech};
  font-weight: 600;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    180deg,
    ${COLORS.transparent} 0%,
    ${COLORS.transparent} 60%,
    ${rgba(COLORS.navytech, 0.1)} 60%,
    ${rgba(COLORS.navytech, 0.1)} 100%
  );
`;

export const Highlight = styled.span`
  strong,
  a {
    ${HighlightStyles}
  }
`;
