import * as React from 'react';
import { RouteComponentProps } from '@reach/router';

import { useBrandBanners, useObjectsApi, useReviews } from 'hooks/useApiCall';
import orderBy from 'lodash/orderBy';

import { Header } from 'ui/organisms/Header';
import { BrandBanner } from 'ui/organisms/BrandBanner';
import { BRAND_BANNER } from 'ui/organisms/BrandBanner/brand-banner-fixture';
import { AboutUs } from 'ui/organisms/AboutUs';
import { ABOUT_US } from 'ui/organisms/AboutUs/about-us-fixture';
import { StagesWork } from 'ui/organisms/StagesWork';
import { STAGES_WORK } from 'ui/organisms/StagesWork/stages-work-fixture';
import { Partners } from 'ui/organisms/Partners';
import { PARTNERS } from 'ui/organisms/Partners/partnets-fixture';
import { OurWorks } from 'ui/organisms/OurWorks';
import { Reviews } from 'ui/organisms/Reviews';
import { Footer } from 'ui/organisms/Footer';

export const MainPage: React.FC<RouteComponentProps> = () => {
  const { data: brandBanners } = useBrandBanners();
  const { data: objectsData } = useObjectsApi();
  const { data: reviewsData } = useReviews();

  const banners = brandBanners?.length ? orderBy(brandBanners, 'order') : BRAND_BANNER;

  const works =
    objectsData?.map((o) => ({
      id: o.id,
      name: `Объект №${o.objectNumber}`,
      address: o.district,
      tags: [
        ...(o.wasBoring ? ['Скважина'] : []),
        ...(o.wasWaterSupply ? ['Водоснабжение'] : []),
        ...(o.wasSewerSystem ? ['Канализация'] : []),
      ],
      previewTooltipImage: o.photos?.[0]?.url,
    })) || [];

  const reviews = reviewsData?.length ? reviewsData : [];

  return (
    <>
      <Header />
      <BrandBanner brandBannerItems={banners} />
      <AboutUs aboutItems={ABOUT_US} />
      <Reviews reviews={reviews} />
      <StagesWork stages={STAGES_WORK} />
      <Partners slides={PARTNERS} />
      <OurWorks works={works} />
      <Footer />
    </>
  );
};
