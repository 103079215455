import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper.scss';

import { API_ROOT } from 'hooks/useApiCall';

import { COLORS, BREAKPOINTS } from 'ui/constants';
import { Title as TitleDefault } from 'ui/atoms/Typography';
import { Container as ContainerDefault } from 'ui/atoms/Container';
import { Icon } from 'ui/atoms/Icon';
import { randomID } from 'utils/randomID';
import { SwiperArrowPrev, SwiperArrowNext } from 'ui/atoms/SwiperArrow';
import { Overlay } from 'ui/atoms/Overlay';

import ImgBackgroudBigDesktop from './static/background-big-desktop.svg';
import ImgBackgroudPrimary from './static/background-primary.svg';
import ImgBackgroudTablet from './static/background-tablet.svg';

SwiperCore.use([Navigation, Pagination]);

interface ObjectItemComponentProps {
  work: WorkI;
}

const ObjectItemComponent: React.FC<ObjectItemComponentProps> = ({ work }) => {
  const [{ x, y }, setXY] = useState({ x: 0, y: 0 });

  const onMouseMove = (e: React.MouseEvent) => {
    setXY({
      x: e.clientX,
      y: e.clientY,
    });
  };

  const [showPreview, setShowPreview] = useState(false);

  const onMouseEnter = () => {
    setShowPreview(true);
  };

  const onMouseLeave = () => {
    setShowPreview(false);
  };

  return (
    <ObjectItem onMouseMove={onMouseMove} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {showPreview && (
        <OverlayLocal>
          <ObjectItemPreview x={x} y={y} src={`${API_ROOT}${work.previewTooltipImage}`} />
        </OverlayLocal>
      )}
      <ObjectItemName to={`/objects/${work.id}`}>
        {work.name}
        <Icon iconName="IconMove" rotate={0} size={24} />
      </ObjectItemName>
      <ObjectItemAddress>{work.address}</ObjectItemAddress>
      {work.tags.length && (
        <ObjectItemTagList>
          {work.tags.map((tag) => (
            <ObjectItemTagItem key={tag}>{tag}</ObjectItemTagItem>
          ))}
        </ObjectItemTagList>
      )}
    </ObjectItem>
  );
};

export interface WorkI {
  id: number;
  name: string;
  address: string;
  tags: string[];
  previewTooltipImage: string;
}

interface OurWorksI {
  works: WorkI[];
}

export const OurWorks: React.FC<OurWorksI> = (props) => {
  const { works } = props;
  const [tagActive, setTagActive] = useState<string | null>(null);
  // force reinitialize swiper for fix styles issue
  const [swiperKey, setSwiperKey] = useState(randomID(5));
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore | null>(null);

  useEffect(() => {
    // reinitialize swiper
    setSwiperKey(randomID(5));
  }, [works]);

  // slider settings
  const sliderSettings: Swiper = {
    direction: 'vertical',
    spaceBetween: 40,
    slidesPerColumn: 1,
    slidesPerView: 2,
    slidesPerColumnFill: 'row',
    loop: false,
    allowTouchMove: false,
    navigation: {
      nextEl: `.${SliderArrowNext.styledComponentId}`,
      prevEl: `.${SliderArrowPrev.styledComponentId}`,
    },
    pagination: {
      el: `.${SliderCounter.styledComponentId}`,
      type: 'fraction',
      renderFraction(currentClass, totalClass) {
        return `<span class="${currentClass}"></span><span class="${totalClass}"></span>`;
      },
    },
    breakpoints: {
      [BREAKPOINTS.tabletMin]: {
        slidesPerColumn: 2,
        slidesPerView: 3,
        spaceBetween: 50,
      },
      [BREAKPOINTS.laptopSmallMin]: {
        allowTouchMove: true,
        slidesPerColumn: 2,
        slidesPerView: 3,
        spaceBetween: 50,
      },
    },
    onSwiper: setSwiperInstance,
  };

  // get tag list
  const tagsSet = new Set<string>();
  works.forEach((work) => work.tags.forEach((tag) => tagsSet.add(tag.trim().toLowerCase())));
  const tags = Array.from(tagsSet);

  // filter works
  const worksFiltered = works.filter((work) => {
    // if not active tag - return all
    if (!tagActive) return true;

    // filter
    return work.tags.map((tag) => tag.toLowerCase()).includes(tagActive);
  });

  // handlers
  const setActiveTag = (tag: string) => {
    if (tagActive === tag) {
      setTagActive(null);
      return;
    }

    setTagActive(tag);
    // reinitialize swiper
    setSwiperKey(randomID(5));
  };

  useEffect(() => {
    swiperInstance?.update();
  }, [swiperInstance, tagActive]);

  return (
    <Body id="our-works">
      <Container>
        <Title>Работаем качественно в кратчайшие сроки</Title>

        <SliderMeta>
          <SliderCounter />

          <SliderArrows>
            <SliderArrowNext>next</SliderArrowNext>
            <SliderArrowPrev>prev</SliderArrowPrev>
          </SliderArrows>
        </SliderMeta>

        <Tags>
          <TagsTitle>поиск объектов по категории:</TagsTitle>
          <TagsList>
            {tags.map((tag, idx) => (
              <TagsItem key={idx} active={tag === tagActive} onClick={() => setActiveTag(tag)}>
                {tag}
              </TagsItem>
            ))}
          </TagsList>
        </Tags>

        <ObjectSliderWrapper key={swiperKey}>
          <ObjectSlider {...sliderSettings}>
            {worksFiltered.map((work) => (
              <SwiperSlide key={work.id}>
                <ObjectItemComponent work={work} />
              </SwiperSlide>
            ))}
          </ObjectSlider>
        </ObjectSliderWrapper>
      </Container>
    </Body>
  );
};

const Body = styled.div`
  position: relative;
  z-index: 0;
  padding: 80px 0;
  background-color: #fff;
  background-image: url(${ImgBackgroudPrimary});
  background-position: center;
  background-size: cover;
  overflow: hidden;

  @media (min-width: ${BREAKPOINTS.desktopMin}px) {
    background-image: url(${ImgBackgroudBigDesktop});
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    background-image: url(${ImgBackgroudTablet});
    padding: 60px 0;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    background-image: url(${ImgBackgroudPrimary});
    padding: 40px 0;
  }
`;

const Container = styled(ContainerDefault)`
  display: grid;
  grid-template-areas:
    'title slider'
    'meta slider'
    'tags slider';
  grid-template-columns: 40% 60%;
  grid-template-rows: 1fr auto auto;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    grid-template-areas:
      'title title'
      'slider slider'
      'tags meta';
    grid-template-columns: 50% 50%;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    grid-template-areas:
      'title'
      'tags'
      'slider'
      'meta';
    grid-template-columns: 100%;
  }
`;

const Title = styled(TitleDefault)`
  width: 295px;
  grid-area: title;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    width: 190px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    width: 150px;
  }
`;

const SliderMeta = styled.div`
  grid-area: meta;
  align-self: end;
  margin-bottom: 80px;
  display: flex;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-bottom: 0;
    justify-self: end;
    align-items: flex-end;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    justify-self: stretch;
  }
`;

const SliderCounter = styled.div`
  position: relative;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  height: 80px;
  width: 85px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-left: auto;
    height: 115px;
    width: 50px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-left: 0;
    height: 42px;
    width: 50px;
  }

  &::before {
    content: '';
    display: block;
    width: 60px;
    height: 1px;
    background-color: #9a9c92;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);

    @media (max-width: ${BREAKPOINTS.tabletMax}px) {
      width: 75px;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    @media (max-width: ${BREAKPOINTS.phoneMax}px) {
      width: 30px;
    }
  }

  .swiper-pagination-current {
    align-self: flex-end;
    font-weight: 700;

    @media (max-width: ${BREAKPOINTS.tabletMax}px) {
      align-self: flex-start;
    }
  }

  .swiper-pagination-total {
    align-self: flex-start;

    @media (max-width: ${BREAKPOINTS.tabletMax}px) {
      align-self: flex-end;
    }
  }
`;

const SliderArrows = styled.div`
  display: flex;
  margin-left: 130px;
  justify-content: space-between;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    flex-direction: column;
    margin-left: 75px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-left: auto;
    flex-direction: row;
  }
`;

const SliderArrowNext = styled(SwiperArrowPrev)`
  transform: rotate(45deg);
  width: 64px;
  height: 64px;
  border-radius: 999px;
  background-size: 30% auto;
  border: solid 1px #c3c4bd;
  margin-right: 10px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-bottom: 10px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-right: 20px;
    margin-bottom: 0;
  }

  &:last-child {
    margin-right: 0;

    @media (max-width: ${BREAKPOINTS.tabletMax}px) {
      margin-bottom: 0;
    }

    @media (max-width: ${BREAKPOINTS.phoneMax}px) {
      margin-right: 0;
    }
  }
`;

const SliderArrowPrev = styled(SwiperArrowNext)`
  transform: rotate(-135deg);
  width: 64px;
  height: 64px;
  border-radius: 999px;
  background-size: 30% auto;
  border: solid 1px #c3c4bd;
  margin-right: 10px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-bottom: 10px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-right: 20px;
    margin-bottom: 0;
  }

  &:last-child {
    margin-right: 0;

    @media (max-width: ${BREAKPOINTS.tabletMax}px) {
      margin-bottom: 0;
    }

    @media (max-width: ${BREAKPOINTS.phoneMax}px) {
      margin-right: 0;
    }
  }
`;

const Tags = styled.div`
  grid-area: tags;
  align-self: end;
  margin-bottom: 50px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-bottom: 0;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-top: 40px;
  }
`;

const TagsTitle = styled.div`
  text-transform: uppercase;
  font-size: 16px;
  opacity: 0.45;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 14px;
  }
`;

const TagsList = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, max-content));
  justify-items: start;
  gap: 20px;
`;

const TagsItem = styled.div<{ active: boolean }>`
  position: relative;
  z-index: 0;
  color: ${({ active }) => (active ? COLORS.navytech : COLORS.blacktech)};
  font-size: 24px;
  line-height: 1.1;
  font-weight: ${({ active }) => (active ? '700' : '400')};
  text-transform: capitalize;
  cursor: pointer;
  user-select: none;
  transition: font-weight 0.2s ease-out, color 0.2s ease-out;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 20px;
  }

  &:hover {
    color: ${COLORS.navytech};
  }

  &::before {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: 45%;
    background-color: ${COLORS.navytech};
    opacity: ${({ active }) => (active ? '0.1' : '0')};
    transition: opacity 0.2s ease-out;
  }
`;

const ObjectSliderWrapper = styled.div`
  grid-area: slider;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin: 90px 0 20px;
  }
`;

const ObjectSlider = styled(Swiper)`
  height: 690px;
  padding-top: 40px;
  margin-top: -40px;
  padding-left: 60px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-left: -60px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    height: 460px;
  }

  .swiper-slide {
    width: 50%;
    margin-left: 0 !important;

    @media (max-width: ${BREAKPOINTS.phoneMax}px) {
      width: 100%;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    width: 50px;
    height: 50px;
    background-color: red;
  }
`;

interface ObjectItemPreviewProps {
  x: number;
  y: number;
}

const ObjectItemPreview = styled.img.attrs<ObjectItemPreviewProps>((props) => ({
  style: {
    left: `${props.x + 20}px`,
    top: `${props.y + 20}px`,
  },
}))<ObjectItemPreviewProps>`
  z-index: 50;
  position: absolute;
  overflow: hidden;
  max-width: 300px;
  max-height: 300px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    display: none !important;
  }
`;

const OverlayLocal = styled(Overlay)`
  pointer-events: none;
  background-color: transparent;
`;

const ObjectItemName = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-size: 24px;
  line-height: 1;
  font-weight: 700;
  color: ${COLORS.navytech};
  padding-right: 30px;
  transition: color 0.2s ease-out;

  &:hover {
    color: ${COLORS.navytech};
  }

  ${Icon} {
    margin-left: 12px;
  }
`;

const ObjectItem = styled.div`
  position: relative;
  z-index: 5;

  &::before {
    content: '';
    display: block;
    width: 60px;
    height: 1px;
    background-color: ${COLORS.navytech};
    transform: rotate(-45deg) translate(-45px, -15px);
    transform-origin: left top;
    transition: background-color 0.2s ease-out;
  }

  &:hover {
    z-index: 10;

    &::before {
      background-color: ${COLORS.yellowtech};
    }

    ${ObjectItemName} {
      color: ${COLORS.yellowtech};
    }
  }
`;

const ObjectItemAddress = styled.div`
  margin-top: 10px;
  font-size: 20px;
`;

const ObjectItemTagList = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, max-content));
  grid-gap: 10px;
`;

const ObjectItemTagItem = styled.div`
  font-size: 16px;
  opacity: 0.65;
`;
