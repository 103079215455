import * as React from 'react';
import styled from 'styled-components';

import { navigate, RouteComponentProps } from "@reach/router";

import { useCalculations } from "hooks/useCalculations";

import {
  ExplanationBreadcrumbsStep,
} from "ui/molecules/ExplanationBreadcrumbs";
import { ExplanationControl } from "ui/molecules/ExplanationControl";

import { formatCost, formatText } from "utils/formatText";
import { Important, ListItem } from "./atoms";
import { ExplanationStepTemplate } from "./ExplanationStepTemplate";

export const ExplanationBoring: React.FC<RouteComponentProps> = () => {
  const {
    calculations: {
      boringCost,
    },
    explanationTexts: {
      boring: {
        timing,
        procedures,
        materials,
      },
    },
  } = useCalculations();

  const goToCalculator = () => { navigate('/calculator/') };

  const onCloseButtonClick = () => { navigate('/'); };

  return (
    <ExplanationStepTemplate
      onCloseButtonClick={onCloseButtonClick}
      currentStep={ExplanationBreadcrumbsStep.boring}
      goToCalculator={goToCalculator}
    >
      <CalculatorQuestion
        title="Срок исполнения"
      >
        <Important>
          {timing}
          {' '}
          день
        </Important>
      </CalculatorQuestion>
      <CalculatorQuestion
        title="Стоимость скважины"
      >
        <Important>
          {formatCost(boringCost)}
          {' '}
          руб.
        </Important>
      </CalculatorQuestion>
      <CalculatorQuestion
        title="Материалы и оборудование"
      >
        {materials.map((s) => <ListItem key={s}>{formatText(s)}</ListItem>)}
      </CalculatorQuestion>
      <CalculatorQuestion
        title="Проводимые работы"
      >
        <ListItem>{formatText(procedures)}</ListItem>
      </CalculatorQuestion>
    </ExplanationStepTemplate>
  );
};

const CalculatorQuestion = styled(ExplanationControl)`
  :not(:first-of-type) {
    margin-top: 20px;
  }
`;
