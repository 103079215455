import React from 'react';
import styled from 'styled-components';
import { navigate, RouteComponentProps } from '@reach/router';

import { showCallbackForm } from 'stores/callbackForm';

import { Highlight } from 'ui/atoms/Highlight';
import { BREAKPOINTS, COLORS } from 'ui/constants';
import { ButtonGhost, ButtonIcon, ButtonPrimary } from 'ui/atoms/Button';
import { Icon } from 'ui/atoms/Icon';

import ImgLogo from './static/logo.svg';

export const MenuContacts: React.FC<RouteComponentProps> = () => {
  const onCalculateClick = () => {
    navigate('/calculator');
  };

  return (
    <Wrapper>
      <AboutText as="div">
        Гидротех - команда настоящих профессионалов бурового дела с опытом работы более <strong>20 лет</strong>.
        <br />
        Квалифицированные специалисты, качественные материалы и жесткий контроль качества на каждом этапе - основа
        успешной работы нашей компании!
      </AboutText>

      <AddressBlock>
        <ContactsLabel>Адрес офиса</ContactsLabel>
        <AddressText>МО, г. Чехов, ул. Cенная, 20</AddressText>
      </AddressBlock>

      <ContactsBlock>
        <ContactsLabel>Контакты</ContactsLabel>
        <ContactsList>
          <PhoneText href="tel:+74951015060">+7 (495) 101-50-60</PhoneText>
          <EmailText href="mailto:info@hydrotechpro.ru">info@hydrotechpro.ru</EmailText>
        </ContactsList>
      </ContactsBlock>

      <Controls>
        <ButtonPrimary onClick={onCalculateClick}>Рассчитать стоимость</ButtonPrimary>

        <ContactUsButton
          onClick={() => {
            showCallbackForm();
          }}
        >
          Связаться с нами
          <ButtonIcon>
            <Icon iconName="IconArrow45" size={18} rotate={90} />
          </ButtonIcon>
        </ContactUsButton>
      </Controls>

      <Logo>
        <LogoImg src={ImgLogo} />
      </Logo>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: 100%;
  position: relative;
  padding: 115px 5vw 80px;
  display: grid;
  grid-template-areas:
    'info'
    'address'
    'contacts'
    'controls';
  grid-template-rows: max-content max-content max-content auto;
  grid-template-columns: 100%;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    padding: 65px 60px;
    grid-template-areas:
      'info address'
      'info contacts'
      'controls logo';
    grid-template-columns: 62% 38%;
    grid-template-rows: max-content max-content max-content;
  }
`;

const AboutText = styled(Highlight)`
  grid-area: info;
  width: 620px;
  max-width: 100%;
  font-size: 26px;
  line-height: 1.3;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 20px;
    padding-right: 40px;
  }
`;

const ContactsLabel = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  color: ${COLORS.blacktech};
  opacity: 0.45;
`;

const AddressBlock = styled.div`
  grid-area: address;
  margin-top: 55px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-top: auto;
  }
`;

const AddressText = styled.div`
  margin-top: 10px;
  font-size: 18px;
  width: 280px;
  max-width: 100%;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-top: 5px;
  }
`;

const ContactsBlock = styled.div`
  grid-area: contacts;
  margin-top: 35px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-top: 40px;
  }
`;

const ContactsList = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5px;
  }
`;

const PhoneText = styled.a`
  font-size: 18px;
`;

const EmailText = styled.a`
  font-size: 18px;
  margin-left: 40px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-left: 0;
    margin-top: 5px;
  }
`;

const Controls = styled.div`
  grid-area: controls;
  margin-top: 40px;
  align-self: end;
  display: flex;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-top: 80px;
  }
`;

const ContactUsButton = styled(ButtonGhost)`
  margin-left: 40px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    display: none;
  }

  ${ButtonIcon} {
    margin-left: 10px;
  }
`;

const Logo = styled.div`
  @media (min-width: ${BREAKPOINTS.laptopSmallMin}px) {
    position: absolute;
    right: 40px;
    bottom: 80px;
    transform: rotate(-90deg) translate(50%, 100%);
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-top: 80px;
    grid-area: logo;
  }
`;

const LogoImg = styled.img`
  display: block;
`;
