import React, { useState, useEffect, useCallback } from 'react';
import { useStore } from 'effector-react';
import styled from 'styled-components';
import { RouteComponentProps, useLocation } from '@reach/router';
import { rgba } from 'polished';
import InputMask from 'react-input-mask';

import addSeconds from 'date-fns/addSeconds';

import {
  $callbackPhoneNumber,
  setCallbackPhoneNumber,
  $callbackTimerEndsAt,
  setCallbackTimerEndsAt,
  $callbackFormVisible,
  hideCallbackForm,
  $formExpanded,
  expandForm,
  collapseForm,
} from 'stores/callbackForm';

import { BREAKPOINTS, COLORS } from 'ui/constants';
import { Highlight } from 'ui/atoms/Highlight';
import { Overlay } from 'ui/atoms/Overlay';
import { ButtonPrimary } from 'ui/atoms/Button';

import { notify } from 'utils/notify';
import { Icon } from 'ui/atoms/Icon';
import IconClose from './static/close.svg';
import IconClockPrimary from './static/clock-primary.svg';
import ImgBackgroundForm from './static/background-form.svg';

const TIME_TO_CALL_BACK = 600;

export const CallbackForm: React.FC<RouteComponentProps> = () => {
  const callbackFormVisible = useStore($callbackFormVisible);
  const callbackTimerEndsAt = useStore($callbackTimerEndsAt);
  const callbackPhoneNumber = useStore($callbackPhoneNumber);
  const formExpanded = useStore($formExpanded);

  const getDiffInSeconds = () => {
    if (callbackTimerEndsAt) {
      const now = new Date();
      return (callbackTimerEndsAt.getTime() - now.getTime()) / 1000;
    }
    return 0;
  };

  const getDiffInSecondsMemoized = useCallback(getDiffInSeconds, [callbackTimerEndsAt]);
  const [callbackTimeLeft, setCallbackTimeLeft] = useState(getDiffInSeconds);
  const [phoneNumber, setPhoneNumber] = useState(callbackPhoneNumber);

  const timeLeftRepr = `${Math.floor(callbackTimeLeft / 60)}:${String(Math.floor(callbackTimeLeft % 60)).padStart(
    2,
    '0',
  )}`;
  const callbackPending = Boolean(callbackTimeLeft);

  const updateCallbackTimeLeft = () => {
    const diffInSeconds = getDiffInSecondsMemoized();
    if (diffInSeconds > 0) {
      setCallbackTimeLeft(diffInSeconds);
    } else {
      setPhoneNumber('');
      expandForm();
      setCallbackPhoneNumber('');
      setCallbackTimeLeft(0);
      setCallbackTimerEndsAt(null);
    }
  };

  const updateCallbackTimeLeftMemoized = useCallback(updateCallbackTimeLeft, [
    getDiffInSecondsMemoized,
    setCallbackTimeLeft,
    setCallbackTimerEndsAt,
  ]);

  useEffect(() => {
    if (callbackTimeLeft) {
      const timer = setTimeout(updateCallbackTimeLeftMemoized, 250);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {};
  }, [callbackTimeLeft, updateCallbackTimeLeftMemoized]);

  const submitForm = (e: React.FormEvent) => {
    e.preventDefault();
    const message = `
<b>Позвоните мне!</b>
${phoneNumber}
`;
    notify({ message });
    setCallbackPhoneNumber(phoneNumber);
    collapseForm();
    setCallbackTimerEndsAt(addSeconds(new Date(), TIME_TO_CALL_BACK));
    setCallbackTimeLeft(TIME_TO_CALL_BACK);
  };

  const onPhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
  };

  const formValid = Boolean(
    phoneNumber && !phoneNumber.includes('_') && (!callbackPhoneNumber || phoneNumber !== callbackPhoneNumber),
  );

  const { pathname } = useLocation();

  const formEx = formExpanded || (callbackFormVisible && !callbackPending);

  return callbackFormVisible ? (
    <Overlay visible={formEx} onClick={callbackPending ? collapseForm : hideCallbackForm}>
      <Wrapper hideOnMobileIf={callbackPending && !formEx && pathname.startsWith('/menu')}>
        <FormPopup onSubmit={submitForm} slickPaddings={!formEx}>
          {formEx && (
            <>
              <Title as="h3">
                Мы перезвоним Вам в течение <strong>10 минут</strong>
              </Title>

              <PhoneBlock>
                <PhoneLabel>Пожалуйста, укажите Ваш номер:</PhoneLabel>
                <PhoneInput
                  mask="+7 999 999-9999"
                  placeholder="+7 *** ***-****"
                  value={phoneNumber}
                  onChange={onPhoneNumberChange}
                />
              </PhoneBlock>
            </>
          )}

          <Meta>
            <Links>
              {callbackFormVisible && callbackPending && !formEx ? (
                <TimerLabel>Перезвоним в течение</TimerLabel>
              ) : (
                <SubmitButton type="submit" disabled={!formValid}>
                  {
                    {
                      true: 'Отправить заявку',
                      [String(callbackPending)]: 'Перезвоним в течение',
                      [String(
                        callbackPending && callbackPhoneNumber && phoneNumber !== callbackPhoneNumber,
                      )]: 'Изменить номер',
                    }.true
                  }
                </SubmitButton>
              )}
            </Links>

            {Boolean(callbackTimeLeft) && <TimerCounter>{timeLeftRepr}</TimerCounter>}

            {
              {
                true: (
                  <FormPopupCloseButton
                    onClick={() => {
                      hideCallbackForm();
                    }}
                  />
                ),
                [String(callbackPending && formExpanded)]: (
                  <ToggleButton
                    type="button"
                    onClick={() => {
                      collapseForm();
                    }}
                  >
                    <Icon iconName="IconChevron" size={18} rotate={180} />
                  </ToggleButton>
                ),
                [String(callbackPending && !formExpanded)]: (
                  <ToggleButton
                    type="button"
                    onClick={() => {
                      expandForm();
                    }}
                  >
                    <Icon iconName="IconChevron" size={18} />
                  </ToggleButton>
                ),
              }.true
            }
          </Meta>
        </FormPopup>
      </Wrapper>
    </Overlay>
  ) : null;
};

const Wrapper = styled.div<{ hideOnMobileIf: boolean }>`
  position: fixed;
  z-index: 10;
  right: 40px;
  bottom: 70px;
  max-width: calc(100% - 40px);

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    bottom: 0;
    right: 0;
    max-width: 100%;
    display: ${(p) => p.hideOnMobileIf && 'none'};
  }
`;

const CloseButton = styled.button`
  width: 24px;
  height: 24px;
  background: transparent url(${IconClose}) center no-repeat;
  background-size: 100% auto;
  opacity: 0.65;
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 1;
  }
`;

const FormPopup = styled.form<{ slickPaddings: boolean }>`
  width: 440px;
  max-width: 100%;
  position: relative;
  background-color: #fff;
  background-image: url(${ImgBackgroundForm});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: ${({ slickPaddings }) => (slickPaddings ? '15px 40px' : '50px')};
  display: flex;
  flex-direction: column;
  box-shadow: 0 -15px 20px ${rgba('#000', 0.07)}, 0 15px 20px ${rgba('#000', 0.07)};

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    padding: ${({ slickPaddings }) => (slickPaddings ? '15px 25px' : '30px')};
    margin-left: auto;
  }
`;

const FormPopupCloseButton = styled(CloseButton)`
  margin-left: auto;
`;

const Title = styled(Highlight)`
  font-size: 32px;

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    font-size: 28px;
  }
`;

const PhoneBlock = styled.div`
  margin-top: 40px;
  margin-bottom: 80px;
`;

const PhoneLabel = styled.label`
  display: block;
  font-size: 16px;
  opacity: 0.65;
  margin-bottom: 20px;
`;

const PhoneInput = styled(InputMask)`
  display: block;
  appearance: none;
  border: none;
  outline: none;
  padding: 0;
  font-size: 20px;
  font-weight: 500;
  color: ${COLORS.blacktech};

  &::placeholder {
    color: ${rgba(COLORS.blacktech, 0.3)};
  }
`;

const Links = styled.div``;

const SubmitButton = styled(ButtonPrimary)`
  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    font-size: 12px;
    padding: 4px 18px;
    height: 38px;
  }
`;

const TimerLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  opacity: 0.65;
  text-transform: uppercase;
`;

const TimerCounter = styled.div`
  margin-left: 20px;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  color: ${COLORS.yellowtech};
  padding-left: 20px;
  background: transparent url(${IconClockPrimary}) center left no-repeat;
  background-size: auto 100%;
`;

const ToggleButton = styled.button`
  color: ${COLORS.blacktech};
  margin-left: auto;
  opacity: 0.65;
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 1;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Meta = styled.div`
  display: flex;
  align-items: center;
`;
