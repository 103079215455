import * as React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';
import { HighlightStyles } from 'ui/atoms/Highlight';
import { BREAKPOINTS } from 'ui/constants';

export enum ExplanationBreadcrumbsStep {
  boring,
  waterSupply,
  sewerSystem,
}

interface ExplanationBreadcrumbsProps {
  currentStep: ExplanationBreadcrumbsStep;
}

export const ExplanationBreadcrumbs: React.FC<ExplanationBreadcrumbsProps> = ({ currentStep = 0 }) => (
  <Breadcrumbs>
    <Breadcrumb active={currentStep === 0}>
      <BreadcrumbLink to="/calculator/details/">Бурение</BreadcrumbLink>
    </Breadcrumb>
    <Breadcrumb active={currentStep === 1}>
      <BreadcrumbLink to="/calculator/details/water-supply">Водоснабжение</BreadcrumbLink>
    </Breadcrumb>
    <Breadcrumb active={currentStep === 2}>
      <BreadcrumbLink to="/calculator/details/sewer-system">Канализация</BreadcrumbLink>
    </Breadcrumb>
  </Breadcrumbs>
);

const Breadcrumbs = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 140px;

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    flex-direction: row;
    min-width: auto;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    justify-content: space-between;
  }
`;

const Breadcrumb = styled.li<{ active: boolean }>`
  display: inline;
  font-size: 24px;
  line-height: 24px;
  font-weight: ${(p) => (p.active ? 450 : 300)};
  opacity: ${(p) => (p.active ? '1 !important' : '0.65')};
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 1;
  }

  :not(:first-of-type) {
    margin-top: 36px;
  }

  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    font-size: 18px;
  }

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    font-size: 16px;
    line-height: 16px;

    :not(:first-of-type) {
      margin-top: 0;
      margin-left: 40px;
    }
  }

  ${(p) => p.active && HighlightStyles}
`;

const BreadcrumbLink = styled(Link)`
  font-weight: inherit;
  color: inherit;
`;
