import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { COLORS } from 'ui/constants';
import { rgba } from 'polished';

interface OverlayProps {
  onClick?: () => void;
  className?: string;
  visible?: boolean | null;
}

export const Overlay: React.FC<OverlayProps> = ({ visible = null, onClick, children, className }) => {
  const [localVisible, setLocalVisible] = useState(false);

  const overlayVisible = visible ?? localVisible;

  useEffect(() => {
    setLocalVisible(true);
    return () => {
      setLocalVisible(false);
    };
  }, []);
  const overlayDOMNode = document.getElementById('overlay');

  if (overlayDOMNode) {
    return ReactDOM.createPortal(
      <OverlayWrapper>
        <ChildrenWrapper>{children}</ChildrenWrapper>
        <OverlayStyled overlayVisible={overlayVisible} className={className} onClick={onClick} />
      </OverlayWrapper>,
      overlayDOMNode,
    );
  }
  return null;
};

const OverlayWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  pointer-events: none;
`;

const OverlayStyled = styled.div<{ overlayVisible: boolean }>`
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: ${rgba(COLORS.blacktech, 0.3)};
  opacity: ${(p) => (p.overlayVisible ? 1 : 0)};
  pointer-events: ${(p) => (p.overlayVisible ? 'auto' : 'none')};
  transition: opacity 300ms ease-in-out;
`;

const ChildrenWrapper = styled.div`
  position: absolute;
  z-index: 20;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  pointer-events: auto;
`;
