import {
  createEvent,
  createStore,
  restore,
  combine, forward,
} from "effector";
import {
  loadLocalStorageFx,
  saveLocalStorageFx,
} from "utils/localStorage";

const LOCAL_STORAGE_KEY = "callbackForm";

type CallbackTimerDateType = Date | null;

loadLocalStorageFx(LOCAL_STORAGE_KEY);

export const setCallbackPhoneNumber = createEvent<string>();
export const $callbackPhoneNumber = restore(setCallbackPhoneNumber, '')
  .on(
    loadLocalStorageFx.doneData, (_, { callbackPhoneNumber }) => callbackPhoneNumber,
  )

export const setCallbackTimerEndsAt = createEvent<CallbackTimerDateType>();
export const $callbackTimerEndsAt = restore(setCallbackTimerEndsAt, null as CallbackTimerDateType)
  .on(
    loadLocalStorageFx.doneData, (_, { callbackTimerEndsAtString }) => (
      callbackTimerEndsAtString
        ? new Date(callbackTimerEndsAtString)
        : null
    ),
  )
export const $callbackTimerEndsAtString = $callbackTimerEndsAt.map((v) => v?.toJSON() || null);

export const showCallbackForm = createEvent();
export const hideCallbackForm = createEvent();
export const $callbackFormVisible = createStore(false)
  .on(showCallbackForm, () => true)
  .on(hideCallbackForm, () => false)
  .on(
    loadLocalStorageFx.doneData, (_, { callbackFormVisible }) => callbackFormVisible,
  )

export const expandForm = createEvent();
export const collapseForm = createEvent();
export const toggleForm = createEvent();
export const $formExpanded = createStore(true)
  .on(expandForm, () => true)
  .on(collapseForm, () => false)
  .on(toggleForm, (v) => !v)
  .on(
    loadLocalStorageFx.doneData, (_, { formExpanded }) => formExpanded,
  )

export const $callbackForm = combine({
  callbackPhoneNumber: $callbackPhoneNumber,
  callbackTimerEndsAtString: $callbackTimerEndsAtString,
  callbackFormVisible: $callbackFormVisible,
  formExpanded: $formExpanded,
});

forward({
  from: $callbackForm,
  to: saveLocalStorageFx.prepend((value) => ({ value, localStorageKey: LOCAL_STORAGE_KEY })),
});
