import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { Link, navigate } from '@reach/router';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, EffectFade } from 'swiper';
import 'swiper/swiper.scss';

import { API_ROOT } from 'hooks/useApiCall';
import { showCallbackForm } from 'stores/callbackForm';

import { Container as ContainerDefault } from 'ui/atoms/Container';
import { formatCost, formatText } from 'utils/formatText';
import { COLORS, BREAKPOINTS } from 'ui/constants';
import { Highlight } from 'ui/atoms/Highlight';

import { Icon } from 'ui/atoms/Icon';
import ImgBackgroundPrimary from './static/background-primary.svg';
import ImgBackgroundTablet from './static/background-tablet.svg';
import IconNext from './static/icon-next.svg';
import IconChevronRight from './static/icon-chevron-right.svg';
import IconChevronLeft from './static/icon-chevron-left.svg';
import IconClose from './static/close.svg';
import IconCloseLight from './static/close-light.svg';
import IconQuoteStart from './static/quote-start.svg';
import IconQuoteEnd from './static/quote-end.svg';

SwiperCore.use([Navigation, EffectFade]);

enum ObjectInfoBreadcrumbsStep {
  boring,
  waterSupply,
  sewerSystem,
}

interface ObjectInfoProps {
  objectNumber: number;
  name: string;
  feedbackText: string;
  feedbackLink: string | null;
  wasBoring: boolean;
  wasWaterSupply: boolean;
  wasSewerSystem: boolean;
  photos: { id: number; url: string }[];
  boringCost: number;
  boringDuration: number;
  boringInstallation: string;
  boringProcedures: string;
  waterSupplyCost: number;
  waterSupplyDuration: number;
  waterSupplyInstallation: string;
  waterSupplyProcedures: string;
  sewerSystemCost: number;
  sewerSystemDuration: number;
  sewerSystemInstallation: string;
  sewerSystemProcedures: string;
  nextObject?: {
    id: number;
    objectNumber: number;
  };
}

export const ObjectInfo: React.FC<ObjectInfoProps> = ({
  name,
  feedbackText,
  feedbackLink,
  wasBoring,
  wasWaterSupply,
  wasSewerSystem,
  photos,
  boringCost,
  boringDuration,
  boringInstallation,
  boringProcedures,
  waterSupplyCost,
  waterSupplyDuration,
  waterSupplyInstallation,
  waterSupplyProcedures,
  sewerSystemCost,
  sewerSystemDuration,
  sewerSystemInstallation,
  sewerSystemProcedures,
  nextObject,
}) => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore | null>(null);
  const [youtubePopupVideoID, setYoutubePopupVideoID] = useState<null | string>(null);

  const sliderSettings: Swiper = {
    spaceBetween: 20,
    navigation: {
      nextEl: `.${SliderControlNext.styledComponentId}`,
      prevEl: `.${SliderControlPrev.styledComponentId}`,
    },
    speed: 1800,
    allowTouchMove: false,
    effect: 'fade',
    onSwiper: setSwiperInstance,
    autoHeight: true,
  };

  useEffect(() => {
    if (swiperInstance) {
      swiperInstance.update();
    }
  }, [photos, swiperInstance]);

  const [currentPage, setCurrentPage] = useState(ObjectInfoBreadcrumbsStep.boring);

  const cost = {
    [ObjectInfoBreadcrumbsStep.boring]: boringCost,
    [ObjectInfoBreadcrumbsStep.waterSupply]: waterSupplyCost,
    [ObjectInfoBreadcrumbsStep.sewerSystem]: sewerSystemCost,
  }[currentPage];
  const duration = {
    [ObjectInfoBreadcrumbsStep.boring]: boringDuration,
    [ObjectInfoBreadcrumbsStep.waterSupply]: waterSupplyDuration,
    [ObjectInfoBreadcrumbsStep.sewerSystem]: sewerSystemDuration,
  }[currentPage];
  const installation = {
    [ObjectInfoBreadcrumbsStep.boring]: boringInstallation,
    [ObjectInfoBreadcrumbsStep.waterSupply]: waterSupplyInstallation,
    [ObjectInfoBreadcrumbsStep.sewerSystem]: sewerSystemInstallation,
  }[currentPage];
  const operations = {
    [ObjectInfoBreadcrumbsStep.boring]: boringProcedures,
    [ObjectInfoBreadcrumbsStep.waterSupply]: waterSupplyProcedures,
    [ObjectInfoBreadcrumbsStep.sewerSystem]: sewerSystemProcedures,
  }[currentPage];

  const onCloseClick = () => {
    navigate('/#our-works');
  };

  const openYouTubePopup = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (e.target instanceof HTMLElement) {
      const href = e.target.getAttribute('href') ?? '';
      const linkMatch = href.match(
        /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?‌​[\w?‌​=]*)?/,
      );
      if (!linkMatch || linkMatch.length < 2) return;

      e.preventDefault();
      const youtubeVideoID = linkMatch[1];
      setYoutubePopupVideoID(youtubeVideoID);
    }
  };

  return (
    <Body>
      <Container>
        <Controls>
          <CloseButton onClick={onCloseClick} />
        </Controls>

        <InfoBlock>
          <Review>
            <ReviewText as="p">
              <ReviewTextStart />
              {formatText(feedbackText)}
              <ReviewTextEnd />
            </ReviewText>

            <ReviewMeta>
              <ReviewAuthor>{name}</ReviewAuthor>

              {feedbackLink && <ReviewLinkText onClick={openYouTubePopup}>{formatText(feedbackLink)}</ReviewLinkText>}
            </ReviewMeta>
          </Review>

          <NextControls>
            {nextObject && (
              <NextControlsLink to={`/objects/${nextObject.id}`}>
                <NextControlsLinkIcon />

                <NextControlsLinkText>
                  Объект <br /> №{nextObject.objectNumber}
                </NextControlsLinkText>
              </NextControlsLink>
            )}
          </NextControls>

          <Support>
            <SupportButton
              onClick={() => {
                showCallbackForm();
              }}
            >
              Остались вопросы
              <Icon iconName="IconQuestion" size={18} />
            </SupportButton>
          </Support>
        </InfoBlock>

        <DescriptionBlock>
          <DescriptionNav>
            {wasBoring && (
              <DescriptionNavLink
                active={currentPage === ObjectInfoBreadcrumbsStep.boring}
                onClick={() => setCurrentPage(ObjectInfoBreadcrumbsStep.boring)}
              >
                Скважина
              </DescriptionNavLink>
            )}
            {wasWaterSupply && (
              <DescriptionNavLink
                active={currentPage === ObjectInfoBreadcrumbsStep.waterSupply}
                onClick={() => setCurrentPage(ObjectInfoBreadcrumbsStep.waterSupply)}
              >
                Водоснабжение
              </DescriptionNavLink>
            )}
            {wasSewerSystem && (
              <DescriptionNavLink
                active={currentPage === ObjectInfoBreadcrumbsStep.sewerSystem}
                onClick={() => setCurrentPage(ObjectInfoBreadcrumbsStep.sewerSystem)}
              >
                Канализация
              </DescriptionNavLink>
            )}
          </DescriptionNav>

          <Stats>
            {duration !== 0 && (
              <StatsItem>
                <StatsTitle>Срок исполнения</StatsTitle>
                <StatsPrimaryValue>
                  {duration} {duration === 1 ? 'день' : 'дня'}
                </StatsPrimaryValue>
              </StatsItem>
            )}

            {cost !== 0 && (
              <StatsItemBig>
                <StatsTitle>Общая Стоимость Скважины</StatsTitle>
                <StatsPrimaryValue>{formatCost(cost)} руб.</StatsPrimaryValue>
              </StatsItemBig>
            )}

            {installation && (
              <StatsItem>
                <StatsTitle>Оборудование</StatsTitle>
                <StatsSecondaryValue>{formatText(installation)}</StatsSecondaryValue>
              </StatsItem>
            )}

            {operations && (
              <StatsItemBig>
                <StatsTitle>Работы</StatsTitle>
                <StatsSecondaryValue>{formatText(operations)}</StatsSecondaryValue>
              </StatsItemBig>
            )}
          </Stats>

          <SliderWrapper>
            <Slider {...sliderSettings}>
              {photos.map((p) => (
                <SwiperSlide key={p.id}>
                  <SliderItem>
                    <SliderItemImg src={`${API_ROOT}${p.url}`} />
                  </SliderItem>
                </SwiperSlide>
              ))}
            </Slider>
          </SliderWrapper>

          <SliderControls>
            <SliderControlNext visible={photos.length > 1} />
            <SliderControlPrev visible={photos.length > 1} />
          </SliderControls>
        </DescriptionBlock>

        {youtubePopupVideoID && (
          <Popup onClick={() => setYoutubePopupVideoID(null)}>
            <PopupCloseButton onClick={() => setYoutubePopupVideoID(null)} />

            <PopupInner onClick={(e) => e.stopPropagation()}>
              <PopupIframeWrapper>
                <PopupIframe title="Youtube" src={`https://www.youtube.com/embed/${youtubePopupVideoID}`}></PopupIframe>
              </PopupIframeWrapper>
            </PopupInner>
          </Popup>
        )}
      </Container>
    </Body>
  );
};

const Body = styled.div`
  padding: 30px 0 80px;
  background-color: transparent;
  background-image: url(${ImgBackgroundPrimary});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    padding: 60px 0;
    background-image: url(${ImgBackgroundTablet});
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    padding-top: 120px;
  }
`;

const Container = styled(ContainerDefault)`
  position: relative;
  flex: 1;
  display: grid;
  grid-template-columns: 47% 53%;
  grid-template-rows: 70px calc(100% - 70px);
  grid-template-areas:
    'controls controls'
    'info description';

  @media (min-width: ${BREAKPOINTS.desktopMin}px) {
    width: 1760px;
    grid-template-columns: 42% 58%;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    grid-template-columns: 100%;
    grid-template-rows: none;
    grid-template-areas:
      'controls'
      'description'
      'info';
  }
`;

const Controls = styled.div`
  grid-area: controls;
  display: flex;
  justify-content: flex-start;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    top: -30px;
  }
`;

const CloseButton = styled.button`
  background: transparent url(${IconClose}) center no-repeat;
  background-size: 50% auto;
  width: 22px;
  height: 22px;
  opacity: 0.7;
  transition: opacity 0.2s ease-out;

  @media (min-width: ${BREAKPOINTS.desktopMin}px) {
    width: 34px;
    height: 34px;
  }

  &:hover {
    opacity: 1;
  }
`;

const InfoBlock = styled.div`
  grid-area: info;
  display: grid;
  grid-template-areas:
    'review'
    'controls'
    'support';
  grid-template-rows: 1fr auto auto;
  padding-right: 100px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    padding-right: 0;
    margin-top: 140px;
    grid-template-areas:
      'review review'
      'support controls';
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-top: 60px;
    grid-template-areas:
      'review'
      'controls'
      'support';
  }
`;

const Review = styled.div`
  grid-area: review;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  padding-top: 35px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    padding-top: 0;
    flex-direction: row;
    align-items: flex-start;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const ReviewText = styled(Highlight)`
  font-size: 26px;
  line-height: 1.3;
  width: 410px;
  max-width: 100%;

  @media (min-width: ${BREAKPOINTS.desktopMin}px) {
    font-size: 32px;
    width: 550px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    flex: 1;
    width: auto;
    margin-right: 100px;
    font-size: 18px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-right: 0;
  }
`;

const ReviewTextQuote = styled.span`
  position: relative;
  z-index: 0;
  width: 0;
  height: 0;
  display: inline-block;

  &::before {
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    position: absolute;
    z-index: 0;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

const ReviewTextStart = styled(ReviewTextQuote)`
  &::before {
    background-image: url(${IconQuoteStart});
    right: 15px;
    bottom: 20px;
  }
`;

const ReviewTextEnd = styled(ReviewTextQuote)`
  &::before {
    background-image: url(${IconQuoteEnd});
    left: 15px;
    top: 0;
  }
`;

const ReviewMeta = styled.div`
  margin-top: 60px;
  display: flex;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-top: 0;
    margin-left: auto;
    flex-direction: column;
    align-items: flex-end;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-left: 0;
    margin-top: 60px;
  }
`;

const ReviewAuthor = styled.div`
  font-size: 14px;
  opacity: 0.45;
  text-transform: uppercase;
  white-space: nowrap;

  @media (min-width: ${BREAKPOINTS.desktopMin}px) {
    font-size: 16px;
  }
`;

const ReviewLinkText = styled(Highlight)`
  margin-left: 40px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-left: 0;
    margin-top: 5px;
  }

  a {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const NextControls = styled.div`
  grid-area: controls;
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    justify-self: end;
    padding-right: 0;
  }
`;

const NextControlsLinkIcon = styled.div`
  width: 96px;
  height: 96px;
  border-radius: 999px;
  border: solid 1px #c3c4bd;
  background: transparent url(${IconNext}) center no-repeat;
  background-size: 35% auto;
  transition: transform 0.2s ease-out;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    width: 64px;
    height: 64px;
  }
`;

const NextControlsLinkText = styled.span`
  br {
    @media (min-width: ${BREAKPOINTS.laptopSmallMin}px) {
      display: none;
    }
  }
`;

const NextControlsLink = styled(Link)`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  color: ${COLORS.blacktech};

  &:hover {
    ${NextControlsLinkIcon} {
      transform: scale(1.05);
    }
  }

  ${NextControlsLinkIcon} {
    margin-right: 40px;

    @media (max-width: ${BREAKPOINTS.tabletMax}px) {
      margin-right: 30px;
    }
  }
`;

const Support = styled.div`
  grid-area: support;
  margin-top: 35px;
`;

const SupportButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  padding-right: 28px;
  color: ${COLORS.blacktech};
  transition: color 0.2s ease-out;

  &:hover {
    color: ${COLORS.yellowtech};
  }

  ${Icon} {
    margin-left: 10px;
  }
`;

const DescriptionBlock = styled.div`
  grid-area: description;
  display: grid;
  grid-template-areas:
    'desc-stats desc-nav'
    'desc-slider desc-slider-controls';
  grid-template-columns: minmax(0, 1fr) 150px;
  grid-template-rows: minmax(0, 1fr) max-content;
  grid-gap: 20px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    grid-template-areas:
      'desc-nav desc-nav'
      'desc-stats desc-stats'
      'desc-slider desc-slider-controls';
    grid-template-columns: max-content auto;
    grid-template-rows: none;
    grid-gap: 60px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    grid-template-areas:
      'desc-nav'
      'desc-stats'
      'desc-slider';
    grid-template-columns: 100%;
    grid-gap: 20px;
  }
`;

const DescriptionNav = styled.div`
  grid-area: desc-nav;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    flex-direction: row;
  }
`;

const DescriptionNavLink = styled.div<{ active?: boolean }>`
  font-size: 18px;
  margin-bottom: 30px;
  opacity: ${({ active }) => (active ? '1 !important' : '0.45')};
  color: ${({ active }) => (active ? COLORS.navytech : '')};
  font-weight: ${({ active }) => (active ? '600' : '400')};
  transition: opacity 0.2s ease-out, color 0.2s ease-out, font-weight 0.2s ease-out;
  background: ${({ active }) => (active ? 'rgb(2, 0, 36)' : '')};
  background: ${({ active }) =>
    active
      ? `linear-gradient(180deg, ${COLORS.transparent} 0%, ${COLORS.transparent} 60%, ${rgba(
          COLORS.navytech,
          0.1,
        )} 60%, ${rgba(COLORS.navytech, 0.1)} 100%)`
      : ''};
  transition: opacity 0.2s ease-out;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  @media (min-width: ${BREAKPOINTS.desktopMin}px) {
    font-size: 24px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-bottom: 0;
    margin-right: 40px;
    font-size: 16px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-right: 25px;
  }

  &:last-child {
    margin-bottom: 0;

    @media (max-width: ${BREAKPOINTS.tabletMax}px) {
      margin-right: 0;
    }
  }
`;

const Stats = styled.div`
  grid-area: desc-stats;
  display: grid;
  align-content: start;
  grid-template-columns: 4fr 6fr;
  grid-gap: 60px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-top: 50px;
    grid-gap: 30px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    grid-template-columns: 100%;
  }
`;

const StatsItem = styled.div``;

const StatsItemBig = styled(StatsItem)``;

const StatsTitle = styled.h3`
  font-size: 14px;
  opacity: 0.45;
  text-transform: uppercase;
  margin-bottom: 10px;

  @media (min-width: ${BREAKPOINTS.desktopMin}px) {
    font-size: 16px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const StatsPrimaryValue = styled.div`
  font-size: 36px;
  color: ${COLORS.navytech};
  font-weight: 600;
  max-width: 240px;

  @media (min-width: ${BREAKPOINTS.desktopMin}px) {
    font-size: 48px;
    max-width: 320px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 32px;
  }
`;

const StatsSecondaryValue = styled.div`
  font-size: 22px;
  max-width: 240px;
  max-height: 100px;
  overflow: hidden;

  @media (min-width: ${BREAKPOINTS.desktopMin}px) {
    font-size: 24px;
    max-width: 320px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 18px;
    max-height: 85px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    max-height: none;
  }
`;

const SliderWrapper = styled.div`
  grid-area: desc-slider;
  overflow: hidden;
`;

const Slider = styled(Swiper)`
  width: 500px;
  max-width: 90%;
  margin-left: 0;
  overflow: visible !important;

  @media (min-width: ${BREAKPOINTS.desktopMin}px) {
    width: 620px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    width: 400px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    width: 90%;
    overflow: visible;
  }

  .swiper-slide {
    position: absolute !important;
    z-index: 0 !important;
    top: 0 !important;
    transform: none !important;
    opacity: 0 !important;
    transition: opacity 0.6s ease-out, left 0.8s ease-out !important;

    &.swiper-slide-active {
      opacity: 1 !important;
      z-index: 2 !important;
      left: 0 !important;
    }

    &.swiper-slide-prev,
    &.swiper-slide-next {
      z-index: 1 !important;
    }

    &.swiper-slide-prev {
      left: -35% !important;
    }

    &.swiper-slide-next {
      left: 35% !important;
    }
  }
`;

const SliderItem = styled.div`
  position: relative;
  padding-bottom: 50%;
  overflow: hidden;
`;

const SliderItemImg = styled.img`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
`;

const SliderControls = styled.div`
  grid-area: desc-slider-controls;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const SliderControl = styled.button<{ visible?: boolean }>`
  width: 96px;
  height: 96px;
  border-radius: 999px;
  background-color: transparent;
  background-position: center;
  background-size: 30% auto;
  background-repeat: no-repeat;
  border: solid 1px #c3c4bd;
  margin-bottom: 20px;
  visibility: ${({ visible }) => (!visible ? 'hidden' : '')};
  opacity: 0.7;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;

  &:hover {
    opacity: 1;
    transform: scale(1.05);
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.swiper-button-disabled {
    opacity: 0.3 !important;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    width: 64px;
    height: 64px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    display: none;
  }
`;

const SliderControlPrev = styled(SliderControl)`
  background-image: url(${IconChevronLeft});
`;

const SliderControlNext = styled(SliderControl)`
  background-image: url(${IconChevronRight});
`;

const Popup = styled.div`
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${rgba('#000', 0.7)};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
`;

const PopupInner = styled.div`
  width: 900px;
  max-width: 90%;
  max-height: calc(100vh - 160px);
  background-color: #fff;
  margin: 80px 0;
`;

const PopupCloseButton = styled.button`
  position: absolute;
  z-index: 10;
  right: 20px;
  top: 20px;
  background: transparent url(${IconCloseLight}) center no-repeat;
  background-size: 50% auto;
  width: 40px;
  height: 40px;
  opacity: 0.7;
  transition: opacity 0.2s ease-out;

  @media (min-width: ${BREAKPOINTS.desktopMin}px) {
    width: 50px;
    height: 50px;
  }

  &:hover {
    opacity: 1;
  }
`;

const PopupIframeWrapper = styled.div`
  position: relative;
  padding-bottom: 55%;
`;

const PopupIframe = styled.iframe`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
