import { useMediaQuery } from 'react-responsive';
import { BREAKPOINTS } from "ui/constants";

export const useMediaBreakpoint = () => {
  const isPhone = useMediaQuery({ maxWidth: BREAKPOINTS.phoneMax });
  const isTablet = useMediaQuery({ maxWidth: BREAKPOINTS.tabletMax });
  const isTabletStrict = useMediaQuery({
    minWidth: BREAKPOINTS.tabletMin,
    maxWidth: BREAKPOINTS.tabletMax,
  });
  const isLaptopSmall = useMediaQuery({ maxWidth: BREAKPOINTS.laptopSmallMax });
  const isLaptop = useMediaQuery({ maxWidth: BREAKPOINTS.laptopMax });
  const isDesktop = useMediaQuery({ maxWidth: BREAKPOINTS.desktopMax });

  return {
    isPhone,
    isTablet,
    isTabletStrict,
    isLaptopSmall,
    isLaptop,
    isDesktop,
  }
}
