import fromPairs from 'lodash/fromPairs';

const CHAT_ID = process.env.REACT_APP_CHAT_ID;

const SEND_MESSAGE_URL = process.env.REACT_APP_SEND_MESSAGE_URL || '/inform';

interface NotifyParams {
  message: string,
  withClientInfo?: boolean,
}

interface TraceI {
  colo: string,
  fl: string,
  gateway: string,
  h: string,
  http: string,
  ip: string,
  loc: string,
  sni: string,
  tls: string,
  ts: string,
  uag: string,
  visit_scheme: string,
  warp: string,
}

export const notify = async ({ message, withClientInfo = true }: NotifyParams) => {
  let clientInfo = '';

  if (withClientInfo) {
    const response = await (
      await global.fetch('https://www.cloudflare.com/cdn-cgi/trace')
    ).text()
    const pairs = response.split('\n').map((l) => l.split('='))
    const { uag, ip, loc } = fromPairs(pairs) as TraceI
    clientInfo = `<i>${loc}: ${ip}\n${uag}</i>`
  }

  const text = `${message}\n\n${clientInfo}`
  console.log(text);

  const body = JSON.stringify({
    chat_id: CHAT_ID,
    text,
    parse_mode: "HTML",
    disable_web_page_preview: true,
  });

  const requestOptions = {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body,
  };

  try {
    const rsp = await global.fetch(
      SEND_MESSAGE_URL,
      requestOptions,
    )
    const responseBody = await rsp.json();
    console.log('response', responseBody);
  } catch (e) {
    console.log('error', e);
  }
}
