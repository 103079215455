import {
  createEvent, restore, combine, forward,
} from "effector";
import {
  loadLocalStorageFx,
  saveLocalStorageFx,
} from "utils/localStorage";

const LOCAL_STORAGE_KEY = "waterSupply";

export enum BoreholeActivityType {
  none = 'none',
  summer = 'summer',
  fulltime = 'fulltime',
};

export const BOREHOLE_ACTIVITY_OPTIONS: { label: string, value: BoreholeActivityType }[] = [
  { label: 'Для полива в летний период', value: BoreholeActivityType.summer },
  { label: 'Для круглогодичного\nпроживания', value: BoreholeActivityType.fulltime },
];

loadLocalStorageFx(LOCAL_STORAGE_KEY);

export const resetWaterSupply = createEvent();

export const setBoreholeActivity = createEvent<BoreholeActivityType>();
export const $boreholeActivity = restore(setBoreholeActivity, BoreholeActivityType.none)
  .on(
    loadLocalStorageFx.doneData, (_, { boreholeActivity }) => boreholeActivity,
  )
  .reset(resetWaterSupply)

export const setMachineRoom = createEvent<boolean>();
export const $machineRoom = restore(setMachineRoom, false)
  .on(
    loadLocalStorageFx.doneData, (_, { machineRoom }) => machineRoom,
  )
  .reset(resetWaterSupply)

export const $waterSupply = combine({
  boreholeActivity: $boreholeActivity,
  machineRoom: $machineRoom,
});

forward({
  from: $waterSupply,
  to: saveLocalStorageFx.prepend((value) => ({ value, localStorageKey: LOCAL_STORAGE_KEY })),
});
