import React from 'react';
import styled from 'styled-components';
import { useMediaBreakpoint } from 'hooks/useMediaBreakpoint';
import { showCallbackForm } from "stores/callbackForm";

import { COLORS } from "ui/constants";
import { Calculator } from 'ui/templates/Calculator';
import { Icon } from 'ui/atoms/Icon';
import { CalculatorTitle } from 'components/Calculator/CalculatorTitle';
import { ButtonGhost, ButtonIcon, ButtonPrimary } from 'ui/atoms/Button';
import { Header } from 'ui/organisms/Header';
import IconClose from "ui/organisms/MenuMobile/static/icon-close.svg";
import { CalculatorBreadcrumbs, CalculatorBreadcrumbsStep } from 'ui/molecules/CalculatorBreadcrumbs';
import { CalculatorHint } from './CalculatorHint';
import { CalculatorTotals } from './CalculatorTotals';

interface CalculatorStepPageProps {
  currentStep: CalculatorBreadcrumbsStep;
  onCloseButtonClick: () => void;
  goToNextStep: () => void;
  skipStep: () => void;
}

export const CalculatorStepTemplate: React.FC<CalculatorStepPageProps> = ({
  children,
  onCloseButtonClick,
  currentStep,
  goToNextStep,
  skipStep,
}) => {
  const { isPhone } = useMediaBreakpoint();

  return (
    <Calculator
      header={(
        <Header closeButton={
          <HeaderCloseButton onClick={onCloseButtonClick}>Закрыть</HeaderCloseButton>
        }
        />
      )}
      breadcrumbs={<CalculatorBreadcrumbs currentStep={currentStep} />}
      hint={<CalculatorHint currentStep={currentStep} />}
      title={<CalculatorTitle currentStep={currentStep} />}
      nextButton={<ButtonPrimary onClick={goToNextStep}>Далее</ButtonPrimary>}
      skipButton={(
        <ButtonGhost onClick={skipStep}>
          Пропустить
          {!isPhone && (
            <IconWrapper>
              <Icon iconName="IconNext" size={18} />
            </IconWrapper>
          )}
        </ButtonGhost>
      )}
      totals={<CalculatorTotals />}
      closeButton={(
        <ButtonGhost onClick={onCloseButtonClick}>
          <Icon iconName="IconClose" size={22} />
        </ButtonGhost>
      )}
      questionsButton={(
        <ButtonGhost onClick={() => { showCallbackForm(); }}>
          Остались вопросы
          <IconWrapper>
            <Icon iconName="IconQuestion" size={18} />
          </IconWrapper>
        </ButtonGhost>
      )}
    >
      {children}
    </Calculator>
  );
};

const IconWrapper = styled(ButtonIcon)`
  color: ${COLORS.blacktech};
  margin-left: 12px;
`;

const HeaderCloseButton = styled.button`
  margin-left: auto;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  padding-right: 20px;
  background: transparent url(${IconClose}) center right no-repeat;
  background-size: auto 88%;
  opacity: 0.65;
`;
