import { createEffect } from "effector";

const TIME_TO_STORE = 2 * 60 * 60;

const LOCAL_STORAGE_PREFIX = 'hydrotech';

const LAST_UPDATED_KEY = `${LOCAL_STORAGE_PREFIX}:lastUpdated`;

export const loadLocalStorageFx = createEffect(async (
  localStorageKey: string,
) => {
  try {
    const lastUpdated = Number(global.localStorage.getItem(LAST_UPDATED_KEY));
    const localStorageValue = JSON.parse(
        global.localStorage.getItem(`${LOCAL_STORAGE_PREFIX}:${localStorageKey}`) as string,
    ) || {};

    const now = Math.floor(Date.now() / 1000);

    if (lastUpdated && (now - lastUpdated) < TIME_TO_STORE) {
      return localStorageValue;
    }
  } catch (e) {
    console.warn("Can't get data from local storage:", e);
  }
  return {};
});

export const saveLocalStorageFx = createEffect(<State>({
  value,
  localStorageKey,
}: {
  value: State,
  localStorageKey: string,
}) => {
  try {
    global.localStorage.setItem(
      `${LOCAL_STORAGE_PREFIX}:${localStorageKey}`,
      JSON.stringify(value),
    );
    global.localStorage.setItem(
      LAST_UPDATED_KEY,
      String(Math.floor(Date.now() / 1000)),
    );
  } catch (e) {
    console.warn("Can't write data to local storage:", e);
  }
});
