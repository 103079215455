import styled from 'styled-components';
import { rgba } from 'polished';
import { BREAKPOINTS, COLORS } from 'ui/constants';
import { ButtonGhost, ButtonIcon, ButtonPrimary } from 'ui/atoms/Button';

export const Important = styled.span`
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
  color: ${rgba(COLORS.navytech, 0.85)};

  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    font-size: 36px;
    line-height: 36px;
  }

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    font-size: 32px;
    line-height: 32px;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  margin-bottom: 10px;
  font-size: 28px;
  font-weight: 400;
  line-height: 32px;
  color: ${rgba(COLORS.blacktech, 0.85)};

  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    font-size: 22px;
    line-height: 22px;
  }

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 18px;
  }
`;

export const NextButton = styled(ButtonPrimary)`
  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    padding: 0 20px;
    height: 48px;
  }
`;

export const ResetCalculatorButton = styled(ButtonGhost)`
  margin-left: 40px;

  ${ButtonIcon} {
    margin-left: 15px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-left: 0;
    order: -1;
  }
`;
