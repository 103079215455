import React, { useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, EffectFade } from 'swiper';
import 'swiper/swiper.scss';

import { BREAKPOINTS } from 'ui/constants';
import { Container as ContainerDefault } from 'ui/atoms/Container';
import { Title as TitleDefault, TitleSecondary as TitleSecondaryDefault } from 'ui/atoms/Typography';

import ImgBackgroundPrimary from './static/background-primary.svg';
import ImgBackgroundTablet from './static/background-tablet.svg';
import ImgBackgroundPhone from './static/background-phone.svg';
import IconChevronRight from './static/icon-chevron-right.svg';
import IconArrowNext from './static/icon-arrow-next.svg';
import IconArrowPrev from './static/icon-arrow-prev.svg';

SwiperCore.use([Navigation, EffectFade]);

export interface StageI {
  label: string;
  title: string;
  description: string;
  previewIMG: string;
}

interface StagesWorkI {
  stages: StageI[];
}

export const StagesWork: React.FC<StagesWorkI> = (props) => {
  const { stages } = props;
  const [activeSlideIDX, setActiveSlideIDX] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore | null>(null);

  // slider settings
  const sliderSettings: Swiper = {
    loop: false,
    autoHeight: true,
    navigation: {
      nextEl: `.${SliderArrowNext.styledComponentId}`,
      prevEl: `.${SliderArrowPrev.styledComponentId}`,
    },
    speed: 1800,
    allowTouchMove: false,
    effect: 'fade',
    onSwiper: (swiper) => setSwiperInstance(swiper),
    onSlideChange: (swiper) => setActiveSlideIDX(swiper.realIndex),
  };

  const handlerChangeActiveStage = (idx: number) => setActiveSlideIDX(idx);

  useLayoutEffect(() => {
    if (!swiperInstance) return;

    swiperInstance.slideTo(activeSlideIDX);
  }, [swiperInstance, activeSlideIDX]);

  return (
    <Body>
      <Container>
        <Secondary>
          <Title>Жесткий контроль качества на каждом этапе</Title>

          <DescLabelWrapperPrimary>
            <DescLabel>Этапы работы</DescLabel>
          </DescLabelWrapperPrimary>
        </Secondary>
        <Main>
          <StagesList>
            {stages.map((stage, idx) => (
              <StagesListItem key={idx} active={idx === activeSlideIDX} onClick={() => handlerChangeActiveStage(idx)}>
                {stage.label}
              </StagesListItem>
            ))}
          </StagesList>

          <SliderWrapper>
            <StagesSlider {...sliderSettings}>
              {stages.map((stage, idx) => (
                <SwiperSlide key={idx}>
                  <Item>
                    <ItemName>
                      {idx + 1} - {stage.title}
                    </ItemName>
                    <ItemDesc>{stage.description}</ItemDesc>
                    <ItemPreview>
                      <ItemPreviewImg src={stage.previewIMG} />
                    </ItemPreview>
                  </Item>
                </SwiperSlide>
              ))}
            </StagesSlider>

            <SliderMeta>
              <DescLabelWrapperPhone>
                <DescLabel>Этапы работы</DescLabel>
              </DescLabelWrapperPhone>

              <SliderArrows>
                <SliderArrowNext showTooltip={activeSlideIDX === 0} show={activeSlideIDX < stages.length - 1}>
                  <SliderArrowIcon />
                  <SliderArrowText>Далее</SliderArrowText>
                </SliderArrowNext>

                <SliderArrowPrev showTooltip={activeSlideIDX === stages.length - 1} show={activeSlideIDX > 0}>
                  <SliderArrowIcon />
                  <SliderArrowText>Назад</SliderArrowText>
                </SliderArrowPrev>
              </SliderArrows>
            </SliderMeta>
          </SliderWrapper>
        </Main>
      </Container>
    </Body>
  );
};

const Body = styled.div`
  position: relative;
  z-index: 0;
  padding: 80px 0;
  background-color: transparent;
  background-image: url(${ImgBackgroundPrimary});
  background-position: center left;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    padding: 50px 0 10px;
    background-image: url(${ImgBackgroundTablet});
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    padding: 40px 0;
    background-image: url(${ImgBackgroundPhone});
  }
`;

const Container = styled(ContainerDefault)`
  display: flex;
  flex-wrap: wrap;
`;

const Secondary = styled.div`
  width: 42%;
  max-width: 42%;
  flex: 0 0 42%;
  display: flex;
  flex-direction: column;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    flex-direction: row;
  }
`;

const Title = styled(TitleDefault)`
  width: 345px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    width: 215px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    width: 175px;
  }
`;

const DescLabel = styled(TitleSecondaryDefault)``;

const DescLabelWrapperPrimary = styled.div`
  margin-top: auto;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-left: auto;
    margin-top: 0;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    display: none;
  }
`;

const DescLabelWrapperPhone = styled.div`
  @media (min-width: ${BREAKPOINTS.tabletMin}px) {
    display: none;
  }
`;

const Main = styled.div`
  width: 58%;
  max-width: 58%;
  flex: 0 0 58%;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }
`;

const StagesList = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-top: 80px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-top: 40px;
    margin-bottom: -20px;
  }
`;

const StagesListItem = styled.div<{ active: boolean }>`
  position: relative;
  z-index: 0;
  font-size: 20px;
  font-weight: ${({ active }) => (active ? '500' : '450')};
  margin-right: 20px;
  padding-right: 40px;
  opacity: ${({ active }) => (active ? '1 !important' : '0.6')};
  transition: opacity 0.2s ease-out, font-weight 0.2s ease-out;
  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 14px;
    padding-right: 24px;
    margin-right: 10px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    font-size: 18px;
    flex: 0 1 auto;
    max-width: 60%;
    margin-right: 20px;
    margin-bottom: 20px;
    padding-right: 30px;
  }

  &::after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 1;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: transparent url(${IconChevronRight}) center no-repeat;
    background-size: contain;

    @media (max-width: ${BREAKPOINTS.tabletMax}px) {
      width: 14px;
      height: 14px;
      top: auto;
      bottom: 6px;
      transform: none;
    }
  }

  &:last-child {
    margin-right: 0;

    &::after {
      display: none;
    }
  }
`;

const SliderWrapper = styled.div`
  position: relative;
  z-index: 0;
  margin-top: 65px;
  width: 65%;
  max-width: 100%;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-top: 60px;
    width: 450px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-top: 60px;
    width: 100%;
  }
`;

const Item = styled.div``;

const ItemName = styled.div`
  font-size: 42px;
  line-height: 1;
  white-space: pre-line;

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    font-size: 26px;
  }
`;

const ItemDesc = styled.div`
  margin-top: 35px;
  font-size: 16px;
  width: 90%;
  height: 72px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-top: 10px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    height: auto;
    margin-top: 20px;
  }
`;

const ItemPreview = styled.div`
  margin-top: 60px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-top: 35px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-top: 40px;
  }
`;

const ItemPreviewImg = styled.img`
  display: block;
  width: 100%;
  pointer-events: none;
`;

const StagesSlider = styled(Swiper)`
  overflow: visible !important;

  .swiper-slide {
    position: absolute !important;
    z-index: 0 !important;
    top: 0 !important;
    transform: none !important;
    transition: left 0.8s ease-out !important;

    ${ItemName} {
      opacity: 0 !important;
      transition: opacity 0.2s ease-out !important;
    }

    ${ItemDesc} {
      opacity: 0 !important;
      transition: opacity 0.6s ease-out !important;
    }

    ${ItemPreview} {
      opacity: 0 !important;
      transition: opacity 1.2s ease-out !important;
    }

    &.swiper-slide-active {
      z-index: 2 !important;
      left: 0 !important;

      ${ItemName} {
        opacity: 1 !important;
      }

      ${ItemDesc} {
        opacity: 1 !important;
      }

      ${ItemPreview} {
        opacity: 1 !important;
      }
    }

    &.swiper-slide-prev,
    &.swiper-slide-next {
      z-index: 1 !important;
    }

    &.swiper-slide-prev {
      left: -35% !important;
    }

    &.swiper-slide-next {
      left: 35% !important;
    }
  }
`;

const SliderArrows = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${BREAKPOINTS.tabletMin}px) {
    position: absolute;
    z-index: 1;
    right: -166px;
    top: 0;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    flex-direction: row;
    margin-left: auto;
  }
`;

const SliderArrowIcon = styled.div`
  position: relative;
  z-index: 0;
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 999px;
  border: solid 1px #c3c4bd;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 35% auto;
  transition: transform 0.2s ease-out;
  cursor: pointer;
`;

const SliderArrowText = styled.div`
  position: absolute;
  top: 50%;
  left: 95px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 450;
  transform: translateY(-50%);
  transition: left 0.2s ease-out;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    display: none;
  }
`;

const SliderArrow = styled.button<{ showTooltip: boolean; show: boolean }>`
  position: relative;
  z-index: 0;
  margin-bottom: 25px;
  visibility: ${({ show }) => (!show ? 'hidden' : '')};
  opacity: 0.7;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    width: 64px;
    height: 64px;
    margin-bottom: 25px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-bottom: 0;
    margin-left: 15px;
  }

  ${SliderArrowText} {
    display: ${({ showTooltip }) => (!showTooltip ? 'none' : '')};
  }

  &:hover {
    opacity: 1;
  }
`;

const SliderArrowPrev = styled(SliderArrow)`
  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    order: 1;
  }

  ${SliderArrowIcon} {
    transform: rotate(45deg);
    background-image: url(${IconArrowPrev});

    &:hover {
      transform: scale(1.05) rotate(45deg);
    }
  }
`;

const SliderArrowNext = styled(SliderArrow)`
  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    order: 2;
  }

  ${SliderArrowIcon} {
    transform: rotate(45deg);
    background-image: url(${IconArrowNext});

    &:hover {
      transform: scale(1.05) rotate(45deg);
    }
  }
`;

const SliderMeta = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-top: 40px;
  }
`;
