import React from 'react';
import styled from 'styled-components';
import { useStore } from 'effector-react';

import { PipeMaterialType, $additionalHintVisible, $pipeMaterial } from 'stores/boring';
import { BREAKPOINTS } from 'ui/constants';
import { CalculatorBreadcrumbsStep } from 'ui/molecules/CalculatorBreadcrumbs';

interface CalculatorTitleProps {
  currentStep: CalculatorBreadcrumbsStep;
}

export const CalculatorTitle: React.FC<CalculatorTitleProps> = ({ currentStep }) => {
  const additionalHintVisible = useStore($additionalHintVisible);
  const pipeMaterial = useStore($pipeMaterial);

  const pipeMaterialTitles = {
    [PipeMaterialType.metal]: 'Конструкция скважины Металл',
    [PipeMaterialType.metalplastic]: 'Конструкция скважины Металл + пластик',
    [PipeMaterialType.pvcu]: 'Конструкция скважины нПВХ',
  };

  const calculatorStepTitles = {
    [CalculatorBreadcrumbsStep.boring]: '1 — Бурение скважины',
    [CalculatorBreadcrumbsStep.waterSupply]: '2 — Водоснабжение и обустройство',
    [CalculatorBreadcrumbsStep.sewerSystem]: '3 — Услуги канализации',
  };

  return currentStep === CalculatorBreadcrumbsStep.boring && additionalHintVisible ? (
    <PipeMaterialTitle>{pipeMaterialTitles[pipeMaterial]}</PipeMaterialTitle>
  ) : (
    <CalculatorTitleStyled>{calculatorStepTitles[currentStep]}</CalculatorTitleStyled>
  );
};

const CalculatorTitleStyled = styled.h1`
  margin-bottom: 86px;
  font-weight: 400;
  font-size: 72px;
  line-height: 1;

  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    font-size: 52px;
    margin-bottom: 40px;
    max-width: 450px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 36px;
    margin-bottom: 30px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    font-size: 28px;
  }
`;

const PipeMaterialTitle = styled(CalculatorTitleStyled)`
  font-size: 36px;
  font-weight: 400;
  line-height: 36px;
`;
