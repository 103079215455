import styled from 'styled-components';
import { rgba } from 'polished';

export const ToolTipDot = styled.div`
  position: absolute;
  z-index: 100;
  width: 0;
  height: 0;
`;

export const ToolTipText = styled.div`
  position: absolute;
  z-index: 2;
  padding: 15px 25px;
  background-color: ${rgba('#cfcfcf', 0.4)};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  font-weight: 300;
  font-style: italic;
  border-radius: 3px;

  strong {
    font-weight: 400;
  }

  p {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ToolTipGuide = styled.div`
  position: absolute;
  z-index: 2;
  background-color: transparent;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;
