import React from 'react';

import styled from 'styled-components';
import { rgba } from 'polished';
import { BREAKPOINTS, COLORS } from 'ui/constants';

interface CalculatorControlProps {
  title: string;
}

export const CalculatorControl: React.FC<CalculatorControlProps> = ({ title, children }) => (
  <CalculatorControlStyled>
    <Title>{title}</Title>
    {children}
  </CalculatorControlStyled>
);

const CalculatorControlStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  margin-bottom: 60px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-bottom: 35px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-bottom: 30px;
  }
`;

const Title = styled.p`
  min-height: 46px;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  margin-bottom: 32px;
  color: ${rgba(COLORS.blacktech, 0.65)};
  max-width: 200px;

  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    min-height: auto;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 15px;
  }
`;
