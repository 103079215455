import styled from 'styled-components';

import IconDownload from './static/icon-download.svg';

export const List = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Name = styled.div`
  font-size: 16px;
  font-weight: 300;
  transition: font-weight 0.2s ease-out;
`;

export const Meta = styled.div`
  font-size: 14px;
  opacity: 0.3;
`;

export const DownloadButton = styled.a`
  width: 32px;
  height: 32px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent url(${IconDownload}) center no-repeat;
  background-size: contain;
  opacity: 0;
  transition: opacity 0.2s ease-out, background-color 0.2s ease-out;
`;

export const Item = styled.div`
  display: block;
  width: 100%;
  position: relative;
  padding: 15px 20px;
  padding-right: 60px;
  background-color: #fff;

  &:hover {
    background-color: #f2f6f2;

    ${DownloadButton} {
      opacity: 1;
    }

    ${Name} {
      font-weight: 500;
      color: #28502e;
    }
  }
`;
