import React from 'react';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components';

import { BREAKPOINTS } from 'ui/constants';
import { MenuInformationPic } from 'ui/molecules/MenuInformationPic';
import { MenuWaterSupply, WaterSystemOption } from './MenuWaterSupply';
import { ToolTipDot, ToolTipText, ToolTipGuide } from 'ui/atoms/IllustrationToolTip';

import WaterSupply1bgURL from 'ui/molecules/MenuInformationPic/static/water-supply-1-bg.jpg';
import WaterSupply1fgURL from 'ui/molecules/MenuInformationPic/static/water-supply-1-fg.png';
import { ReactComponent as WaterSupply1C1 } from 'ui/molecules/MenuInformationPic/static/water-supply-1-c1.svg';
import BGGuideLine8 from './static/guide-line-08.svg';

export const MenuWaterSupplyLetnik: React.FC<RouteComponentProps> = () => (
  <MenuWaterSupply
    pic={<Pic />}
    currentStep={WaterSystemOption.summerOption}
    description={
      <Text>
        Скважинный оголовок предназначен для накрытия обсадной трубы скважины. Увеличивает надежность подвешивания
        скважинного насоса на тросу или веревке. Защищает скважины от попадания грязи, пыли, различных сторонних
        предметов,а также талых грунтовых вод. Защищает от кражи оборудования и комплектующих.
      </Text>
    }
  />
);

const Pic = () => (
  <MenuInformationPic backgroundURL={WaterSupply1bgURL} foregroundURL={WaterSupply1fgURL}>
    <WaterSupply1C1Styled />

    <ToolTipDot8>
      <ToolTipText8>
        Скважинный оголовок состоит из двух фланцев - верхнего и нижнего, и уплотняющего резинового кольца, которое
        помещается между ними. Верхний фланец имеет отверстия для вывода кабеля питания для насоса и отверстия для
        вывода трубы. На внутренней поверхности верхнего фланца есть карабин, на который крепится трос для удержания
        насоса в скважине.
      </ToolTipText8>
      <ToolTipGuide8 />
    </ToolTipDot8>
  </MenuInformationPic>
);

const WaterSupply1C1Styled = styled(WaterSupply1C1)`
  animation-delay: 0s;
  top: 53%;
  left: 64%;
  width: calc(95 / 996 * 100%);
  height: calc(57 / 451 * 100%);
`;

const Text = styled.p`
  font-size: 16px;
  width: 350px;
  max-width: 100%;

  @media (min-width: ${BREAKPOINTS.desktopMin}px) {
    font-size: 18px;
    width: 480px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 18px;
    line-height: 1.4;
    width: 100%;
  }
`;

const LocalToolTipDot = styled(ToolTipDot)`
  @media (max-width: ${BREAKPOINTS.laptopMax}px) {
    transform: scale(0.9);
  }

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    transform: scale(0.8);
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    transform: scale(0.7);
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    transform: scale(0.8);
  }
`;

const ToolTipDot8 = styled(LocalToolTipDot)`
  left: 59%;
  top: 53%;
`;

const ToolTipText8 = styled(ToolTipText)`
  width: 330px;
  height: 237px;
  clip-path: polygon(100% 0, 100% 80%, 90% 100%, 0 100%, 0 0);
  top: -30px;
  right: 153px;
`;

const ToolTipGuide8 = styled(ToolTipGuide)`
  background-image: url(${BGGuideLine8});
  width: 159px;
  height: 74px;
  top: 0;
  right: 0;
`;
