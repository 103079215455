import React, { useState } from 'react';
import styled from 'styled-components';
import { lighten, rgba } from 'polished';
import { navigate, Link, RouteComponentProps } from '@reach/router';
import orderBy from 'lodash/orderBy';

import { showCallbackForm } from 'stores/callbackForm';
import { ButtonPrimary, ButtonGhost, ButtonIcon } from 'ui/atoms/Button';
import { Icon } from 'ui/atoms/Icon';
import { COLORS } from 'ui/constants';
import { Highlight } from 'ui/atoms/Highlight';
import * as СontractItem from 'ui/atoms/СontractItem';
import { useWindowSize } from 'hooks/useWindowSize';
import { useDocuments, API_ROOT, DocumentI } from 'hooks/useApiCall';

import ImgLogo from './static/LogoRus.svg';
import ImgLogoAbout from './static/logo-about.svg';
import IconClose from './static/icon-close.svg';
import IconCloseSmall from './static/icon-close-small.svg';
import IconChevron from './static/icon-chevron.svg';
import IconChevronActive from './static/icon-chevron-active.svg';
import IconArrowBack from './static/icon-arrow-back.svg';

export const MenuMobilePrimary: React.FC<RouteComponentProps> = () => {
  const [secondaryMenuActive, setSecondaryMenuActive] = useState(false);
  const windowSize = useWindowSize();
  let { data: documents } = useDocuments();
  documents = documents?.length ? orderBy(documents, 'id', 'desc') : documents;

  const [contractsExpanded, setContractsExpanded] = useState(false);
  const [pricesExpanded, setPricesExpanded] = useState(false);

  const onCalculateClick = () => {
    navigate('/calculator');
  };

  const onCloseClick = () => {
    navigate('/');
  };

  const documentsJSX = (type: DocumentI['type']) => {
    if (!Array.isArray(documents) || !documents.length) return;

    const documentsByType = documents.filter((i) => i.type === type);
    return documentsByType.map((document) => {
      if (!Array.isArray(document.file) || !document.file.length) return null;
      const file = document.file[0];

      return (
        <СontractItem.Item>
          <СontractItem.Name>{document.name}</СontractItem.Name>
          <СontractItem.Meta>{`${file.ext.toUpperCase()}, ${file.size} Кб`}</СontractItem.Meta>

          <СontractItem.DownloadButton href={`${API_ROOT}${file.url}`} target="_blank" />
        </СontractItem.Item>
      );
    });
  };

  return (
    <Wrapper height={windowSize.height}>
      <Header>
        <HeaderLogo>
          <HeaderLogoImg src={ImgLogo} />
        </HeaderLogo>

        <HeaderCloseButton onClick={onCloseClick}>Закрыть</HeaderCloseButton>
      </Header>

      <Main>
        <Menu>
          {!secondaryMenuActive && (
            <Nav>
              <NavLink to="/menu/boring">Бурение скважин</NavLink>
              <NavLink to="/menu/water-supply/kesson">Водоснабжение</NavLink>
              <NavLink to="/menu/sewer-system">Автономная канализация</NavLink>
              <DropLink
                onClick={() => {
                  setPricesExpanded(true);
                }}
              >
                Прайс лист
              </DropLink>
              <NavToggle as="button" onClick={() => setSecondaryMenuActive(true)}>
                Еще
              </NavToggle>
            </Nav>
          )}

          {secondaryMenuActive && (
            <Nav>
              <NavLink to="/warranty">Гарантийное обслуживание</NavLink>
              <NavLink to="/certificates">Сертификаты</NavLink>
              <DropLink
                onClick={() => {
                  setContractsExpanded(true);
                }}
              >
                Шаблоны договоров
              </DropLink>
              <NavLink to="/faq">Вопросы и ответы</NavLink>
              <NavToggle as="button" onClick={() => setSecondaryMenuActive(false)} back>
                Назад
              </NavToggle>
            </Nav>
          )}

          <SecondaryNav>
            <SecondaryNavLink to="/menu/contacts">Наши контакты</SecondaryNavLink>
            <SecondaryNavLink to="/menu/about">О нас</SecondaryNavLink>
          </SecondaryNav>
        </Menu>
      </Main>

      {pricesExpanded && (
        <DropLinkPopup>
          <DropLinkPopupMain>
            <СontractItem.Item as={Link} to="/menu/prices">
              <СontractItem.Name>Бурение скважины</СontractItem.Name>
            </СontractItem.Item>
            <СontractItem.Item as={Link} to="/menu/prices/sewer-system">
              <СontractItem.Name>Обустройство и канализация</СontractItem.Name>
            </СontractItem.Item>
          </DropLinkPopupMain>

          <DropLinkPopupControls>
            <DropLinkPopupLabel>Прайс лист</DropLinkPopupLabel>
            <DropLinkPopupClose
              onClick={() => {
                setPricesExpanded(false);
              }}
            />
          </DropLinkPopupControls>
        </DropLinkPopup>
      )}

      {contractsExpanded && (
        <DropLinkPopup>
          <DropLinkPopupMain>{documentsJSX('document')}</DropLinkPopupMain>

          <DropLinkPopupControls>
            <DropLinkPopupLabel>Шаблоны договоров</DropLinkPopupLabel>
            <DropLinkPopupClose
              onClick={() => {
                setContractsExpanded(false);
              }}
            />
          </DropLinkPopupControls>
        </DropLinkPopup>
      )}

      <CalcPriceButton onClick={onCalculateClick}>
        <ButtonIcon>
          <Icon iconName="IconCalc" size={18} color={COLORS.whitetech} />
        </ButtonIcon>
        Рассчитать стоимость
      </CalcPriceButton>
    </Wrapper>
  );
};

export const MenuMobileContacts: React.FC<RouteComponentProps> = () => {
  const windowSize = useWindowSize();

  const onCloseClick = () => {
    navigate('/');
  };

  const onMenuClick = () => {
    navigate('/menu');
  };

  return (
    <Wrapper height={windowSize.height}>
      <Header>
        <HeaderBackButton onClick={onMenuClick}>Меню</HeaderBackButton>

        <HeaderCloseButton onClick={onCloseClick}>Закрыть</HeaderCloseButton>
      </Header>

      <Main>
        <Contacts>
          <Title>Наши контакты</Title>

          <ContactsItem>
            <ContactsLabel>Адрес офиса</ContactsLabel>
            <ContactsAddress>МО, г. Чехов, ул. Cенная, 20</ContactsAddress>
          </ContactsItem>

          <ContactsItem>
            <ContactsLabel>Контакты</ContactsLabel>
            <ContactsPhone href="tel:+74951015060">+7 (495) 101-50-60</ContactsPhone>
            <ContactsEmail href="mailto:info@hydrotechpro.ru">info@hydrotechpro.ru</ContactsEmail>
          </ContactsItem>

          <ContactsLink
            onClick={() => {
              showCallbackForm();
            }}
          >
            <ButtonIcon>
              <Icon iconName="IconArrow45" size={18} rotate={-90} color={COLORS.yellowtech} />
            </ButtonIcon>
            Связаться с нами
          </ContactsLink>

          <Meta>
            <Copyright>© 2020 ООО “Гидротех”. Все права защищены</Copyright>
            <Socials>
              <SocialsItem target="_blank" href="https://www.facebook.com/1891168824535203">
                Фейсбук
              </SocialsItem>
              <SocialsItem target="_blank" href="https://www.instagram.com/hydrotechpro/">
                Инстаграм
              </SocialsItem>
              <SocialsItem target="_blank" href="https://vk.com/hydrotechpro">
                Вконтакте
              </SocialsItem>
              <SocialsItem target="_blank" href="https://www.youtube.com/channel/UC9GICyJoMn1MuDKznnPLwKA">
                Ютуб
              </SocialsItem>
            </Socials>
          </Meta>
        </Contacts>
      </Main>
    </Wrapper>
  );
};

export const MenuMobileAbout: React.FC<RouteComponentProps> = () => {
  const windowSize = useWindowSize();

  const onCalculateClick = () => {
    navigate('/calculator');
  };

  const onMenuClick = () => {
    navigate('/menu');
  };

  const onCloseClick = () => {
    navigate('/');
  };

  return (
    <Wrapper height={windowSize.height}>
      <Header>
        <HeaderBackButton onClick={onMenuClick}>Меню</HeaderBackButton>

        <HeaderCloseButton onClick={onCloseClick}>Закрыть</HeaderCloseButton>
      </Header>

      <Main>
        <About>
          <AboutLogo>
            <AboutLogoImg src={ImgLogoAbout} />
          </AboutLogo>

          <AboutTextWrapper>
            <AboutText>
              Гидротех - команда настоящих профессионалов бурового дела с опытом работы более <strong>20 лет.</strong>{' '}
              Квалифицированные специалисты, качественные материалы и жесткий контроль качества на каждом этапе - основа
              успешной работы нашей компании!
            </AboutText>
          </AboutTextWrapper>
        </About>
      </Main>

      <CalcPriceButton onClick={onCalculateClick}>
        <ButtonIcon>
          <Icon iconName="IconCalc" size={18} color={COLORS.whitetech} />
        </ButtonIcon>
        Рассчитать стоимость
      </CalcPriceButton>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ height: number }>`
  height: ${({ height }) => `${height}px`};
  max-height: -moz-available; /* WebKit-based browsers will ignore this. */
  max-height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  max-height: fill-available;
  display: flex;
  flex-direction: column;
`;

const Main = styled.div`
  flex: 1 1 auto;
  max-height: 100%;
  overflow: auto;
`;

const Header = styled.header`
  flex: 0 0 auto;
  padding: 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eef0ec;
`;

const HeaderLogo = styled.a``;

const HeaderLogoImg = styled.img`
  display: block;
  width: 110px;
`;

const HeaderCloseButton = styled.button`
  margin-left: auto;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  padding-right: 20px;
  background: transparent url(${IconClose}) center right no-repeat;
  background-size: auto 88%;
  opacity: 0.65;
`;

const HeaderBackButton = styled.button`
  padding-left: 30px;
  background: transparent url(${IconArrowBack}) center left no-repeat;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
`;

const Menu = styled.div`
  padding: 60px 20px;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const NavLink = styled(Link)`
  color: ${COLORS.blacktech};
  margin-bottom: 20px;
  font-size: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const DropLink = styled.button`
  color: ${COLORS.blacktech};
  margin-bottom: 20px;
  font-size: 24px;
`;

const NavToggle = styled(NavLink)<{ back?: boolean }>`
  position: relative;
  color: ${COLORS.yellowtech};
  padding-right: 30px;
  align-self: ${({ back }) => (back ? 'flex-end' : 'flex-start')};
  margin-top: 6px;

  &::after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    right: 0;
    background: transparent url(${IconChevronActive}) center no-repeat;
    background-size: contain;
    transform: ${({ back }) => (back ? 'rotate(180deg) translateY(40%)' : 'translateY(-40%)')};
  }
`;

const SecondaryNav = styled.nav`
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SecondaryNavLink = styled(NavLink)`
  margin-bottom: 20px;
  padding-right: 30px;
  background: transparent url(${IconChevron}) center right no-repeat;

  &:last-child {
    margin-bottom: 0;
  }
`;

const CalcPriceButton = styled(ButtonPrimary)`
  flex: 0 0 auto;

  ${ButtonIcon} {
    margin-right: 10px;
  }
`;

const Title = styled.div`
  font-size: 24px;
`;

const About = styled.div`
  padding: 60px 20px;
`;

const AboutLogo = styled.div``;

const AboutLogoImg = styled.img`
  display: block;
`;

const AboutTextWrapper = styled.div`
  margin-top: 35px;
`;

const AboutText = styled(Highlight)`
  font-size: 26px;
  line-height: 1.3;
`;

const Contacts = styled.div`
  padding: 60px 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100%;
`;

const ContactsItem = styled.div`
  margin-top: 35px;
  display: flex;
  flex-direction: column;
`;

const ContactsLabel = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  color: ${COLORS.blacktech};
  opacity: 0.45;
  margin-bottom: 10px;
`;

const ContactsAddress = styled.div`
  font-size: 16px;
  width: 240px;
  max-width: 100%;
`;

const ContactsPhone = styled.a`
  font-size: 16px;
`;

const ContactsEmail = styled.a`
  font-size: 16px;
`;

const ContactsLink = styled(ButtonGhost)`
  margin-top: 60px;
  margin-bottom: 20px;
  width: auto;
  color: ${COLORS.yellowtech};

  ${ButtonIcon} {
    margin-right: 10px;
  }
`;

const Meta = styled.div`
  margin-top: auto;
`;

const Copyright = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  opacity: 0.45;
`;

const Socials = styled.div`
  margin-top: 10px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(4, auto);
  justify-content: start;
`;

const SocialsItem = styled.a`
  font-size: 14px;
  text-transform: uppercase;
  color: ${COLORS.blacktech};
  transition: color 0.2s ease-out, transform 0.2s ease-out;

  &:hover {
    color: ${lighten(0.15, COLORS.blacktech)};
    transform: translateY(-1px);
  }
`;

const DropLinkPopup = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #fff;
  max-height: 70vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: -20px 0 30px ${rgba('#0a0908', 0.05)};
`;

const DropLinkPopupMain = styled(СontractItem.List)`
  flex: 1 1 auto;
`;

const DropLinkPopupControls = styled.div`
  position: relative;
  z-index: 10;
  flex: 0 0 60px;
  height: 60px;
  border-top: 1px solid #eef0ec;
  display: flex;
  align-items: center;
  padding: 5px 20px;
`;

const DropLinkPopupLabel = styled.div`
  font-size: 18px;
  opacity: 0.65;
`;

const DropLinkPopupClose = styled.button`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent url(${IconCloseSmall}) center no-repeat;
  background-size: contain;
`;
