import React from 'react';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components';

import { PricesWrapper } from './PricesWrapper';
import * as PriceTableStyles from './PricesTable';
import { BREAKPOINTS } from 'ui/constants';
import { useDocuments, API_ROOT, useBoringPrices } from 'hooks/useApiCall';

export const PriceListBoring: React.FC<RouteComponentProps> = () => {
  let { data: documents } = useDocuments();
  const boringDoc = documents && documents.find((i) => i.name === 'Прайс лист на бурение');
  const boringDocURL = boringDoc && `${API_ROOT}${boringDoc?.file[0]?.url}`;
  let { data: boringData } = useBoringPrices();

  return (
    <PricesWrapper
      downloadDocLink={boringDocURL}
      title={
        <>
          Бурение скважин <span>Цены в рублях за погонный метр</span>
        </>
      }
    >
      <BoringTableHead>
        <PriceTableStyles.HeadItem>Административный район</PriceTableStyles.HeadItem>
        <PriceTableStyles.HeadItem>
          Глубина <span>(м)</span>
        </PriceTableStyles.HeadItem>
        <PriceTableStyles.HeadItem>
          Металл <span>(Ø 133)</span>
        </PriceTableStyles.HeadItem>
        <PriceTableStyles.HeadItem>
          <div>
            Металл <span>(Ø 133)</span>
          </div>
          <div>
            Пластик <span>(Ø 117)</span>
          </div>
        </PriceTableStyles.HeadItem>
        <PriceTableStyles.HeadItem>
          Металл <span>(Ø 159)</span>
        </PriceTableStyles.HeadItem>
        <PriceTableStyles.HeadItem>
          <div>
            Металл <span>(Ø 159)</span>
          </div>
          <div>
            Пластик <span>(Ø 125)</span>
          </div>
        </PriceTableStyles.HeadItem>
        <PriceTableStyles.HeadItem>
          НПВХ <span>Ø125</span>
        </PriceTableStyles.HeadItem>
      </BoringTableHead>

      <PriceTableStyles.Body>
        {boringData &&
          boringData.map((i) => (
            <BoringTableRow key={i.id}>
              <PriceTableStyles.Item>{i.region}</PriceTableStyles.Item>
              <PriceTableStyles.Item>{i.depth}</PriceTableStyles.Item>
              <PriceTableStyles.Item>{i.t1}</PriceTableStyles.Item>
              <PriceTableStyles.Item>{i.t2}</PriceTableStyles.Item>
              <PriceTableStyles.Item>{i.t3}</PriceTableStyles.Item>
              <PriceTableStyles.Item>{i.t4}</PriceTableStyles.Item>
              <PriceTableStyles.Item>{i.t5}</PriceTableStyles.Item>
            </BoringTableRow>
          ))}
      </PriceTableStyles.Body>
    </PricesWrapper>
  );
};

const BoringTableHead = styled(PriceTableStyles.Head)`
  grid-template-columns: minmax(250px, 2fr) repeat(6, minmax(140px, 1fr));

  @media (max-width: ${BREAKPOINTS.laptopMax}px) {
    grid-template-columns: minmax(200px, 2fr) repeat(6, minmax(140px, 1fr));
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    grid-template-columns: minmax(150px, 2fr) repeat(6, minmax(140px, 1fr));
  }
`;

const BoringTableRow = styled(PriceTableStyles.Row)`
  grid-template-columns: minmax(250px, 2fr) repeat(6, minmax(140px, 1fr));

  @media (max-width: ${BREAKPOINTS.laptopMax}px) {
    grid-template-columns: minmax(200px, 2fr) repeat(6, minmax(140px, 1fr));
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    grid-template-columns: minmax(150px, 2fr) repeat(6, minmax(140px, 1fr));
  }
`;
