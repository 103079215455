import React from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';

import { Container as ContainerDefault } from 'ui/atoms/Container';
import { Icon } from 'ui/atoms/Icon';
import { COLORS, BREAKPOINTS } from 'ui/constants';
import { useMediaBreakpoint } from 'hooks/useMediaBreakpoint';

export const Footer = () => {
  const { isPhone, isTablet } = useMediaBreakpoint();

  return (
    <Body>
      <Container>
        <Copyright>
          © 2020 ООО “Гидротех”.
          <br />
          Все права защищены
        </Copyright>

        <Email href="mailto:info@hydrotechpro.ru">info@hydrotechpro.ru</Email>

        <MadeBy>
          Разработка сайта — <MadeByLink href="https://www.behance.net/akodo">akōdo</MadeByLink>
        </MadeBy>

        <Socials>
          <SocialItem href="https://www.facebook.com/1891168824535203" target="_blank">
            <Icon iconName="IconFacebook" size={isPhone || isTablet ? 20 : 24} />
          </SocialItem>
          <SocialItem href="https://www.instagram.com/hydrotechpro/" target="_blank">
            <Icon iconName="IconInstagram" size={isPhone || isTablet ? 20 : 24} />
          </SocialItem>
          <SocialItem href="https://www.youtube.com/channel/UC9GICyJoMn1MuDKznnPLwKA" target="_blank">
            <Icon iconName="IconYoutube" size={isPhone || isTablet ? 20 : 24} />
          </SocialItem>
          <SocialItem href="https://vk.com/hydrotechpro" target="_blank">
            <Icon iconName="IconVkontakte" size={isPhone || isTablet ? 20 : 24} />
          </SocialItem>
        </Socials>
      </Container>
    </Body>
  );
};

const Body = styled.footer`
  background-color: #f6f9f5;
  padding: 20px 0;

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    padding: 40px 0;
  }
`;

const Container = styled(ContainerDefault)`
  display: grid;
  grid-template-columns: 45% repeat(3, auto);
  align-items: center;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    grid-template-columns: repeat(4, auto);
    grid-gap: 25px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    grid-template-columns: 100%;
    grid-gap: 0;
  }
`;

const Copyright = styled.p`
  color: ${rgba(COLORS.blacktech, 0.45)};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 450;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 9px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    font-size: 14px;
  }

  br {
    @media (min-width: ${BREAKPOINTS.tabletMin}px) {
      display: none !important;
    }
  }
`;

const Link = styled.a`
  color: ${rgba(COLORS.blacktech, 0.45)};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 450;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 9px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    font-size: 14px;
  }
`;

const Email = styled(Link)`
  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-top: 30px;
  }
`;

const MadeBy = styled.div`
  color: ${rgba(COLORS.blacktech, 0.45)};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 450;
  justify-self: center;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 9px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    font-size: 14px;
    justify-self: start;
  }
`;

const MadeByLink = styled(Link)``;

const Socials = styled.div`
  display: flex;
  align-items: center;
  justify-self: end;

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-top: 20px;
    justify-self: start;
  }
`;

const SocialItem = styled.a`
  margin-right: 18px;
  opacity: 0.45;
  transition: opacity 0.2s ease-out;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-right: 10px;
  }

  svg,
  span {
    display: block;
  }

  &:hover {
    opacity: 0.8;
  }

  &:last-child {
    margin-right: 0;
  }
`;
