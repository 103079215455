import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { BREAKPOINTS, COLORS } from 'ui/constants';

import BackgroundURL from 'ui/atoms/CalculatorBackground.svg';
import Background2URL from 'ui/atoms/CalculatorBackground2.svg';
import { useMediaBreakpoint } from 'hooks/useMediaBreakpoint';

interface CalculatorProps {
  header: React.ReactNode;
  breadcrumbs: React.ReactNode;
  hint: React.ReactNode;
  nextButton: React.ReactNode;
  skipButton: React.ReactNode;
  totals: React.ReactNode;
  title: React.ReactNode;
  closeButton: React.ReactNode;
  questionsButton: React.ReactNode;
}

export const Calculator: React.FC<CalculatorProps> = ({
  children,
  header,
  breadcrumbs,
  hint,
  nextButton,
  skipButton,
  totals,
  title,
  closeButton,
  questionsButton,
}) => {
  const { isPhone } = useMediaBreakpoint();
  return (
    <CalculatorStyled>
      {isPhone && (
        <>
          <HeaderStyled>{header}</HeaderStyled>
          <MobileBreadcrumbsStyled>{breadcrumbs}</MobileBreadcrumbsStyled>
          <MobileTitleStyled>{title}</MobileTitleStyled>
          <MobileTotals>{totals}</MobileTotals>
        </>
      )}
      {!isPhone && (
        <HintStyled>
          <CloseButtonWrapper>{closeButton}</CloseButtonWrapper>
          <Hint>
            <TitleStyled>{title}</TitleStyled>
            {hint}
          </Hint>
          <QuestionsButtonWrapper>{questionsButton}</QuestionsButtonWrapper>
        </HintStyled>
      )}
      <MainStyled>
        {!isPhone && <BreadcrumbsStyled>{breadcrumbs}</BreadcrumbsStyled>}
        <ContentStyled>{children}</ContentStyled>
        {isPhone && (
          <>
            <MobileHintContainer>{hint}</MobileHintContainer>
            <QuestionsButtonWrapper>{questionsButton}</QuestionsButtonWrapper>
          </>
        )}
        <ControlsAndTotals>
          <StepControlsWrapper>
            <NextButtonWrapper>{nextButton}</NextButtonWrapper>
            <SkipButtonWrapper>{skipButton}</SkipButtonWrapper>
          </StepControlsWrapper>
          {isPhone && (
            <StickyNav>
              <NextButtonWrapper>{nextButton}</NextButtonWrapper>
              <SkipButtonWrapper>{skipButton}</SkipButtonWrapper>
            </StickyNav>
          )}
          {!isPhone && <TotalsInControls>{totals}</TotalsInControls>}
        </ControlsAndTotals>
      </MainStyled>
    </CalculatorStyled>
  );
};

const HeaderStyled = styled.div`
  @media (min-width: ${BREAKPOINTS.tabletMin}px) {
    display: none;
  }

  padding-bottom: 60px;
`;

const CalculatorStyled = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background-image: url(${BackgroundURL});

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    flex-direction: column;
    background-image: url(${Background2URL});
  }
`;

const MainStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 43%;
  padding: 180px 80px 100px;
  overflow-y: auto;

  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    padding: 128px 80px 100px;
    flex-basis: 48%;
  }

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    padding: 90px 60px 100px;
    flex-basis: 50%;
  }

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    padding: 115px 50px 90px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    padding: 55px 40px 100px 60px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    padding: 30px 20px 0;
    overflow: visible;
  }
`;

const MobileBreadcrumbsStyled = styled.nav`
  padding: 20px 0 20px 20px;
  font-size: 18px;
  line-height: 28px;
`;

const BreadcrumbsStyled = styled.nav`
  margin-bottom: 80px;

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    margin-bottom: 60px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-bottom: 40px;
  }
`;

const ContentStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  > * {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    padding-right: 20px;

    @media (max-width: ${BREAKPOINTS.phoneMax}px) {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
      padding-right: 0;
    }
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    max-width: 540px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
  }
`;

const ControlsAndTotals = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: auto;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    padding-top: 56px;
    align-items: center;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    padding-top: 56px;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
  }
`;

const HintStyled = styled.div`
  position: relative;
  background-color: ${rgba(COLORS.blacktech, 0.02)};
  flex-basis: 57%;
  padding: 40px 82px 110px 100px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-height: 100vh;

  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    flex-basis: 52%;
  }

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    padding: 35px 60px 70px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    padding: 40px 60px 90px;
    min-height: 496px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    padding: 0 20px;
  }
`;

const MobileTitleStyled = styled.div`
  padding: 10px 20px 0;
  max-width: 280px;
`;

const TitleStyled = styled.div`
  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    font-size: 52px;
    font-weight: 400;
    line-height: 52px;
  }
`;

const Hint = styled.div`
  flex: 1;

  min-height: 0;

  display: flex;
  flex-direction: column;
  justify-content: stretch;
  margin-top: 105px;
  margin-bottom: 24px;

  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    margin-top: 60px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-top: 6px;
    margin-bottom: 15px;
  }
`;

const MobileTotals = styled.div`
  padding: 6px 20px 20px;
  border-bottom: 1px solid ${rgba(COLORS.blacktech, 0.1)};
`;

const MobileHintContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TotalsInControls = styled.div``;

const CloseButtonWrapper = styled.div`
  align-self: flex-start;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    align-self: flex-end;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    display: none;
  }
`;

const QuestionsButtonWrapper = styled.div`
  align-self: flex-start;

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-top: 20px;
  }
`;

const StepControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    visibility: hidden;
  }
`;

const StickyNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;

  position: fixed;
  z-index: 100;
  min-height: 48px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${COLORS.whitetech};
`;

const NextButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    height: 42px;
    padding: 0;
    flex-basis: 60%;
  }
`;

const SkipButtonWrapper = styled.div`
  margin-left: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    margin-left: 0px;
    padding: 0 20px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-left: 20px;
    padding: 0 20px;
    height: 42px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-left: 10px;
    padding: 0;
    order: -1;
    flex-basis: 40%;
  }
`;
