import { AboutItemI } from './AboutUs';

export const ABOUT_US: AboutItemI[] = [
  {
    name: '+15 лет',
    label: 'опыта на рынке.',
    desc:
      'Собственная производственная база, в наличии все необходимое оборудование, а главное — это опыт наших специалистов.',
    descShort: 'Собственная производственная база',
  },
  {
    name: '10 лет',
    label: 'Гарантии',
    desc: 'В наличии  все необходимые сертификаты',
    descShort: 'В наличии  все необходимые сертификаты',
  },
  {
    name: '+12 тыс.',
    label: 'Довольных клиентов',
    desc: 'Работаем без предоплаты и в любую погоду',
    descShort: 'Работаем без предоплаты и в любую погоду',
  },
];
