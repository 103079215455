import React from 'react';

import { showOrderForm } from "stores/misc";
import { useMediaBreakpoint } from "hooks/useMediaBreakpoint";

import { Icon } from "ui/atoms/Icon";
import { ButtonGhost, ButtonIcon } from "ui/atoms/Button";
import { Header } from "ui/organisms/Header";
import { Explanation } from "ui/templates/Explanation";
import {
  ExplanationBreadcrumbs,
  ExplanationBreadcrumbsStep,
} from "ui/molecules/ExplanationBreadcrumbs";
import { ExplanationHint } from "./ExplanationHint";
import { NextButton, ResetCalculatorButton } from "./atoms";
import { OrderForm } from "../OrderForm";

interface ExplanationStepPageProps {
  currentStep: ExplanationBreadcrumbsStep,
  onCloseButtonClick: () => void,
  goToCalculator: () => void,
};

export const ExplanationStepTemplate: React.FC<ExplanationStepPageProps> = ({
  children,
  onCloseButtonClick,
  currentStep,
  goToCalculator,
}) => {
  const { isPhone } = useMediaBreakpoint();

  return (
    <Explanation
      header={<Header />}
      orderForm={<OrderForm />}
      breadcrumbs={<ExplanationBreadcrumbs currentStep={currentStep} />}
      hint={<ExplanationHint currentStep={currentStep} />}
      nextButton={(
        <NextButton onClick={() => { showOrderForm(); }}>Оформить заявку</NextButton>
      )}
      resetButton={(
        <ResetCalculatorButton onClick={goToCalculator}>
          Перерассчитать
          {!isPhone && (
            <ButtonIcon>
              <Icon iconName="IconNext" size={18} />
            </ButtonIcon>
          )}
        </ResetCalculatorButton>
      )}
      closeButton={(
        <ButtonGhost onClick={onCloseButtonClick}>
          <Icon iconName="IconClose" size={32} />
        </ButtonGhost>
      )}
    >
      {children}
    </Explanation>
  );
};
