import React from 'react';
import { rgba } from 'polished';

import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'ui/constants';

type RoundButtonProps = {
  icon: React.ReactNode;
  small?: boolean;
  ghost?: boolean;
  onClick: () => void;
  className?: string;
};

export const RoundButton: React.FC<RoundButtonProps> = ({ icon, ghost, small, onClick, children, className }) => (
  <ButtonStyled onClick={onClick} className={className}>
    <Circle ghost={ghost} small={small}>
      {icon}
    </Circle>
    {children && <Label>{children}</Label>}
  </ButtonStyled>
);

const ButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Label = styled.span`
  text-transform: uppercase;
  margin-left: 40px;

  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    font-size: 14px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-left: 28px;
  }
`;

interface CircleI {
  small?: boolean;
  ghost?: boolean;
}

export const Circle = styled.div<CircleI>`
  height: ${(p) =>
    ({
      true: '96px',
      [String(p.small)]: '64px',
    }.true)};
  width: ${(p) =>
    ({
      true: '96px',
      [String(p.small)]: '64px',
    }.true)};
  border-radius: 50%;
  border: ${(p) =>
    ({
      true: 'none',
      [String(p.ghost)]: `1px solid ${rgba(COLORS.blacktech, 0.15)}`,
    }.true)};
  color: ${(p) => (p.ghost ? COLORS.blacktech : COLORS.whitetech)};
  background-color: ${(p) => (p.ghost ? rgba(COLORS.whitetech, 0.15) : COLORS.yellowtech)};

  display: flex;
  align-items: center;
  justify-content: center;
`;
