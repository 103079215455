import { useCalculations } from 'hooks/useCalculations';
import React, { useState } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { useMediaBreakpoint } from 'hooks/useMediaBreakpoint';

import { BREAKPOINTS, COLORS } from 'ui/constants';
import { Icon } from 'ui/atoms/Icon';

export const CalculatorTotals = () => {
  const {
    calculations: { boringCost, waterSupplyCost, sewerSystemCost, total },
  } = useCalculations();

  const [expanded, setExpanded] = useState(false);

  const { isPhone } = useMediaBreakpoint();

  const toggleDetails = () => {
    setExpanded((v) => !v);
  };

  return (
    <Totals
      tabIndex={0}
      role="button"
      onBlur={() => {
        setExpanded(false);
      }}
    >
      <HoverWrapper>
        {expanded && (
          <Hover>
            <li>
              <h4>Скважина</h4>
              <div>{boringCost} руб.</div>
            </li>
            <li>
              <h4>Водоснабжение</h4>
              <div>{waterSupplyCost} руб.</div>
            </li>
            <li>
              <h4>Канализация</h4>
              <div>{sewerSystemCost} руб.</div>
            </li>
          </Hover>
        )}
      </HoverWrapper>
      <TotalsButton type="button" onClick={toggleDetails} expanded={expanded}>
        <TotalsButtonLabel>
          {isPhone ? 'Общая стоимость' : 'Общая стоимость работ'}
          <IconLocal iconName="IconDropdown" size={16} />
        </TotalsButtonLabel>
        <TotalsButtonPrice>
          <TotalsButtonPriceText>{total} руб.</TotalsButtonPriceText>
          <IconLocal
            iconName="IconDropdown"
            size={16}
            // size={isPhone ? 16 : 20}
            rotate={expanded ? 270 : 0}
            color={expanded ? COLORS.yellowtech : COLORS.blacktech}
          />
        </TotalsButtonPrice>
      </TotalsButton>
    </Totals>
  );
};

const Totals = styled.div``;

const HoverWrapper = styled.div`
  position: relative;
`;

const Hover = styled.ul`
  position: absolute;
  width: 120%;
  bottom: 0;
  right: 0;
  left: auto;
  padding: 30px 40px 30px 40px;

  box-shadow: 0px 17px 30px rgba(10, 9, 8, 0.05);
  border-radius: 2px;
  background-color: ${COLORS.whitetech};

  font-size: 24px;
  font-weight: 600;
  line-height: 36px;

  z-index: 50;

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    top: 60px;
    bottom: auto;
    width: 100%;
  }

  li:not(:first-of-type) {
    margin-top: 20px;
  }

  h4 {
    font-size: 14px;
    font-weight: 450;
    line-height: 18px;
    text-transform: uppercase;
    color: ${rgba(COLORS.blacktech, 0.45)};
  }
`;

const IconLocal = styled(Icon)`
  display: flex;
  margin-left: 40px;

  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    margin-left: 10px;
  }
`;

const TotalsButtonLabel = styled.h4`
  display: flex;
  text-align: left;
  font-size: 16px;
  font-weight: 450;
  line-height: 21px;
  text-transform: uppercase;
  color: ${rgba(COLORS.blacktech, 0.45)};

  ${IconLocal} {
    visibility: hidden;
  }

  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    font-size: 14px;
    line-height: 18px;
  }

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    font-size: 12px;
    line-height: 16px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    max-width: 100px;
  }
`;

const TotalsButtonPrice = styled.div`
  display: flex;
  align-items: center;
`;

const TotalsButtonPriceText = styled.span`
  font-size: 36px;
  font-weight: 600;
  line-height: 48px;

  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    font-size: 30px;
    line-height: 38px;
  }

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 28px;
    line-height: 36px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    font-size: 24px;
    line-height: 31px;
  }
`;

const TotalsButton = styled.button<{ expanded: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  cursor: pointer;
  font-size: 36px;
  font-weight: 600;
  line-height: 48px;
  color: ${(p) => (p.expanded ? rgba(COLORS.yellowtech, 0.85) : rgba(COLORS.blacktech, 0.85))};

  @media (hover: hover) {
    :hover {
      ${TotalsButtonPrice} {
        color: ${COLORS.yellowtech};
      }
    }
  }

  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    font-size: 30px;
    line-height: 38px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 28px;
    line-height: 36px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    font-size: 24px;
    line-height: 31px;
  }
`;
