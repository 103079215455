import React, { useRef, useState } from 'react';
import { useStore } from 'effector-react';

import { rgba } from 'polished';
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import { useCalculations } from 'hooks/useCalculations';
import { useOrder } from 'hooks/useOrder';
import { $phoneNumber, setPhoneNumber, $email, setEmail } from 'stores/userInfo';
import { $orderFormVisible, hideOrderForm } from 'stores/misc';

import { BREAKPOINTS, COLORS } from 'ui/constants';
import { Highlight } from 'ui/atoms/Highlight';
import { Input } from 'ui/atoms/Input';
import { ButtonGhost, ButtonIcon } from 'ui/atoms/Button';
import { Icon } from 'ui/atoms/Icon';
import { Overlay } from 'ui/atoms/Overlay';
import { OrderFormInput } from 'ui/molecules/OrderFormInput';

import BackgroundURL from './static/bg.svg';

export const OrderForm = () => {
  const {
    calculations: { total },
    explanationTexts: {
      total: { timing },
    },
  } = useCalculations();

  const phoneNumber = useStore($phoneNumber);
  const email = useStore($email);

  const orderFormVisible = useStore($orderFormVisible);

  const phoneNumberInputRef = useRef(null);

  const { createOrder } = useOrder();

  const [orderSubmitted, setOrderSubmitted] = useState(false);

  const formValid = Boolean(phoneNumber && !phoneNumber.includes('_'));

  const submitForm = () => {
    if (!phoneNumber) {
      const el = phoneNumberInputRef.current;
      if (el) {
        // @ts-ignore
        el.shake();
      }
    } else {
      setOrderSubmitted(true);
      createOrder();
    }
  };

  return orderFormVisible ? (
    <Overlay onClick={hideOrderForm}>
      <OrderFormStyled>
        <Instructions>
          {orderSubmitted ? (
            'Ваша заявка принята!'
          ) : (
            <Highlight>
              Заполните ваши данные в форме ниже и
              <br />
              мы свяжемся с Вами в течение <strong>10 минут</strong>
            </Highlight>
          )}
          {orderSubmitted && (
            <SubInstructions>
              <Highlight>
                Мы свяжемся с Вами
                <br />в течение <strong>10 минут</strong>
              </Highlight>
            </SubInstructions>
          )}
        </Instructions>

        {!orderSubmitted && (
          <FormAndCalculations>
            <Calculations>
              <div>
                <h4>Срок выполнения</h4>
                <p>{timing} дня</p>
              </div>
              <div>
                <h4>Общая стоимость работ</h4>
                <p>{total} руб.</p>
              </div>
            </Calculations>
            <Form>
              <FormInput title="Ваш номер" ref={phoneNumberInputRef}>
                <PhoneInput
                  mask="+7 999 999-9999"
                  placeholder="+7 *** ***-****"
                  value={phoneNumber}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value)}
                />
              </FormInput>
              <FormInput title="Электронная почта">
                <Input value={email} onChange={setEmail} placeholder="vasha_pochta@mail.ru" />
              </FormInput>
            </Form>
          </FormAndCalculations>
        )}
        <Controls>
          {!orderSubmitted && (
            <SubmitButton onClick={submitForm} valid={formValid}>
              Отправить заявку
              <ButtonIcon>
                <Icon
                  iconName="IconArrow45"
                  size={18}
                  rotate={90}
                  color={formValid ? COLORS.yellowtech : rgba(COLORS.blacktech, 0.3)}
                />
              </ButtonIcon>
            </SubmitButton>
          )}
          <CloseButton
            onClick={() => {
              hideOrderForm();
            }}
          >
            {orderSubmitted && <span>Закрыть</span>}
            <Icon iconName="IconClose" size={orderSubmitted ? 16 : 32} color={COLORS.blacktech} />
          </CloseButton>
        </Controls>
      </OrderFormStyled>
    </Overlay>
  ) : null;
};

const OrderFormStyled = styled.div`
  position: fixed;
  top: auto;
  right: auto;
  bottom: 0;
  left: 40%;
  z-index: 10;
  background-color: #fcfffb;
  background-image: url('${BackgroundURL}');
  background-position: right top;
  background-repeat: no-repeat;
  box-shadow: 0px -15px 23px rgba(10, 9, 8, 0.05);
  padding: 55px 60px;

  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    left: 38%;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    left: 0;
    right: 0;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    padding: 100px 20px 20px;
    top: 0;
    overflow: auto;
  }
`;

const Instructions = styled.div`
  font-size: 32px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 50px;

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    font-size: 24px;
    line-height: 28px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-bottom: 80px;
  }

  p:not(:first-of-type) {
    margin-top: 20px;
  }
`;

const SubInstructions = styled.div`
  margin-top: 40px;
  font-size: 24px;
  line-height: 32px;
`;

const FormAndCalculations = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Form = styled.form`
  display: flex;

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    flex-direction: column;
  }
`;

const FormInput = styled(OrderFormInput)`
  min-width: 200px;
  margin-right: 40px;

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-bottom: 25px;
  }
`;

const Calculations = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 28px;

  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  color: ${rgba(COLORS.navytech, 0.8)};

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-top: 76px;
    order: 2;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 0;
  }

  > * {
    min-width: 200px;
    margin-right: 40px;

    @media (max-width: ${BREAKPOINTS.phoneMax}px) {
      min-width: 0;
    }
  }

  h4 {
    font-size: 14px;
    font-weight: 450;
    line-height: 18px;
    text-transform: uppercase;
    color: ${rgba(COLORS.blacktech, 0.45)};

    @media (max-width: ${BREAKPOINTS.phoneMax}px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

const Controls = styled.div`
  margin-top: 60px;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-top: 30px;
  }
`;

const SubmitButton = styled(ButtonGhost)<{ valid: boolean }>`
  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    width: auto;
  }
  color: ${(p) => (p.valid ? COLORS.yellowtech : rgba(COLORS.blacktech, 0.3))};
`;

const CloseButton = styled(ButtonGhost)`
  opacity: 0.65;

  font-size: 14px;

  > span:last-child:not(:only-child) {
    margin-left: 16px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    width: auto;
  }
`;

const PhoneInput = styled(InputMask)`
  color: ${COLORS.blacktech};
  background: transparent;
  border: none;

  &:focus {
    outline: none;
  }
`;
