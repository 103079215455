import React from 'react';
import styled from 'styled-components';
import { Link, navigate } from '@reach/router';

import { COLORS, BREAKPOINTS } from 'ui/constants';
import { Container as ContainerDefault } from 'ui/atoms/Container';
import { Icon as IconDefault } from 'ui/atoms/Icon';
import { ButtonPrimary, ButtonGhost, ButtonIcon } from 'ui/atoms/Button';
import { useScrollDirection, SCROLL_UP } from 'hooks/useScrollDirection';

import ImgLogo from './static/LogoRus.svg';

interface HeaderProps {
  closeButton?: React.ReactNode;
}

export const Header = ({ closeButton }: HeaderProps) => {
  const scrollDirection = useScrollDirection(SCROLL_UP, 60);
  const scrollUp = scrollDirection === SCROLL_UP;

  const goToMenu = () => {
    navigate('/menu');
  };

  const onCalculateClick = () => {
    navigate('/calculator');
  };

  return (
    <Body scrollUp={scrollUp}>
      <Container>
        <Logo to="/">
          <LogoImg src={ImgLogo} />
        </Logo>

        <Contacts>
          <ContactsPhone href="tel:+74951015060">
            <span>+7 (495)</span> 101-50-60
          </ContactsPhone>
          <ContactsWorktime>ПН-ВС 8 — 22</ContactsWorktime>
        </Contacts>

        {closeButton || (
          <ButtonMenu onClick={goToMenu}>
            <IconMenu iconName="IconMenu" size={20} color={COLORS.yellowtech} />
            <ButtonMenuLabel>Меню</ButtonMenuLabel>
          </ButtonMenu>
        )}

        <Nav>
          <NavLink to="/menu/boring">Бурение скважин</NavLink>
          <NavLink to="/menu/water-supply/kesson">Водоснабжение</NavLink>
          <NavLink to="/menu/sewer-system">Канализация</NavLink>
        </Nav>

        <CalcPriceButton onClick={onCalculateClick}>
          <ButtonIcon>
            <IconDefault iconName="IconCalc" size={18} color={COLORS.whitetech} />
          </ButtonIcon>
          Рассчитать стоимость
        </CalcPriceButton>
      </Container>
    </Body>
  );
};

const Body = styled.header<{ scrollUp: boolean }>`
  position: sticky;
  top: 0;
  z-index: 100;
  padding: 30px 0;
  background-color: ${COLORS.whitetech};
  border-bottom: 1px solid ${COLORS.bordertech};
  transform: ${({ scrollUp }) => (scrollUp ? `translateY(0)` : `translateY(-100%)`)};
  transition: transform 0.4s ease-out;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    padding: 28px 0;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    padding: 18px 0;
    position: fixed;
    z-index: 2000;
    left: 0;
    top: 0;
    width: 100%;
  }
`;

const Container = styled(ContainerDefault)`
  display: flex;
  align-items: center;
`;

const Logo = styled(Link)`
  width: 122px;
  flex: 0 0 122px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-right: auto;
  }
`;

const LogoImg = styled.img`
  display: block;
  width: 100%;
`;

const Contacts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 80px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-left: auto;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    display: none;
  }
`;

const ContactsPhone = styled.a`
  color: ${COLORS.blacktech};
  font-size: 14px;
  font-weight: 500;

  span {
    color: ${COLORS.yellowtech};
  }

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    font-size: 10px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 14px;
  }
`;

const ContactsWorktime = styled.div`
  font-size: 12px;
  font-weight: 500;
  opacity: 0.45;

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    font-size: 10px;
  }
`;

const IconMenu = styled(IconDefault)``;

const ButtonMenu = styled(ButtonGhost)`
  margin-left: auto;
  cursor: pointer;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-left: 70px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-left: auto;
    width: auto;
  }
`;

const ButtonMenuLabel = styled.div`
  margin-left: 20px;
  font-size: 14px;

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    font-size: 12px;
    margin-left: 10px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    font-size: 14px;
  }
`;

const Nav = styled.nav`
  margin-left: 40px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    display: none;
  }
`;

const NavLink = styled(Link)`
  font-size: 16px;
  margin-right: 30px;
  color: ${COLORS.blacktech};

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    font-size: 12px;
  }

  &:hover {
    text-decoration: underline;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const CalcPriceButton = styled(ButtonPrimary)`
  margin-left: auto;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    display: none;
  }

  ${ButtonIcon} {
    margin-right: 10px;
  }
`;
