import React from 'react';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components';

import { PricesWrapper } from './PricesWrapper';
import * as PriceTableStyles from './PricesTable';
import { useDocuments, API_ROOT, useSewerSystemPrices, SewerSystemPriceI } from 'hooks/useApiCall';
import { BREAKPOINTS } from 'ui/constants';

export const PriceListSewerSystem: React.FC<RouteComponentProps> = () => {
  let { data: documents } = useDocuments();
  const boringDoc = documents && documents.find((i) => i.name === 'Прайс лист по обустройству и канализации');
  const boringDocURL = boringDoc && `${API_ROOT}${boringDoc?.file[0]?.url}`;
  let { data: sewerSystemPricesData } = useSewerSystemPrices();

  const drawItemsJSX = (tableType: SewerSystemPriceI['tableType']) => {
    if (!sewerSystemPricesData) return null;

    return sewerSystemPricesData
      .filter((i) => i.tableType === tableType)
      .map((i) => (
        <SewerSystemTableRow key={i.id}>
          <PriceTableStyles.Item>{i.label}</PriceTableStyles.Item>
          <PriceTableStyles.Item>{i.value}</PriceTableStyles.Item>
        </SewerSystemTableRow>
      ));
  };

  return (
    <PricesWrapper downloadDocLink={boringDocURL} title="Обустройство и канализация">
      <SewerSystemTable>
        <SewerSystemTableTitle>Автономная канализация (стандартный монтаж)</SewerSystemTableTitle>

        <SewerSystemTableMain>
          <SewerSystemTableHead>
            <PriceTableStyles.HeadItem>Модели станций</PriceTableStyles.HeadItem>
            <PriceTableStyles.HeadItem>Стоимость</PriceTableStyles.HeadItem>
          </SewerSystemTableHead>

          <PriceTableStyles.Body>{drawItemsJSX('autonomous_sewerage_station_models')}</PriceTableStyles.Body>
        </SewerSystemTableMain>

        <SewerSystemTableMain>
          <SewerSystemTableHead>
            <PriceTableStyles.HeadItem>Дополнительно</PriceTableStyles.HeadItem>
            <PriceTableStyles.HeadItem>Стоимость</PriceTableStyles.HeadItem>
          </SewerSystemTableHead>

          <PriceTableStyles.Body>{drawItemsJSX('autonomous_sewerage_additionally_1')}</PriceTableStyles.Body>
        </SewerSystemTableMain>

        <SewerSystemTableMain>
          <SewerSystemTableHead>
            <PriceTableStyles.HeadItem>Дополнительно</PriceTableStyles.HeadItem>
            <PriceTableStyles.HeadItem>
              Стоимость <span>(1м.п.)</span>
            </PriceTableStyles.HeadItem>
          </SewerSystemTableHead>

          <PriceTableStyles.Body>{drawItemsJSX('autonomous_sewerage_additionally_2')}</PriceTableStyles.Body>
        </SewerSystemTableMain>
      </SewerSystemTable>

      <SewerSystemTable>
        <SewerSystemTableTitle>Обустройство скважин (до 50м глубиной)</SewerSystemTableTitle>

        <SewerSystemTableMain>
          <SewerSystemTableHead>
            <PriceTableStyles.HeadItem>Вид обустройства</PriceTableStyles.HeadItem>
            <PriceTableStyles.HeadItem>Стоимость</PriceTableStyles.HeadItem>
          </SewerSystemTableHead>

          <PriceTableStyles.Body>{drawItemsJSX('construction_of_wells_type_of_arrangement')}</PriceTableStyles.Body>
        </SewerSystemTableMain>

        <SewerSystemTableMain>
          <SewerSystemTableHead>
            <PriceTableStyles.HeadItem>Дополнительно</PriceTableStyles.HeadItem>
            <PriceTableStyles.HeadItem>Стоимость</PriceTableStyles.HeadItem>
          </SewerSystemTableHead>

          <PriceTableStyles.Body>{drawItemsJSX('construction_of_wells_additionally_1')}</PriceTableStyles.Body>
        </SewerSystemTableMain>

        <SewerSystemTableMain>
          <SewerSystemTableHead>
            <PriceTableStyles.HeadItem>Работы</PriceTableStyles.HeadItem>
            <PriceTableStyles.HeadItem>
              Стоимость <span>(1м.п.)</span>
            </PriceTableStyles.HeadItem>
          </SewerSystemTableHead>

          <PriceTableStyles.Body>{drawItemsJSX('construction_of_wells_work')}</PriceTableStyles.Body>
        </SewerSystemTableMain>
      </SewerSystemTable>

      <SewerSystemTable>
        <SewerSystemTableTitle>дренажный колодец </SewerSystemTableTitle>

        <SewerSystemTableMain>
          <SewerSystemTableHead>
            <PriceTableStyles.HeadItem>Вид обустройства</PriceTableStyles.HeadItem>
            <PriceTableStyles.HeadItem>Стоимость</PriceTableStyles.HeadItem>
          </SewerSystemTableHead>

          <PriceTableStyles.Body>{drawItemsJSX('draining_well_type_of_arrangement')}</PriceTableStyles.Body>
        </SewerSystemTableMain>
      </SewerSystemTable>
    </PricesWrapper>
  );
};

const SewerSystemTable = styled.div`
  &:not(:last-child) {
    margin-bottom: 80px;
  }
`;

const SewerSystemTableTitle = styled.h3`
  font-size: 18px;
  text-transform: uppercase;
  color: #78796d;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 40px;
  }
`;

const SewerSystemTableMain = styled.div`
  &:not(:last-child) {
    margin-bottom: 40px;
  }
`;

const SewerSystemTableHead = styled(PriceTableStyles.Head)`
  grid-template-columns: minmax(250px, 2fr) minmax(140px, 1fr);

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    grid-template-columns: minmax(180px, 2fr) minmax(140px, 1fr);
  }
`;

const SewerSystemTableRow = styled(PriceTableStyles.Row)`
  grid-template-columns: minmax(250px, 2fr) minmax(140px, 1fr);

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    grid-template-columns: minmax(180px, 2fr) minmax(140px, 1fr);
  }
`;
