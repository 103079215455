import { UAParser } from 'ua-parser-js';

const BLACKLISTED_BROWSERS: { [key: string]: number } = {
  'Mobile Safari': 11,
}

const BLACKLISTED_OS: { [key: string]: number } = {
  Android: 4.4,
}

export const isSupportedBrowser = () => {
  const parser = new UAParser()
  const { name, version } = parser.getBrowser()
  const { name: osName, version: osVersion } = parser.getOS()

  if (name && version) {
    const blacklistedVersion = BLACKLISTED_BROWSERS[name]
    if (blacklistedVersion && Number(version) < blacklistedVersion) {
      return false;
    }
  }
  if (osName && osVersion) {
    const blacklistedVersion = BLACKLISTED_OS[osName]
    if (blacklistedVersion && Number(osVersion) < blacklistedVersion) {
      return false;
    }
  }

  return true;
};
