import { useStore } from "effector-react";

import { notify } from "utils/notify";
import { useCalculations } from "hooks/useCalculations";
import { keyOptionsByValue } from "utils/keyOptionsByValue";

import {
  PIPE_MATERIAL_OPTIONS,
  PUMP_PERFORMANCE_OPTIONS,
  $boreholeLocation,
  $boreholeDepth,
  $pumpPerformance,
  $pipeMaterial,
} from "stores/boring";
import {
  BOREHOLE_ACTIVITY_OPTIONS,
  $boreholeActivity,
  $machineRoom,
} from "stores/waterSupply";
import {
  ADDITIONAL_DRAINAGE_WELL_SIZE_OPTIONS,
  FAMILY_MEMBERS_COUNT_OPTIONS,
  $familyMembersCount,
  $distanceToConnectionPoint,
  $additionalDrainageWellSize,
  $distanceToRainwaterSewerSystem,
} from "stores/sewerSystem";
import { $email, $phoneNumber } from "stores/userInfo";

export const useOrder = () => {
  const boreholeLocation = useStore($boreholeLocation);
  const boreholeDepth = useStore($boreholeDepth);
  const pumpPerformance = useStore($pumpPerformance);
  const pipeMaterial = useStore($pipeMaterial);

  const boreholeActivity = useStore($boreholeActivity);
  const machineRoom = useStore($machineRoom);

  const familyMembersCount = useStore($familyMembersCount);
  const distanceToConnectionPoint = useStore($distanceToConnectionPoint);
  const additionalDrainageWellSize = useStore($additionalDrainageWellSize);
  const distanceToRainwaterSewerSystem = useStore($distanceToRainwaterSewerSystem);

  const phoneNumber = useStore($phoneNumber);
  const email = useStore($email);

  const {
    calculations: {
      boringCost,
      waterSupplyCost,
      sewerSystemCost,
      total,
    },
  } = useCalculations();

  const createOrder = () => {
    const message = `
<b>Номер телефона:</b> ${phoneNumber}
<b>E-mail:</b> ${email}
<b>Итого:</b> ${total} руб.


<b>Местоположение скважины:</b> ${boreholeLocation}
<b>Глубина:</b> ${boreholeDepth}
<b>Производительность:</b> ${keyOptionsByValue(PUMP_PERFORMANCE_OPTIONS)[pumpPerformance]}
<b>Конструкция:</b> ${keyOptionsByValue(PIPE_MATERIAL_OPTIONS)[pipeMaterial]}
<b>Стоимость бурения:</b> ${boringCost} руб.

<b>Эксплуатация скважины:</b> ${keyOptionsByValue(BOREHOLE_ACTIVITY_OPTIONS)[boreholeActivity] || ''}
<b>Наличие тех. помещения:</b> ${machineRoom ? 'Да' : 'Нет'}
<b>Стоимость водоснабжения:</b> ${waterSupplyCost} руб.

<b>В доме одновременно будет проживать:</b> ${keyOptionsByValue(FAMILY_MEMBERS_COUNT_OPTIONS)[familyMembersCount]}
<b>Планируемое расстояние от дома до канализации:</b> ${distanceToConnectionPoint}
<b>Дополнительный дренажный колодец:</b> ${keyOptionsByValue(ADDITIONAL_DRAINAGE_WELL_SIZE_OPTIONS)[additionalDrainageWellSize]}
<b>Планируемое расстояние от канализации до слива:</b> ${distanceToRainwaterSewerSystem}
<b>Стоимость канализации:</b> ${sewerSystemCost} руб.
    `;
    notify({ message });
  };
  return { createOrder }
}
