import React from 'react';
import styled from 'styled-components';
import { useStore } from 'effector-react';

import trimEnd from 'lodash/trimEnd';

import { useCalculations } from 'hooks';
import {
  PipeMaterialType,
  $boreholeLocation,
  $boreholeDepth,
  $pumpPerformance,
  $pipeMaterial,
  $additionalHintVisible,
  toggleAdditionalHint,
} from 'stores/boring';
import { $boreholeActivity, $machineRoom } from 'stores/waterSupply';
import { useMediaBreakpoint } from 'hooks/useMediaBreakpoint';
import { formatText } from 'utils/formatText';

import { RoundButton, Label as RoundButtonLabel, Circle as RoundButtonCircle } from 'ui/atoms/RoundButton';
import { Highlight } from 'ui/atoms/Highlight';
import { Icon } from 'ui/atoms/Icon';
import { CalculatorBreadcrumbsStep } from 'ui/molecules/CalculatorBreadcrumbs';
import { BREAKPOINTS, COLORS } from 'ui/constants';
import { Link } from '@reach/router';

import IconListItem from './static/list-item.svg';

const MetalPipeHint: React.FC = () => (
  <>
    <ul>
      <li>Cрок службы скважины</li>
      <li>Возможность эксплуатации</li>
      <li>Отсутствие вторичного загрязнения</li>
      <li>Стойкость к дезинфекции</li>
      <li>Уступает нПВХ</li>
    </ul>
  </>
);

const MetalPlasticPipeHint: React.FC = () => (
  <>
    <ul>
      <li>Cрок службы скважины (ориентировочно 50 лет);</li>
      <li>
        Возможность эксплуатации бытовых насосов отечественного и импортного производства диаметром
        <br />3 или 4 дюйма;
      </li>
      <li>Отсутствие коррозии и вторичного загрязнения железом, вода находится в пластиковой пищевой трубе;</li>
      <li>Стойкость к хим. растворам, применяемым при восстановлении и дезинфекции скважины;</li>
      <li>Не хрупкая (в сравнении с нПВХ трубой), при этом по прочности не уступает нПВХ....</li>
    </ul>
  </>
);

const PVCUPipeHint: React.FC = () => (
  <>
    <ul>
      <li>Материал не реагирует с водой и растворенными в ней веществами.</li>
      <li>Безопасный пластик не повлияет на цвет, вкус и запах воды.</li>
      <li>На гладких стенках не образуются бактериальные колонии.</li>
      <li>Конструкция устойчива к сезонным колебаниям температуры.</li>
      <li>Обсадная колонна хорошо выдерживает гидроудары.</li>
      <li>Материал не проводит блуждающие токи.</li>
      <li>Герметичные стенки не пропускают кислород.</li>
      <li>Внутри обсадной колонны не образуется водный камень.</li>
      <li>Качество изделий подтверждено сертификацией.</li>
    </ul>
  </>
);

const PipeMaterialHint: React.FC = () => {
  const pipeMaterial = useStore($pipeMaterial);
  const pipeMaterialHints = {
    [PipeMaterialType.metal]: <MetalPipeHint />,
    [PipeMaterialType.metalplastic]: <MetalPlasticPipeHint />,
    [PipeMaterialType.pvcu]: <PVCUPipeHint />,
  };
  return pipeMaterialHints[pipeMaterial];
};

const BoringHint: React.FC = () => {
  const boreholeLocation = useStore($boreholeLocation);
  const boreholeDepth = useStore($boreholeDepth);
  const pumpPerformance = useStore($pumpPerformance);
  const pipeMaterial = useStore($pipeMaterial);
  const additionalHintVisible = useStore($additionalHintVisible);

  const { isLaptopSmall } = useMediaBreakpoint();
  const {
    prices: { boringPrices },
  } = useCalculations();

  const defaultHint =
    'В зависимости от района глубина залегания водоносного известняка и геологическое строение меняется. В результате **сложность бурения и конструкция скважины могут** значительно **отличаться**.';

  const depthHint = `На основе геологических изысканий по указанному местоположению (${trimEnd(
    boreholeLocation,
  )}) глубина залегания артезианской воды находится в диапазоне **${Math.max(0, boreholeDepth - 5)}-${
    boreholeDepth + 5
  } метров**. Цена за метр бурения **${boringPrices[`${pipeMaterial}${pumpPerformance}`]} рублей.**`;

  const someText = `В свою очередь, для обеспечения необходимой **производительности в ${pumpPerformance}м³** будет использоваться конструкция из`;

  const pipeExplanation: { [key: string]: string } = {
    metal3: `${depthHint} ${someText} **металлических обсадных труб Ø133мм**.`,
    metal5: `${depthHint} ${someText} **металлических обсадных труб Ø159мм**.`,
    metalplastic3: `${depthHint} ${someText} **металлической Ø133мм** и **пластиковой Ø117мм** обсадных труб.`,
    metalplastic5: `${depthHint} ${someText} **металлической Ø159мм** и **пластиковой Ø125мм** обсадных труб.`,
    pvcu3: `${depthHint} ${someText} обсадных труб **нПВХ Ø125мм**.`,
    pvcu5: 'Опция с 5 кубометрами не доступна для конструкции из нПВХ труб.',
  };

  const hint = boreholeDepth ? pipeExplanation[`${pipeMaterial}${pumpPerformance}`] : defaultHint;

  return (
    <>
      <HintText>{additionalHintVisible ? <PipeMaterialHint /> : <Highlight>{formatText(hint)}</Highlight>}</HintText>
      <AdditionalHintButton
        onClick={toggleAdditionalHint}
        icon={<Icon iconName="IconArrow" size={20} rotate={additionalHintVisible ? 90 : 270} />}
        ghost
        small={isLaptopSmall}
      >
        {additionalHintVisible ? 'Назад' : 'Еще'}
      </AdditionalHintButton>
    </>
  );
};

const WaterSupplyHint: React.FC = () => {
  const boreholeActivity = useStore($boreholeActivity);
  const machineRoom = useStore($machineRoom);

  const boreholeActivityHintMapping: { [key: string]: string } = {
    none:
      'Так называют комплекс земельных и строительных работ, объединенных с монтажом необходимой водоподъемной техники, электроники, автоматики, сантехнического оборудования.',
    summer:
      'В тех случаях, когда скважиной пользуются только в летнее время и не требуется подводка воды в дом, наиболее эффективным и недорогим вариантом является скважинный оголовок.',
    fulltime:
      'Для круглогодичной эксплуатации, водоподъемная техника, электроника и автоматика должны быть защищены от наружных повреждений.',
  };
  const boreholeActivityHint = boreholeActivityHintMapping[boreholeActivity];

  const noMachineRoomHint =
    'Для экономии места в доме удобно использовать кессон - герметичное помещение, расположенное в земле и не требующее утепления в холодное время года.';
  const withMachineRoomHint =
    'При возможности размещения техники в доме используется адаптер - устройство для вывода водопроводных труб через обсадную трубу ниже уровня промерзания грунта.';

  const machineRoomHint = boreholeActivity === 'fulltime' && (machineRoom ? withMachineRoomHint : noMachineRoomHint);

  return (
    <HintText>
      {boreholeActivityHint} {machineRoomHint}
      <br />
      <br />
      Ознакомиться с возможными вариантами обустройства скважины{' '}
      <Link to="/menu/water-supply/kesson">вы можете тут</Link>.
    </HintText>
  );
};

const SewerSystemHint: React.FC = () => (
  <HintText>
    Современная автономная канализация – это сложная инженерная система по отведению сточных вод и их многоуровневому
    очищению посредством механических и биологических способов. Основными факторами для расчета стоимости установки
    автономной канализации является объем накопительной емкости и производительность станции. Ознакомиться с возможными
    вариантами автономной канализации <Link to="/menu/sewer-system">вы можете тут</Link>.
  </HintText>
);

interface CalculatorHintProps {
  currentStep: CalculatorBreadcrumbsStep;
}

export const CalculatorHint: React.FC<CalculatorHintProps> = ({ currentStep }) => {
  const HintText = {
    [CalculatorBreadcrumbsStep.boring]: BoringHint,
    [CalculatorBreadcrumbsStep.waterSupply]: WaterSupplyHint,
    [CalculatorBreadcrumbsStep.sewerSystem]: SewerSystemHint,
  }[currentStep];
  return <HintText />;
};

const HintText = styled.main`
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
  overflow: auto;
  max-height: 100%;
  padding-bottom: 40px;
  margin-bottom: 50px;
  margin-right: -40px;
  padding-right: 20px;
  scrollbar-color: ${COLORS.yellowtech} ${COLORS.lightgraytech};
  scrollbar-width: thin;

  :hover {
    ::-webkit-scrollbar {
      //opacity: 1;
      display: block;
    }
  }

  //-ms-overflow-style: none; /* Internet Explorer 10+ */
  //scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    //opacity: 0;
    //display: none;
    //transition: opacity 300ms ease-in-out;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${COLORS.lightgraytech};
    -webkit-box-shadow: none;
    border-radius: 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLORS.yellowtech};
    -webkit-box-shadow: none;
    border-radius: 0;
  }

  li {
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 20px;

    &::before {
      content: '';
      display: inline-block;
      width: 15px;
      height: 15px;
      margin-right: 10px;
      background-color: transparent;
      background-image: url(${IconListItem});
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
        height: 12px;
      }
    }

    @media (max-width: ${BREAKPOINTS.desktopMax}px) {
      font-size: 18px;
      line-height: 26px;
    }

    @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 15px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    text-decoration: underline;
    color: ${COLORS.navytech};
    font-weight: 500;
    transition: opacity 0.2s ease-out;

    &:hover {
      opacity: 0.8;
    }
  }

  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    font-size: 20px;
    line-height: 32px;
  }

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 42px;
    max-width: 430px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    max-width: none;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 32px;
  }
`;

const AdditionalHintButton = styled(RoundButton)`
  display: flex;
  align-items: center;
  color: ${COLORS.blacktech};
  position: absolute;
  z-index: 1;
  right: 30px;
  bottom: 70px;

  ${RoundButtonLabel} {
    width: 45px;

    @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
      margin-left: 30px;
    }
  }

  ${RoundButtonCircle} {
    @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
      height: 64px;
      width: 64px;
    }
  }

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    right: 70px;
  }

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    bottom: 88px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    right: 30px;
    margin-right: 60px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-right: 0;
  }
`;
