import React, { useState, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';

import { useStore } from 'effector-react';

import { lighten } from 'polished';
import { Link, navigate, useLocation } from '@reach/router';

import { showCallbackForm, $callbackTimerEndsAt, toggleForm } from 'stores/callbackForm';

import { RoundButton } from 'ui/atoms/RoundButton';
import { BREAKPOINTS, COLORS } from 'ui/constants';
import { Icon } from 'ui/atoms/Icon';
import { useMediaBreakpoint } from 'hooks/useMediaBreakpoint';
import { ButtonGhost, ButtonIcon, ButtonPrimary } from 'ui/atoms/Button';
import { useWindowSize } from 'hooks/useWindowSize';

import IconClose from './static/icon-close.svg';
import IconArrowBack from './static/icon-arrow-back.svg';
import IconBackMenu from './static/icon-back-menu.svg';
import IconCloseMenu from './static/icon-close-menu.svg';

interface MenuContentProps {
  pic: React.ReactNode;
  subNavigation?: React.ReactNode;
}

export const MenuContent: React.FC<MenuContentProps> = ({ children, pic, subNavigation }) => {
  const callbackTimerEndsAt = useStore($callbackTimerEndsAt);
  const windowSize = useWindowSize();

  const contentRef = useRef<HTMLDivElement | null>(null);
  const [descriptionVisible, setDescriptionVisible] = useState(false);
  const { isPhone, isTabletStrict } = useMediaBreakpoint();
  const { pathname } = useLocation();

  const toggleDescription = () => {
    setDescriptionVisible((v) => !v);
  };

  const onCalculateClick = () => {
    navigate('/calculator');
  };

  const onBackClick = () => {
    navigate('/menu');
  };

  const onCloseClick = () => {
    navigate('/');
  };

  // fix firefor height bug
  useLayoutEffect(() => {
    if (contentRef.current) {
      const el = contentRef.current;
      const elSize = el.getBoundingClientRect();

      el.style.height = isPhone ? `${elSize.height}px` : '';
    }
  });

  return (
    <MenuContentStyled height={windowSize.height}>
      <Header>
        <HeaderBackButton onClick={onBackClick}>Меню</HeaderBackButton>

        <HeaderCloseButton onClick={onCloseClick}>Закрыть</HeaderCloseButton>
      </Header>

      <MenuTabletControls>
        <MenuTabletCloseButton onClick={onBackClick} />

        <MenuTabletBackButton onClick={onCloseClick}>На главную</MenuTabletBackButton>
      </MenuTabletControls>

      <DescriptionToggle
        onClick={toggleDescription}
        icon={<Icon iconName="IconArrow" size={20} rotate={descriptionVisible ? 90 : 270} />}
        ghost
        small
      />
      <Content ref={contentRef}>
        <PicContainer visible={!descriptionVisible}>{pic}</PicContainer>

        <DescriptionContainer visible={descriptionVisible}>
          <FooContainer>
            {isTabletStrict && (
              <NavPrimary>
                <NavLink to="/menu/boring" active={pathname.startsWith('/menu/boring')}>
                  Бурение скважин
                </NavLink>
                <NavLink to="/menu/water-supply/kesson" active={pathname.startsWith('/menu/water-supply')}>
                  Водоснабжение
                </NavLink>
                <NavLink to="/menu/sewer-system" active={pathname.startsWith('/menu/sewer-system')}>
                  Автономная канализация
                </NavLink>
              </NavPrimary>
            )}

            <SomeContainer>
              {!isPhone && subNavigation && <SubNav>{subNavigation}</SubNav>}

              <DescriptionMain>{children}</DescriptionMain>
            </SomeContainer>
          </FooContainer>

          {!isPhone && (
            <ActionButtons>
              <ActionButtonsContactUs
                onClick={() => {
                  showCallbackForm();
                }}
              >
                Связаться с нами
                <ButtonIcon>
                  <Icon iconName="IconArrow45" size={18} rotate={90} />
                </ButtonIcon>
              </ActionButtonsContactUs>

              <ButtonPrimary onClick={onCalculateClick}>Рассчитать стоимость</ButtonPrimary>
            </ActionButtons>
          )}
        </DescriptionContainer>
      </Content>

      {isPhone && (
        <MobileButtonControls>
          <MobileSubNav>{subNavigation}</MobileSubNav>
          <MobileActionButtons>
            <MobileCalculateButton onClick={onCalculateClick}>
              <ButtonIcon>
                <Icon iconName="IconCalc" size={18} color={COLORS.whitetech} />
              </ButtonIcon>
              Рассчитать стоимость
            </MobileCalculateButton>
            <MobileCallButton
              onClick={
                callbackTimerEndsAt
                  ? () => {
                      toggleForm();
                    }
                  : () => {
                      showCallbackForm();
                    }
              }
            >
              <ButtonIcon>
                <Icon iconName={callbackTimerEndsAt ? 'IconClock' : 'IconPhone'} size={24} color={COLORS.yellowtech} />
              </ButtonIcon>
            </MobileCallButton>
          </MobileActionButtons>
        </MobileButtonControls>
      )}
    </MenuContentStyled>
  );
};

const MenuContentStyled = styled.div<{ height: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 50px;

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    padding-bottom: 20px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    height: ${({ height }) => `${height}px`};
    max-height: -moz-available; /* WebKit-based browsers will ignore this. */
    max-height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    max-height: fill-available;
    padding-bottom: 0;
  }
`;

const DescriptionToggle = styled(RoundButton)`
  position: absolute;
  z-index: 10;
  top: 80px;
  right: 20px;
  display: none;
  color: ${COLORS.blacktech};

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    display: block;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    height: auto;
    flex: 1 1 auto;
    min-height: 0;
    overflow: auto;
  }
`;

const PicContainer = styled.div<{ visible: boolean }>`
  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    height: 100%;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    display: ${(p) => (p.visible ? 'block' : 'none')};
  }
`;

const DescriptionContainer = styled.div<{ visible: boolean }>`
  margin-top: auto;
  display: flex;
  align-items: flex-end;
  padding: 20px 100px 20px 20px;

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    padding: 20px 40px 20px 20px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    flex-direction: column;
    align-items: stretch;
    padding: 20px 60px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    display: ${(p) => (p.visible ? 'block' : 'none')};
    padding: 20px;
    margin-top: 60px;
  }
`;

const FooContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex: 0 1 auto;
  min-width: 0;
  margin-right: 10px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-right: 0;
  }
`;

const SomeContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 1 auto;
  min-width: 0;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    flex: 0 0 65%;
    width: 65%;
    max-width: 65%;
    flex-direction: column;
    align-items: stretch;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
`;

const DescriptionMain = styled.div`
  flex: 0 1 auto;
  min-width: 0;
`;

const SubNav = styled.div`
  flex: 0 0 auto;
  max-width: 160px;
  margin-right: 70px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    max-width: none;
    margin-right: 0;
  }
`;

const ActionButtons = styled.div`
  flex: 0 0 230px;
  width: 230px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    flex: 0 0 auto;
    align-items: center;
    margin-top: 40px;
  }
`;

const ActionButtonsContactUs = styled(ButtonGhost)`
  margin-bottom: 30px;
  opacity: 0.65;

  ${ButtonIcon} {
    margin-left: 10px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-bottom: 0;
  }
`;

const MobileSubNav = styled.div`
  display: flex;
  flex-direction: row;
`;

const MobileActionButtons = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
`;

const MobileCalculateButton = styled(ButtonPrimary)`
  flex: 1;

  ${ButtonIcon} {
    margin-right: 10px;
  }
`;

const MobileButtonControls = styled.div``;

const MobileCallButton = styled(ButtonGhost)`
  flex: 0 0 50px;
  width: 50px;
  max-width: 50px;
  border-top: 1px solid #eef0ec;
  height: 100%;
  background-color: #fff;
`;

const NavPrimary = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    flex: 0 0 35%;
    width: 35%;
    max-width: 35%;
    height: calc(100% + 20px);
    margin-bottom: -20px;
  }
`;

const NavLink = styled(Link)<{ active?: boolean }>`
  color: ${({ active }) => (active ? `${COLORS.yellowtech} !important` : COLORS.blacktech)};
  transition: color 0.2s ease-out, transform 0.2s ease-out;
  order: ${(p) => (p.active ? -1 : 0)};
  font-size: ${(p) => (p.active ? '24px' : '18px')};
  line-height: 23px;
  margin-bottom: ${(p) => (p.active ? 'auto' : '20px')};

  &:hover {
    color: ${lighten(0.15, COLORS.blacktech)};
  }
`;

const Header = styled.header`
  flex: 0 0 auto;
  padding: 20px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #eef0ec;

  @media (min-width: ${BREAKPOINTS.tabletMin}px) {
    display: none;
  }
`;

const HeaderCloseButton = styled.button`
  margin-left: auto;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  padding-right: 20px;
  background: transparent url(${IconClose}) center right no-repeat;
  background-size: auto 88%;
  opacity: 0.65;
`;

const HeaderBackButton = styled.button`
  padding-left: 30px;
  background: transparent url(${IconArrowBack}) center left no-repeat;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
`;

const MenuTabletControls = styled.div`
  display: none;

  @media (min-width: ${BREAKPOINTS.tabletMin}px) and (max-width: ${BREAKPOINTS.tabletMax}px) {
    display: flex;
    position: absolute;
    z-index: 100;
    left: 0;
    top: 40px;
    width: 100%;
    padding: 0 60px;
  } ;
`;

const MenuTabletCloseButton = styled.div`
  width: 22px;
  height: 22px;
  background: transparent url(${IconCloseMenu}) center right no-repeat;
`;

const MenuTabletBackButton = styled.div`
  margin-left: auto;
  font-weight: 500;
  text-transform: uppercase;
  opacity: 0.45;
  padding-right: 30px;
  background: transparent url(${IconBackMenu}) center right no-repeat;
`;
