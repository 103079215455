import React from 'react'
import { RouteComponentProps, useLocation } from "@reach/router";

// @ts-ignore
export const ScrollToTop: React.FC<RouteComponentProps> = ({
  children,
}) => {
  const { pathname, hash } = useLocation();
  React.useEffect(() => {
    if (!hash) {
      setTimeout(() => {
        global.window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
      }, 0)
    };
  }, [pathname, hash])
  if (children) {
    return children
  }
  return null;
};
