import * as React from 'react';

import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'ui/constants';
import { rgba } from 'polished';
import { Icon } from 'ui/atoms/Icon';

interface CheckboxProps {
  label: string;
  name?: string;
  checked: boolean;
  onChange: (newValue: boolean) => void;
  className?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({ label, name, checked, onChange, className }) => {
  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };
  return (
    <Label checked={checked} className={className}>
      <input name={name} type="checkbox" checked={checked} onChange={onCheckboxChange} />
      <CustomCheckbox checked={checked}>
        <Icon iconName="IconDone" />
      </CustomCheckbox>
      {label}
    </Label>
  );
};

export const CustomCheckbox = styled.span<{ checked: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 4px 18px 0 0;
  width: 20px;
  height: 20px;
  border: none;
  color: ${COLORS.blacktech};
  background-color: ${(p) => (p.checked ? rgba(COLORS.yellowtech, 0.15) : rgba(COLORS.blacktech, 0.1))};

  > * {
    opacity: ${(p) => (p.checked ? 1 : 0)};
    transition: all 0.3s ease-out;
    transform: scale(${(p) => (p.checked ? 1 : 0)});
  }

  transition: all 0.3s ease-out;
  transform: scale(1);

  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    width: 14px;
    height: 14px;
  }

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    width: 12px;
    height: 12px;
  }
`;

export const Label = styled.label<{ checked: boolean }>`
  font-size: 28px;
  font-weight: ${(p) => (p.checked ? 450 : 400)};
  line-height: 28px;

  display: flex;
  align-items: flex-start;

  cursor: pointer;

  :not(:first-of-type) {
    margin-top: 20px;

    @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
      margin-top: 16px;
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    font-size: 18px;
    line-height: 18px;
  }

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    font-size: 16px;
    line-height: 16px;
  }
`;
