import React from 'react';

import styled from 'styled-components';
import { rgba } from 'polished';
import { useMediaBreakpoint } from 'hooks/useMediaBreakpoint';
import { BREAKPOINTS, COLORS } from 'ui/constants';

import BackgroundURL from 'ui/atoms/CalculatorBackground.svg';

interface ExplanationProps {
  header: React.ReactNode;
  breadcrumbs: React.ReactNode;
  hint: React.ReactNode;
  nextButton: React.ReactNode;
  resetButton: React.ReactNode;
  closeButton: React.ReactNode;
  orderForm: React.ReactNode;
  title?: string;
}

export const Explanation: React.FC<ExplanationProps> = ({
  children,
  header,
  breadcrumbs,
  hint,
  nextButton,
  resetButton,
  closeButton,
  orderForm,
  title = 'Расчёт стоимости',
}) => {
  const { isPhone } = useMediaBreakpoint();
  return (
    <ExplanationStyled>
      {isPhone && (
        <>
          <HeaderStyled>{header}</HeaderStyled>
          <MobileTitleStyled>{title}</MobileTitleStyled>
        </>
      )}
      {!isPhone && (
        <HintStyled>
          <Hint>{hint}</Hint>
        </HintStyled>
      )}
      <MainStyled>
        {!isPhone && (
          <BreadcrumbsAndContentContainer>
            <BreadcrumbsStyled>{breadcrumbs}</BreadcrumbsStyled>
            <ContentStyled>{children}</ContentStyled>
          </BreadcrumbsAndContentContainer>
        )}
        {isPhone && (
          <>
            <ContentStyled>{children}</ContentStyled>
            <MobileHintContainer>{hint}</MobileHintContainer>
            <MobileBreadcrumbsStyled>{breadcrumbs}</MobileBreadcrumbsStyled>
          </>
        )}
        <ControlsAndTotals>
          <StepControlsWrapper>
            <NextButtonWrapper>{nextButton}</NextButtonWrapper>
            <ResetButtonWrapper>{resetButton}</ResetButtonWrapper>
          </StepControlsWrapper>
          {isPhone && (
            <StickyNav>
              <NextButtonWrapper>{nextButton}</NextButtonWrapper>
              <ResetButtonWrapper>{resetButton}</ResetButtonWrapper>
            </StickyNav>
          )}
        </ControlsAndTotals>
      </MainStyled>
      <OrderFormContainer>{orderForm}</OrderFormContainer>
    </ExplanationStyled>
  );
};

const ExplanationStyled = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background-image: url(${BackgroundURL});

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    flex-direction: column;
  }
`;

const MainStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 60%;

  padding: 180px 60px 100px 80px;
  overflow-y: auto;

  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    flex-basis: 62%;
    padding: 127px 80px 60px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    padding: 60px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    padding: 20px 20px 0;
  }
`;

const BreadcrumbsStyled = styled.nav`
  margin-bottom: 80px;
  order: 2;
  flex: 0 0 160px;
  width: 160px;
  max-width: 160px;

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    margin-bottom: 60px;
    order: 0;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
`;

const ContentStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 120px;

  > * {
    flex-basis: 49%;

    @media (max-width: ${BREAKPOINTS.phoneMax}px) {
      flex-basis: 100%;
    }
  }

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    margin-right: 40px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    max-width: 480px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    max-width: 100%;
    margin-right: 0;
  }
`;

const HintStyled = styled.div`
  background-color: ${rgba(COLORS.blacktech, 0.02)};
  flex-basis: 40%;
  padding: 0 82px 0 120px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    flex-basis: 38%;
    padding: 40px 60px 0 100px;
  }

  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    padding: 40px 60px 0;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    padding: 0 20px;
  }
`;

const BreadcrumbsAndContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    flex-direction: column;
  }
`;

const OrderFormContainer = styled.div``;

const HeaderStyled = styled.div`
  @media (min-width: ${BREAKPOINTS.tabletMin}px) {
    display: none;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-bottom: 60px;
  }
`;

const MobileBreadcrumbsStyled = styled.nav`
  order: -1;
  margin-bottom: 40px;
  padding: 20px 0 20px 0;
`;

const ControlsAndTotals = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  margin-top: auto;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    padding-top: 0px;
    align-items: center;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
  }
`;

const MobileTitleStyled = styled.div`
  padding: 30px 20px;

  font-size: 28px;
  font-weight: 400;
  line-height: 28px;

  background-color: ${rgba(COLORS.blacktech, 0.02)};
`;

const Hint = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: stretch;
  margin-top: 40px;
  margin-bottom: 24px;

  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    margin-top: 6px;
    margin-bottom: 15px;
  }
`;

const MobileHintContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StepControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    visibility: hidden;
  }
`;

const StickyNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;
  
  position: fixed;
  z-index: 100;
  min-height: 48px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${COLORS.whitetech};
  border-top: 1px solid ${rgba(COLORS.blacktech, 0.07)};
`;

const NextButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    //padding: 0 20px;
    height: 42px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    height: 48px;
    padding: 0;
    flex-basis: 60%;
  }
`;

const ResetButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    padding: 0 20px;
    height: 42px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    height: 48px;
    padding: 0;
    order: -1;
    flex-basis: 40%;
  }
`;
