import { useStore } from "effector-react";
import { $prices, $calculations, $explanationTexts } from "stores/calculator";

export const useCalculations = () => {
  const prices = useStore($prices);
  const calculations = useStore($calculations);
  const explanationTexts = useStore($explanationTexts);

  return {
    calculations,
    prices,
    explanationTexts,
  };
}
