import * as React from 'react';
import { Suspense } from 'react';
import { useStore } from "effector-react";
import styled from 'styled-components';

import { navigate, RouteComponentProps } from "@reach/router";
import {
  PIPE_MATERIAL_OPTIONS,
  PipeMaterialType,
  PUMP_PERFORMANCE_OPTIONS,
  PumpPerformanceType,
  $boreholeLocation,
  setBoreholeLocation,
  $boreholeDepth,
  setBoreholeDepth,
  $pumpPerformance,
  setPumpPerformance,
  $pipeMaterial,
  setPipeMaterial,
  resetBoring,
} from "stores/boring";

import { NumberInput } from "ui/atoms/Input";
import { CalculatorBreadcrumbsStep } from "ui/molecules/CalculatorBreadcrumbs";
import { DropdownSelect } from "ui/molecules/DropdownSelect";
import { CalculatorControl } from "ui/molecules/CalculatorControl";
import { CalculatorStepTemplate } from "./CalculatorStepTemplate";

const LocationInput = React.lazy(() => import('ui/molecules/LocationInput'));

export const CalculatorBoring: React.FC<RouteComponentProps> = () => {
  const boreholeLocation = useStore($boreholeLocation);
  const boreholeDepth = useStore($boreholeDepth);
  const pumpPerformance = useStore($pumpPerformance);
  const pipeMaterial = useStore($pipeMaterial);

  const goToNextStep = () => { navigate('/calculator/water-supply') };

  const skipStep = () => {
    resetBoring();
    goToNextStep();
  };

  const onCloseButtonClick = () => { navigate('/'); };

  const onBoreholeSelect = ({
    value,
    depth,
  }: {
    value: string,
    depth: number,
    lat: number,
    lng: number,
  }) => {
    const withoutCounty = value.replace(/.+? моск.+?, /i, '');
    setBoreholeLocation(withoutCounty);
    setBoreholeDepth(depth);
  };

  return (
    <CalculatorStepTemplate
      currentStep={CalculatorBreadcrumbsStep.boring}
      goToNextStep={goToNextStep}
      skipStep={skipStep}
      onCloseButtonClick={onCloseButtonClick}
    >
      <CalculatorQuestion
        title="Местоположение скважины"
      >
        <Suspense fallback={<div>Загрузка</div>}>
          <LocationInput value={boreholeLocation} onSelect={onBoreholeSelect} />
        </Suspense>
      </CalculatorQuestion>
      <CalculatorQuestion
        title="Глубина"
      >
        <NumberInput
          value={boreholeDepth}
          onChange={setBoreholeDepth}
        />
      </CalculatorQuestion>
      <CalculatorQuestion
        title="Производительность"
      >
        <DropdownSelect<PumpPerformanceType>
          value={pumpPerformance}
          options={PUMP_PERFORMANCE_OPTIONS}
          onChange={setPumpPerformance}
        />
      </CalculatorQuestion>
      <CalculatorQuestion
        title="Конструкция"
      >
        <DropdownSelect<PipeMaterialType>
          value={pipeMaterial}
          options={PIPE_MATERIAL_OPTIONS}
          onChange={setPipeMaterial}
        />
      </CalculatorQuestion>
    </CalculatorStepTemplate>
  );
};

const CalculatorQuestion = styled(CalculatorControl)`
  :not(:first-of-type) {
    margin-top: 20px;
  }
`;
