import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from '@reach/router';

import { BREAKPOINTS } from 'ui/constants';
import { MenuInformationPic } from 'ui/molecules/MenuInformationPic';
import { MenuWaterSupply, WaterSystemOption } from './MenuWaterSupply';
import { ToolTipDot, ToolTipText, ToolTipGuide } from 'ui/atoms/IllustrationToolTip';

import WaterSupply2bgURL from 'ui/molecules/MenuInformationPic/static/water-supply-2-bg.jpg';
import WaterSupply2fgURL from 'ui/molecules/MenuInformationPic/static/water-supply-2-fg.png';
import { ReactComponent as WaterSupply2C1 } from 'ui/molecules/MenuInformationPic/static/water-supply-2-c1.svg';
import BGGuideLine9 from './static/guide-line-09.svg';
import BGGuideLine10 from './static/guide-line-10.svg';

export const MenuWaterSupplyKesson: React.FC<RouteComponentProps> = () => (
  <MenuWaterSupply
    pic={<Pic />}
    currentStep={WaterSystemOption.caisson}
    description={
      <Text>
        Герметичный кессон представляет собой специальную камеру, которая защищает оборудование, от ливневой и грунтовой
        воды, а также от замерзания. Так же предотвращает проникновение мусора и талой воды в чистый источник.
        Обеспечивает бесперебойную подачу воды в дом.
      </Text>
    }
  />
);

export const Pic = () => (
  <MenuInformationPic backgroundURL={WaterSupply2bgURL} foregroundURL={WaterSupply2fgURL}>
    <WaterSupply2C1Styled />

    <ToolTipDot9>
      <ToolTipText9>
        Гидроаккумулятор разделен на две части каучуковой мембраной. В одной части бака находится вода. В другую часть
        закачивается воздух, который стабилизирует давление в системе
      </ToolTipText9>
      <ToolTipGuide9 />
    </ToolTipDot9>

    <ToolTipDot10>
      <ToolTipText10>
        Поступление воды в рабочую камеру предотвращается нагнетанием в неё сжатого воздуха. Кессон сооружается на
        поверхности и погружается в грунт под действием собственного веса и веса надкессонного строения по мере выемки
        грунта
      </ToolTipText10>
      <ToolTipGuide10 />
    </ToolTipDot10>
  </MenuInformationPic>
);

const WaterSupply2C1Styled = styled(WaterSupply2C1)`
  animation-delay: 0s;
  top: 67%;
  left: 68%;
  width: calc(255 / 983 * 100%);
  height: calc(154 / 481 * 100%);
`;

const Text = styled.p`
  font-size: 16px;
  width: 350px;
  max-width: 100%;

  @media (min-width: ${BREAKPOINTS.desktopMin}px) {
    font-size: 18px;
    width: 480px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 18px;
    line-height: 1.4;
    width: 100%;
  }
`;

const LocalToolTipDot = styled(ToolTipDot)`
  @media (max-width: ${BREAKPOINTS.laptopMax}px) {
    transform: scale(0.9);
  }

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    transform: scale(0.8);
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    transform: scale(0.7);
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    transform: scale(0.8);
  }
`;

const ToolTipDot9 = styled(LocalToolTipDot)`
  left: 65%;
  top: 64%;
`;

const ToolTipText9 = styled(ToolTipText)`
  width: 303px;
  height: 148px;
  clip-path: polygon(100% 0, 100% 70%, 85% 100%, 0 100%, 0 0);
  bottom: 75px;
  right: 305px;
`;

const ToolTipGuide9 = styled(ToolTipGuide)`
  background-image: url(${BGGuideLine9});
  width: 310px;
  height: 138px;
  bottom: 0;
  right: 0;
`;
const ToolTipDot10 = styled(LocalToolTipDot)`
  left: 68%;
  top: 77%;
`;

const ToolTipText10 = styled(ToolTipText)`
  width: 328px;
  height: 185px;
  clip-path: polygon(100% 0, 100% 70%, 85% 100%, 0 100%, 0 0);
  top: -112px;
  right: 228px;
`;

const ToolTipGuide10 = styled(ToolTipGuide)`
  background-image: url(${BGGuideLine10});
  width: 235px;
  height: 70px;
  top: 0;
  right: 0;
`;
