import { combine, createEffect, createStore } from "effector";
import { keyOptionsByValue } from "utils/keyOptionsByValue";
import { API_ROOT } from "hooks/useApiCall";

import { $boreholeDepth, $pipeMaterial, $pumpPerformance } from "stores/boring";
import { $boreholeActivity, $machineRoom } from "stores/waterSupply";
import {
  $additionalDrainageWellSize,
  $distanceToConnectionPoint,
  $distanceToRainwaterSewerSystem,
  $familyMembersCount,
  ADDITIONAL_DRAINAGE_WELL_SIZE_OPTIONS,
  AdditionalDrainageWellSizeType,
  FamilyMembersCountType,
} from "stores/sewerSystem";
import { pluralizeMeters } from "utils/formatText";

const defaultCalculatorSettings = {
  metal3: 1400,
  metal5: 1600,
  metalplastic3: 2400,
  metalplastic5: 2800,
  pvcu3: 2000,
  pvcu5: 2200,
  summer: 10000,
  fulltime: 30000,
  machineRoomPrice: 8000,
  family0: 0,
  family3: 100,
  family5: 150,
  family6: 160,
  family7: 170,
  family8: 200,
  family10: 250,
  connectionPointPrice: 1700,
  additionalDrainageWellPrices0: 0,
  additionalDrainageWellPrices1: 2000,
  additionalDrainageWellPrices2: 4000,
  additionalDrainageWellPrices3: 6000,
  rainwaterSewerSystemPrice: 1500,

  boringProcedures: 'Бурение скважины',
  metal3Label: 'Grundfos 430xm',
  metal5Label: 'Grundfos 440xm',
  metalplastic3Label: 'Grundfos 450xm',
  metalplastic5Label: 'Grundfos 460xm',
  pvcu3Label: 'Grundfos 470xm',
  pvcu5Label: 'Grundfos 480xm',

  waterSupplyProcedures: 'Подключение водоснабжения',
  summerLabel: 'Летник',
  fulltimeLabel: 'Кессон',
  fullTimeWithMachineRoom: 'Кессон + адаптер',

  sewerSystemProcedures: 'Подключение канализации',
  family3Label: '',
  family5Label: '',
  family8Label: '',
  family10Label: '',
  family99Label: '',
};

const fetchCalculatorSettingsFx = createEffect(async () => {
  const req = await global.fetch(`${API_ROOT}/calculator-settings`);
  return req.json();
});

export const $calculatorSettings = createStore(defaultCalculatorSettings)
  .on(fetchCalculatorSettingsFx.doneData, (v, settings) => ({ ...v, ...settings }));

const $calculatorValues = combine({
  $boreholeDepth,
  $pumpPerformance,
  $pipeMaterial,
  $boreholeActivity,
  $machineRoom,
  $familyMembersCount,
  $distanceToConnectionPoint,
  $additionalDrainageWellSize,
  $distanceToRainwaterSewerSystem,
});

export const $prices = $calculatorSettings.map((data) => ({
  boringPrices: {
    metal3: data?.metal3 || 0,
    metal5: data?.metal5 || 0,
    metalplastic3: data?.metalplastic3 || 0,
    metalplastic5: data?.metalplastic5 || 0,
    pvcu3: data?.pvcu3 || 0,
    pvcu5: data?.pvcu5 || 0,
  } as {[key: string]: number},

  boreholeActivityPrices: {
    summer: data?.summer || 0,
    fulltime: data?.fulltime || 0,
  } as {[key: string]: number},
  machineRoomPrice: data?.machineRoomPrice || 0,

  familyMembersCountPrices: {
    [FamilyMembersCountType.p0]: data?.family0 || 0,
    [FamilyMembersCountType.p3]: data?.family3 || 0,
    [FamilyMembersCountType.p5]: data?.family5 || 0,
    [FamilyMembersCountType.p6]: data?.family6 || 0,
    [FamilyMembersCountType.p7]: data?.family7 || 0,
    [FamilyMembersCountType.p8]: data?.family8 || 0,
    [FamilyMembersCountType.p10]: data?.family10 || 0,
  } as {[key: string]: number},

  connectionPointPrice: data?.connectionPointPrice || 0,
  additionalDrainageWellPrices: {
    [AdditionalDrainageWellSizeType.w0]: data?.additionalDrainageWellPrices0 || 0,
    [AdditionalDrainageWellSizeType.w1]: data?.additionalDrainageWellPrices1 || 0,
    [AdditionalDrainageWellSizeType.w2]: data?.additionalDrainageWellPrices2 || 0,
    [AdditionalDrainageWellSizeType.w3]: data?.additionalDrainageWellPrices3 || 0,
  } as {[key: string]: number},
  rainwaterSewerSystemPrice: data?.rainwaterSewerSystemPrice || 0,
}));

const $labels = $calculatorSettings.map((data) => ({
  boringProcedures: data?.boringProcedures || '',
  boringLabels: {
    metal3: data?.metal3Label || '',
    metal5: data?.metal5Label || '',
    metalplastic3: data?.metalplastic3Label || '',
    metalplastic5: data?.metalplastic5Label || '',
    pvcu3: data?.pvcu3Label || '',
    pvcu5: data?.pvcu5Label || '',
  } as {[key: string]: string},

  waterSupplyProcedures: data?.waterSupplyProcedures || '',
  summerLabel: data?.summerLabel || '',
  fulltimeLabel: data?.fulltimeLabel || '',
  fullTimeWithMachineRoomLabel: data?.fullTimeWithMachineRoom || '',

  sewerSystemProcedures: data?.sewerSystemProcedures || '',
  familyMembersCountLabels: {
    3: data?.family3Label || '',
    5: data?.family5Label || '',
    8: data?.family8Label || '',
    10: data?.family10Label || '',
    99: data?.family99Label || '',
  } as {[key: string]: string},
}));

export const $calculations = combine(
  $calculatorValues,
  $prices,
  (
    calculatorValues,
    prices,
  ) => {
    const boringCost = prices.boringPrices[
      `${calculatorValues.$pipeMaterial}${calculatorValues.$pumpPerformance}`
    ] * calculatorValues.$boreholeDepth;
    const waterSupplyCost = (
      (prices.boreholeActivityPrices[calculatorValues.$boreholeActivity] || 0)
    + ((calculatorValues.$boreholeActivity === 'fulltime' && calculatorValues.$machineRoom) ? prices.machineRoomPrice : 0)
    );
    const sewerSystemCost = (
      prices.familyMembersCountPrices[+calculatorValues.$familyMembersCount]
    + (
      calculatorValues.$familyMembersCount === FamilyMembersCountType.p0
        ? 0
        : prices.connectionPointPrice * calculatorValues.$distanceToConnectionPoint
    )
    + prices.additionalDrainageWellPrices[+calculatorValues.$additionalDrainageWellSize]
    + prices.rainwaterSewerSystemPrice * calculatorValues.$distanceToRainwaterSewerSystem
    );

    const total = boringCost + waterSupplyCost + sewerSystemCost;

    return {
      boringCost,
      waterSupplyCost,
      sewerSystemCost,
      total,
    }
  },
);

export const $explanationTexts = combine(
  $calculatorValues,
  $labels,
  (calculatorValues, labels) => {
    const boringTiming = 1;
    const boringMaterials = [
      `${calculatorValues.$boreholeDepth} ${pluralizeMeters(calculatorValues.$boreholeDepth)}`,
      labels.boringLabels[
        `${calculatorValues.$pipeMaterial}${calculatorValues.$pumpPerformance}`
      ],
    ] as string[];

    const waterSupplyMaterials = [
      {
        true: labels.summerLabel,
        [String(calculatorValues.$boreholeActivity === 'fulltime')]: labels.fulltimeLabel,
        [String(calculatorValues.$boreholeActivity === 'fulltime' && calculatorValues.$machineRoom)]: labels.fullTimeWithMachineRoomLabel,
      }.true,
    ];
    const waterSupplyTiming = 2;

    const sewerSystemTiming = 1;
    const sewerSystemMaterials = [
      labels.familyMembersCountLabels[+calculatorValues.$familyMembersCount],
      ...(calculatorValues.$additionalDrainageWellSize > 0
        ? [keyOptionsByValue(
          ADDITIONAL_DRAINAGE_WELL_SIZE_OPTIONS,
        )[calculatorValues.$additionalDrainageWellSize],
        ]
        : []
      ),
    ] as string[];

    const totalTiming = boringTiming + waterSupplyTiming + sewerSystemTiming;

    return ({
      boring: {
        timing: boringTiming,
        materials: boringMaterials,
        procedures: labels.boringProcedures,
      },
      waterSupply: {
        timing: waterSupplyTiming,
        materials: waterSupplyMaterials,
        procedures: labels.waterSupplyProcedures,
      },
      sewerSystem: {
        timing: sewerSystemTiming,
        materials: sewerSystemMaterials,
        procedures: labels.sewerSystemProcedures,
      },
      total: {
        timing: totalTiming,
      },

    });
  },
)

fetchCalculatorSettingsFx();
