import * as React from 'react';
import styled from "styled-components";
import { useStore } from "effector-react";

import { navigate, RouteComponentProps } from "@reach/router";
import {
  $boreholeActivity,
  setBoreholeActivity,
  $machineRoom,
  setMachineRoom,
  BOREHOLE_ACTIVITY_OPTIONS,
  BoreholeActivityType,
  resetWaterSupply,
} from "stores/waterSupply";

import { Checkbox } from "ui/atoms/Checkbox";
import { CalculatorBreadcrumbsStep } from "ui/molecules/CalculatorBreadcrumbs";
import { RadioSelect } from "ui/molecules/RadioSelect";
import { CalculatorControl } from "ui/molecules/CalculatorControl";
import { CalculatorStepTemplate } from "./CalculatorStepTemplate";

export const CalculatorWaterSupply: React.FC<RouteComponentProps> = () => {
  const boreholeActivity = useStore($boreholeActivity);
  const machineRoom = useStore($machineRoom);

  const goToNextStep = () => { navigate('/calculator/sewer-system') };

  const skipStep = () => {
    resetWaterSupply();
    goToNextStep();
  };

  const onCloseButtonClick = () => { navigate('/'); };

  return (
    <CalculatorStepTemplate
      currentStep={CalculatorBreadcrumbsStep.waterSupply}
      goToNextStep={goToNextStep}
      skipStep={skipStep}
      onCloseButtonClick={onCloseButtonClick}
    >

      <CalculatorQuestion
        title="Эксплуатация скважины"
      >
        <RadioSelect<BoreholeActivityType>
          value={boreholeActivity}
          name="boreholeActivity"
          options={BOREHOLE_ACTIVITY_OPTIONS}
          onChange={setBoreholeActivity}
        />
        {boreholeActivity === 'fulltime'
        && (
          <CheckboxLocal
            label="Наличие тех. помещения 0.8 кв.м."
            checked={machineRoom}
            onChange={setMachineRoom}
          />
        )}
      </CalculatorQuestion>
    </CalculatorStepTemplate>
  );
};

const CalculatorQuestion = styled(CalculatorControl)`
`;

const CheckboxLocal = styled(Checkbox)`
  margin-top: 16px;
`;
