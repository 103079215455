import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { Link, navigate } from '@reach/router';

import { Container as ContainerDefault } from 'ui/atoms/Container';
import { StyledText as StyledTextDefault } from 'ui/atoms/StyledText';
import { BREAKPOINTS, COLORS } from 'ui/constants';
import { Icon } from 'ui/atoms/Icon';

import { formatText } from 'utils/formatText';
import { FaqI, FaqPageI } from "hooks/useApiCall";
import IconChevron from './static/chevron.svg';

interface MenuItemI extends FaqPageI {
  selected: boolean,
}

interface SFaqI extends FaqI {
  selected: boolean,
}

interface MenuFAQProps {
  menuItems: MenuItemI[],
  selectedPage: FaqPageI,
  faqs: SFaqI[],
}

export const MenuFAQ: React.FC<MenuFAQProps> = ({
  menuItems,
  faqs,
  selectedPage,
}) => {
  const onCloseClick = () => {
    navigate('/');
  };

  const onBackClick = () => {
    navigate('/menu');
  };

  return (
    <Wrapper>
      <Container>
        <MainControls>
          <MainControlsBackButton onClick={onBackClick}>
            <Icon iconName="IconArrow" size={22} rotate={90} />
          </MainControlsBackButton>

          <MainControlsCloseButton onClick={onCloseClick}>
            Вернуться на главную
            <MainControlsCloseButtonIcon iconName="IconClose" size={18} />
          </MainControlsCloseButton>
        </MainControls>

        <Body>
          <Aside>
            {menuItems?.map((page) => (
              <AsideItem key={page.id}>
                <AsideLink to={`/faq/${page.id}`} active={page.selected}>
                  {page.pageName}
                </AsideLink>
              </AsideItem>
            ))}
          </Aside>

          <Main>
            {faqs.map((faq) => (
              <Item active={faq.selected} key={faq.id}>
                <ItemHead to={`/faq/${selectedPage.id}/${faq.id}`} disabled={faq.selected}>{faq.title}</ItemHead>
                <ItemBody>{formatText(faq.faqText)}</ItemBody>
              </Item>
            ))}
          </Main>
        </Body>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 40px 0 80px;
`;

const Container = styled(ContainerDefault)``;

const Body = styled.div`
  display: flex;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    flex-direction: column;
  }
`;

const Aside = styled.div`
  width: 30%;
  flex: 0 0 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    width: 100%;
    flex: 0 0 auto;
    flex-direction: row;
    margin-bottom: 30px;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari and Opera */
    }
  }
`;

const AsideItem = styled.div`
  @media (min-width: ${BREAKPOINTS.laptopSmallMin}px) {
    margin-bottom: 30px;
    max-width: 215px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const AsideLink = styled(Link)<{ active?: boolean }>`
  display: inline;
  font-size: 18px;
  color: ${({ active }) => (active ? COLORS.navytech : COLORS.blacktech)};
  font-weight: ${({ active }) => (active ? '600' : '400')};
  background: ${({ active }) => (active
    ? `linear-gradient(
    180deg,
    ${COLORS.transparent} 0%,
    ${COLORS.transparent} 60%,
    ${rgba(COLORS.navytech, 0.1)} 60%,
    ${rgba(COLORS.navytech, 0.1)} 100%
  )`
    : 'transparent')};

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    white-space: nowrap;
    font-size: 16px;
  }
`;

const Main = styled.div`
  width: 70%;
  flex: 0 0 70%;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    width: 100%;
    flex: 0 0 auto;
  }
`;

const ItemHead = styled(Link)<{ disabled: boolean }>`
  position: relative;
  font-size: 24px;
  line-height: 1.2;
  font-weight: 500;
  color: ${COLORS.navytech};
  padding-left: 35px;
  pointer-events: ${(p) => p.disabled && 'none'};

  &::before {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    background: transparent url(${IconChevron}) center no-repeat;
    background-size: contain;
    position: absolute;
    left: 0;
    top: 3px;
  }
`;

const ItemBody = styled(StyledTextDefault)`
  margin-top: 30px;
  padding-left: 35px;
`;

const Item = styled.div<{ active?: boolean }>`
  margin-bottom: 60px;

  &:last-child {
    margin-bottom: 0;
  }

  ${ItemHead} {
    &:before {
      transform: ${({ active }) => (!active ? 'rotate(-90deg)' : '')};
    }
  }

  ${ItemBody} {
    display: ${({ active }) => (!active ? 'none !important' : '')};
  }
`;

const MainControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 90px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-bottom: 40px;
  }
`;

const MainControlsBackButton = styled.button`
  color: ${COLORS.blacktech};
  opacity: 0.65;
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 1;
  }
`;

const MainControlsCloseButtonIcon = styled(Icon)``;

const MainControlsCloseButton = styled.button`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  opacity: 0.65;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  opacity: 0.65;
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 1;
  }

  ${MainControlsCloseButtonIcon} {
    margin-left: 10px;
  }
`;
