import * as React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { BREAKPOINTS, COLORS } from 'ui/constants';
import { pluralizeMeters } from 'utils/formatText';

interface InputProps {
  value: string;
  onChange: (newValue: string) => void;
  className?: string;
  onKeyUp?: (e: React.KeyboardEvent) => void;
  placeholder?: string;
  maxLength?: number;
}

export const Input: React.FC<InputProps> = ({
  value,
  onChange,
  placeholder = '',
  className,
  onKeyUp,
  maxLength = 524288,
}) => {
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };
  return (
    <StyledInput
      className={className}
      value={String(value)}
      onChange={onInputChange}
      onKeyUp={onKeyUp}
      placeholder={placeholder}
      maxLength={maxLength}
    />
  );
};

interface NumberInputProps {
  value: number;
  onChange: (newValue: number) => void;
}

export const NumberInput: React.FC<NumberInputProps> = ({ value, onChange }) => {
  const onInputChange = (v: string) => {
    const number = v.replace(/\D/g, '');
    onChange(+number || 0);
  };
  const valueWithUnit = `${value} ${pluralizeMeters(value)}`;
  const onKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Backspace') {
      e.preventDefault();
      onChange(0);
    }
  };
  return (
    <InputWrapper>
      <Input value={valueWithUnit} onChange={onInputChange} onKeyUp={onKeyUp} />
    </InputWrapper>
  );
};

const StyledInput = styled.input`
  color: ${(p) => (p.value === '0 метров' ? rgba(COLORS.blacktech, 0.45) : COLORS.blacktech)};
  background: transparent;
  border: none;
  border-bottom: 2px solid #eee;
  transition: border-color 0.2s ease;

  &:focus {
    border-color: orange;
    outline: none;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    width: 100%;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  font-size: 28px;
  font-weight: 400;
  line-height: 28px;
  min-height: 42px;

  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    font-size: 18px;
    line-height: 18px;
    min-height: 32px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 16px;
    line-height: 16px;
    min-height: 20px;
  }
`;
