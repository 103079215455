import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from '@reach/router';

import { BREAKPOINTS } from 'ui/constants';
import { MenuInformationPic } from 'ui/molecules/MenuInformationPic';
import { MenuWaterSupply, WaterSystemOption } from './MenuWaterSupply';
import { ToolTipDot, ToolTipText, ToolTipGuide } from 'ui/atoms/IllustrationToolTip';

import WaterSupply3bgURL from 'ui/molecules/MenuInformationPic/static/water-supply-3-bg.jpg';
import WaterSupply3fgURL from 'ui/molecules/MenuInformationPic/static/water-supply-3-fg.png';
import { ReactComponent as WaterSupply3C1 } from 'ui/molecules/MenuInformationPic/static/water-supply-3-c1.svg';
import { ReactComponent as WaterSupply3C2 } from 'ui/molecules/MenuInformationPic/static/water-supply-3-c2.svg';
import BGGuideLine11 from './static/guide-line-11.svg';
import BGGuideLine12 from './static/guide-line-12.svg';

export const MenuWaterSupplyAdapter: React.FC<RouteComponentProps> = () => (
  <MenuWaterSupply
    pic={<Pic />}
    currentStep={WaterSystemOption.adapter}
    description={
      <Text>
        Адаптеры монтируются ниже отметки промерзания грунта и защищают водопровод в зимнее время. В отличие от кессона,
        адаптер занимает гораздо меньше места и обладает значительными преимуществами. Автоматика адаптерной системы
        устанавливается в доме или другом теплом помещении.
      </Text>
    }
  />
);

const Pic = () => (
  <MenuInformationPic backgroundURL={WaterSupply3bgURL} foregroundURL={WaterSupply3fgURL}>
    <WaterSupply3C1Styled />
    <WaterSupply3C2Styled />

    <ToolTipDot11>
      <ToolTipText11>
        Гидроаккумулятор разделен на две части каучуковой мембраной. В одной части бака находится вода. В другую часть
        закачивается воздух, который стабилизирует давление в системе
      </ToolTipText11>
      <ToolTipGuide11 />
    </ToolTipDot11>

    <ToolTipDot12>
      <ToolTipText12>
        Скважинный адаптер выполняет функцию подвеса насоса и перенаправляет поток воды от вертикальной водоподъемной
        трубы в трубопровод горизонтального направления от скважины к дому.
      </ToolTipText12>
      <ToolTipGuide12 />
    </ToolTipDot12>
  </MenuInformationPic>
);

const WaterSupply3C1Styled = styled(WaterSupply3C1)`
  animation-delay: 0s;
  top: 29%;
  left: 53.3%;
  width: calc(102 / 988 * 100%);
  height: calc(63 / 451 * 100%);
`;

const WaterSupply3C2Styled = styled(WaterSupply3C2)`
  animation-delay: 0.2s;
  top: 62%;
  left: 67.5%;
  width: calc(126 / 988 * 100%);
  height: calc(79 / 451 * 100%);
`;

const Text = styled.p`
  font-size: 16px;
  width: 350px;
  max-width: 100%;

  @media (min-width: ${BREAKPOINTS.desktopMin}px) {
    font-size: 18px;
    width: 480px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 18px;
    line-height: 1.4;
    width: 100%;
  }
`;

const LocalToolTipDot = styled(ToolTipDot)`
  @media (max-width: ${BREAKPOINTS.laptopMax}px) {
    transform: scale(0.9);
  }

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    transform: scale(0.8);
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    transform: scale(0.7);
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    transform: scale(0.8);
  }
`;

const ToolTipDot11 = styled(LocalToolTipDot)`
  left: 52%;
  top: 29%;
`;

const ToolTipText11 = styled(ToolTipText)`
  width: 303px;
  height: 148px;
  clip-path: polygon(100% 0, 100% 80%, 90% 100%, 0 100%, 0 0);
  top: -40px;
  right: 90px;
`;

const ToolTipGuide11 = styled(ToolTipGuide)`
  background-image: url(${BGGuideLine11});
  width: 94px;
  height: 34px;
  top: 0;
  right: 0;
`;

const ToolTipDot12 = styled(LocalToolTipDot)`
  left: 62%;
  top: 67%;
`;

const ToolTipText12 = styled(ToolTipText)`
  width: 313px;
  height: 148px;
  clip-path: polygon(100% 0, 100% 80%, 90% 100%, 0 100%, 0 0);
  top: -8px;
  right: 125px;
`;

const ToolTipGuide12 = styled(ToolTipGuide)`
  background-image: url(${BGGuideLine12});
  width: 130px;
  height: 73px;
  top: 0;
  right: 0;
`;
