import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Container as ContainerDefault } from 'ui/atoms/Container';
import { Title as TitleDefault } from 'ui/atoms/Typography';
import { COLORS, BREAKPOINTS } from 'ui/constants';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ImgBackgroundPrimary from './static/background-primary.svg';
import ImgBackgroundTablet from './static/background-tablet.svg';
import ImgBackgroundPhone from './static/background-phone.svg';

export interface AboutItemI {
  name: string;
  label: string;
  desc: string;
  descShort: string;
}

interface AboutUsI {
  aboutItems: AboutItemI[];
}

export const AboutUs: React.FC<AboutUsI> = (props) => {
  const { aboutItems } = props;
  const [activeItemIDX, setActiveItemIDX] = useState(0);

  const navItems = [...aboutItems];
  const mainItems = navItems.splice(activeItemIDX, 1); // splice remove 1 item by index and return it
  const mainItem = mainItems.length > 0 ? mainItems[0] : null;

  const handlerChangeActiveItem = (name: string) => {
    const originalItemsIDX = aboutItems.findIndex((i) => i.name === name);
    if (originalItemsIDX > -1) {
      setActiveItemIDX(originalItemsIDX);
    }
  };

  useEffect(() => {
    setActiveItemIDX(0);
  }, [aboutItems]);

  return (
    <Body>
      <Container>
        <Head>
          <Title>
            Гидротех - <br /> команда настоящих профессионалов
          </Title>
        </Head>
        <Nav>
          {navItems.map((item) => (
            <CSSTransition key={item.name} timeout={{ enter: 500, exit: 0 }} classNames="slide-animation">
              <NavItem key={item.name} onClick={() => handlerChangeActiveItem(item.name)}>
                <ItemName>{item.name}</ItemName>
                <ItemLabel>{item.label}</ItemLabel>
                <ItemDesc>{item.descShort}</ItemDesc>
              </NavItem>
            </CSSTransition>
          ))}
        </Nav>
        <Main>
          {mainItem && (
            <CSSTransition timeout={700} key={mainItem.name} classNames="slide-animation">
              <MainContent>
                <ItemNamePrimary>{mainItem.name}</ItemNamePrimary>
                <ItemLabelPrimary>{mainItem.label}</ItemLabelPrimary>
                <ItemDescPrimary>{mainItem.desc}</ItemDescPrimary>
              </MainContent>
            </CSSTransition>
          )}
        </Main>
      </Container>
    </Body>
  );
};

const Body = styled.div`
  padding: 80px 0;
  background-color: #f9fbf7;
  background-image: url(${ImgBackgroundPrimary});
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    padding: 60px 0;
    background-image: url(${ImgBackgroundTablet});
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    padding: 40px 0;
    background-image: url(${ImgBackgroundPhone});
  }
`;

const Container = styled(ContainerDefault)`
  display: grid;
  grid-template-areas:
    'head main'
    'nav main';
  grid-template-columns: 42% 58%;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    grid-template-areas:
      'head nav'
      'main main';
    grid-template-columns: auto auto;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    grid-template-areas:
      'head'
      'nav'
      'main';

    grid-template-columns: 100%;
  }
`;

const Head = styled.div`
  grid-area: head;
`;

const Title = styled(TitleDefault)`
  width: 270px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    width: 170px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    width: 140px;
  }
`;

const Main = styled(TransitionGroup)`
  grid-area: main;
  position: relative;

  .slide-animation {
    &-enter {
      opacity: 0;
      &-active {
        opacity: 1;
        transition: opacity 700ms ease-in-out 200ms;
      }
    }
    &-exit {
      position: absolute;
      opacity: 1;
      left: 0;
      top: 0;
      &-active {
        opacity: 0;
        left: -60px;
        transition: opacity 500ms ease-in-out, left 500ms ease-in-out;
      }
    }
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-top: 60px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-top: 40px;
  }
`;

const MainContent = styled.div``;

const ItemName = styled.h4`
  position: relative;
  font-size: 48px;
  line-height: 1.2;
  font-weight: 500;
  color: ${COLORS.navytech};
  transition: transform 0.2s ease-out, font-weight 0.2s ease-out, color 0.2s ease-out;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 40px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    font-size: 24px;
  }

  &::before {
    content: '';
    display: block;
    width: 60px;
    height: 1px;
    background-color: ${COLORS.navytech};
    transform: rotate(-45deg) translate(-45px, -15px);
    transform-origin: left top;

    @media (max-width: ${BREAKPOINTS.phoneMax}px) {
      width: 40px;
      transform: rotate(-45deg) translate(-25px, -10px);
    }
  }
`;

const ItemLabel = styled.p`
  margin-top: 5px;
  font-size: 20px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 16px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    font-size: 14px;
    height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ItemDesc = styled.p`
  margin-top: 35px;
  font-size: 20px;
  font-weight: 300;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-top: 20px;
    font-size: 16px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    font-size: 12px;
    line-height: 1.15;
    margin-top: 15px;
  }
`;

const ItemNamePrimary = styled.h3`
  position: relative;
  z-index: 1;
  display: inline-block;
  font-size: 52px;
  line-height: 1;
  font-weight: 600;
  color: ${COLORS.navytech};

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 38px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    font-size: 26px;
  }

  &::before {
    content: '';
    display: block;
    width: 60px;
    height: 1px;
    background-color: ${COLORS.navytech};
    transform: rotate(-45deg) translate(-45px, -15px);
    transform-origin: left top;
  }

  &::after {
    display: block;
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 45%;
    background-color: ${COLORS.navytech};
    opacity: 0.1;
    transition: opacity 0.2s ease-out;
  }
`;

const ItemLabelPrimary = styled.p`
  font-size: 52px;
  line-height: 1.1;
  color: ${COLORS.navytech};
  width: 90%;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 38px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    font-size: 26px;
  }
`;

const ItemDescPrimary = styled.p`
  font-size: 42px;
  line-height: 1.2;
  color: ${COLORS.navytech};
  width: 90%;
  margin-top: 15px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 38px;
    margin-top: 10px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    font-size: 26px;
    line-height: 1.2;
  }
`;

const Nav = styled(TransitionGroup)`
  grid-area: nav;
  margin-top: 270px;
  display: flex;
  align-items: flex-start;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-top: 0;
    justify-self: end;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-top: 50px;
  }

  .slide-animation {
    &-enter {
      opacity: 0;

      &-active {
        opacity: 1;
        transition: opacity 200ms ease-in;
      }
    }

    &-exit {
      display: none;
    }
  }
`;

const NavItem = styled.div`
  margin-right: 40px;
  flex: 0 0 250px;
  width: 250px;
  max-width: 250px;
  cursor: pointer;

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    flex: 0 0 200px;
    width: 200px;
    max-width: 200px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    flex: 0 0 180px;
    width: 180px;
    max-width: 180px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    flex: 0 0 90px;
    width: 90px;
    max-width: 90px;
    margin-right: 30px;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    ${ItemName} {
      color: ${COLORS.yellowtech};
      transform: translateY(-2px);

      &::before {
        background-color: ${COLORS.yellowtech};
      }
    }
  }
`;
