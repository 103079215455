import React from 'react';

import styled from 'styled-components';
import { rgba } from 'polished';
import { BREAKPOINTS, COLORS } from 'ui/constants';

interface ExplanationControlProps {
  title: string;
}

export const ExplanationControl: React.FC<ExplanationControlProps> = ({ title, children }) => (
  <ExplanationControlStyled>
    <Title>{title}</Title>
    {children}
  </ExplanationControlStyled>
);

const ExplanationControlStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  margin-bottom: 60px;

  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    margin-bottom: 80px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-bottom: 35px;
  }
  
  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-bottom: 30px;
  }
`;

const Title = styled.p`
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 450;
  line-height: 21px;

  margin-bottom: 32px;
  color: ${rgba(COLORS.blacktech, 0.65)};

  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
  }
`;
