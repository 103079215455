import styled from 'styled-components';
import { rgba } from 'polished';

import { BREAKPOINTS } from 'ui/constants';

export const Head = styled.div`
  display: grid;
  position: sticky;
  z-index: 100;
  top: 0;
  background-color: #fff;
`;

export const HeadItem = styled.div`
  padding: 20px 10px;

  &:not(:first-child) {
    text-align: center;
  }

  &:nth-child(1) {
    position: sticky;
    left: 0;
    background-color: #fff;

    &:after {
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      box-shadow: 3px 0px 3px ${rgba('#000', 0.05)};
    }
  }

  span {
    color: #78796d;
  }
`;

export const Body = styled.div``;

export const Item = styled.div`
  padding: 20px 10px;
  font-size: 18px;

  @media (max-width: ${BREAKPOINTS.laptopMax}px) {
    font-size: 16px;
  }

  &:not(:first-child) {
    text-align: center;
  }

  &:nth-child(1) {
    position: sticky;
    left: 0;
    font-weight: 300;
    color: #78796d;

    &:after {
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      box-shadow: 3px 0px 3px ${rgba('#000', 0.05)};
    }
  }

  &:nth-child(2) {
    background-color: ${rgba('#28502E', 0.022)};
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr 1fr;

  &:nth-child(2n - 1) {
    ${Item} {
      background-color: #fff;
    }
  }

  &:nth-child(2n) {
    ${Item} {
      background-color: #f9fbf8;
    }
  }
`;
