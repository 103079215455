import { BrandBannerItemI } from './BrandBanner';

import ImgBrandBanner1 from './static/brand-banner-1.png';
import ImgBrandBanner2 from './static/brand-banner-2.png';
import ImgBrandBanner3 from './static/brand-banner-3.png';

export const BRAND_BANNER: BrandBannerItemI[] = [
  {
    id: 1,
    title: 'Бурение скважин \nлюбой сложности',
    subtitle: 'От 2000 руб. за метр',
    text:
      'Мы занимаемся бурением и обустройством скважин **более 15 лет,** в наличии все необходимое оборудование, а главное — это огромный опыт наших специалистов.',
    previewImg: {
      id: 1,
      url: ImgBrandBanner1,
    },
  },
  {
    id: 2,
    title: 'Автономная канализация, септик',
    subtitle: 'От 62000 руб.',
    text:
      'Мы аккуратно и бережно относимся к вашему участку. По окончанию всех работ наши сотрудники **убирают за собой мусор.**',
    previewImg: {
      id: 2,
      url: ImgBrandBanner2,
    },
  },
  {
    id: 3,
    title: 'Отопление',
    subtitle: 'От 30000 руб.',
    text:
      'Зимой и летом. В дождь и вьюгу - в любую погоду. Буровые бригады нашей компании работают **круглый год в любых погодных условиях.**',
    previewImg: {
      id: 3,
      url: ImgBrandBanner3,
    },
  },
];
