import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useStore } from 'effector-react';
import styled, { keyframes } from 'styled-components';
import { navigate } from '@reach/router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useSwipeable } from 'react-swipeable';

import { $callbackFormVisible, showCallbackForm } from 'stores/callbackForm';

import { useWindowSize } from "hooks/useWindowSize";
import { COLORS, BREAKPOINTS } from 'ui/constants';
import { Container as ContainerDefault } from 'ui/atoms/Container';
import { formatText } from 'utils/formatText';
import { ButtonPrimary, ButtonGhost, ButtonIcon } from 'ui/atoms/Button';
import { Icon } from 'ui/atoms/Icon';
import { SwiperArrowPrev, SwiperArrowNext } from 'ui/atoms/SwiperArrow';
import { Highlight } from 'ui/atoms/Highlight';
import IconPhone from 'ui/molecules/CallbackForm/static/phone.svg';

import { API_ROOT } from 'hooks/useApiCall';
import ImgBackgroundPrimary from './static/background-primary.svg';
import ImgBackgroundTablet from './static/background-tablet.svg';
import ImgBackgroundPhone from './static/background-phone.svg';
// import IconPlay from './static/icon-play.svg';

export interface BrandBannerItemI {
  id: number;
  title: string;
  subtitle: string;
  text: string;
  previewImg: { id: number; url: string };
}

interface BrandBannerI {
  brandBannerItems: BrandBannerItemI[];
}

const ANIMATION_DURATION = 500;
const SLIDER_INTERVAL = 10000;

export const BrandBanner: React.FC<BrandBannerI> = (props) => {
  const { brandBannerItems } = props;
  const windowSize = useWindowSize();

  const callbackFormVisible = useStore($callbackFormVisible);

  const [activeSlideIDX, setActiveSlideIDX] = useState(0);
  const [movingRight, setMovingRight] = useState(false);
  const [transitionInProgress, setTransitionInProgress] = useState(false);
  const moveSlidesInterval = useRef(0);

  const setTransition = () => {
    setTransitionInProgress(true);
    setTimeout(() => {
      setTransitionInProgress(false);
    }, ANIMATION_DURATION);
  };

  const nextButtonDisabled = transitionInProgress || activeSlideIDX === brandBannerItems.length - 1;

  const prevButtonDisabled = transitionInProgress || activeSlideIDX === 0;

  const nextSlideHandler = useCallback(() => {
    setMovingRight(false);
    setTransition();
    setTimeout(() => {
      setActiveSlideIDX((v) => (v < brandBannerItems.length - 1 ? v + 1 : 0));
    }, 0);

    clearInterval(moveSlidesInterval.current);
    moveSlidesInterval.current = setInterval(() => nextSlideHandler(), SLIDER_INTERVAL);
  }, [brandBannerItems]);

  const prevSlideHandler = useCallback(() => {
    setMovingRight(true);
    setTransition();
    setTimeout(() => {
      setActiveSlideIDX((v) => (v > 0 ? v - 1 : brandBannerItems.length - 1));
    }, 0);

    clearInterval(moveSlidesInterval.current);
    moveSlidesInterval.current = setInterval(() => nextSlideHandler(), SLIDER_INTERVAL);
  }, [brandBannerItems, nextSlideHandler]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      nextSlideHandler();
    },
    onSwipedRight: () => {
      prevSlideHandler();
    },
  });

  const currentItem = brandBannerItems[activeSlideIDX];

  const goToCalculator = () => {
    navigate('/calculator/');
  };

  const goToExplanation = () => {
    if(windowSize.width < 768) {
      navigate('/menu');
      return;
    }

    if (activeSlideIDX === 0) {
      navigate('/menu/boring');
      return;
    }

    if (activeSlideIDX === 1) {
      navigate('/menu/water-supply/kesson');
      return;
    }

    if (activeSlideIDX === 2) {
      navigate('/menu/sewer-system');
      return;
    }
  };

  useEffect(() => {
    setActiveSlideIDX(0);
  }, [brandBannerItems]);

  useEffect(() => {
    moveSlidesInterval.current = setInterval(() => nextSlideHandler(), SLIDER_INTERVAL);
    return () => clearInterval(moveSlidesInterval.current);
  }, [nextSlideHandler]);

  return (
    <Body>
      <Container>
        {/* <PlayBlock>
          <PlayButton target="_blank" href="https://www.youtube.com/channel/UC9GICyJoMn1MuDKznnPLwKA">
            <PlayButtonIcon />
            <PlayButtonText>Как мы работаем</PlayButtonText>
          </PlayButton>
        </PlayBlock> */}

        <InfoBlock>
          <SubTitle>{currentItem.subtitle}</SubTitle>
          <Title>{currentItem.title}</Title>
        </InfoBlock>

        <ControlsBlock>
          <CalcButton onClick={goToCalculator}>Рассчитать стоимость</CalcButton>
          <OurServicesButton onClick={goToExplanation}>
            <span>Посмотреть все услуги</span>
            <ButtonIcon>
              <Icon iconName="IconArrow45" size={20} rotate={90} />
            </ButtonIcon>
          </OurServicesButton>
        </ControlsBlock>

        <MainBlock>
          <Text as="p">{formatText(currentItem.text)}</Text>

          <PreviewSliderCounter>
            <span>{activeSlideIDX + 1}</span>
            <span>{brandBannerItems.length}</span>
          </PreviewSliderCounter>

          <Arrows>
            <ArrowPrev onClick={nextSlideHandler} disabled={nextButtonDisabled} />
            <ArrowNext onClick={prevSlideHandler} disabled={prevButtonDisabled} />
          </Arrows>
        </MainBlock>

        <PreviewSliderBlock {...swipeHandlers}>
          <PreviewSliderArrowPrev onClick={prevSlideHandler} />
          <PreviewSliderArrowNext onClick={nextSlideHandler} />

          <PreviewSlider movingRight={movingRight}>
            <SpaceCreator src={`${API_ROOT}${brandBannerItems[0].previewImg.url}`} />
            <CSSTransition key={currentItem.id} timeout={ANIMATION_DURATION} classNames="slide-animation">
              <PreviewSliderItem>
                <PreviewSliderImg src={`${API_ROOT}${currentItem.previewImg.url}`} />
              </PreviewSliderItem>
            </CSSTransition>
          </PreviewSlider>
        </PreviewSliderBlock>

        {!callbackFormVisible && (
          <IconButtonPhone
            onClick={() => {
              showCallbackForm();
            }}
          />
        )}
      </Container>
    </Body>
  );
};

const Body = styled.div`
  position: relative;
  z-index: 1;
  padding: 110px 0 80px;
  background-color: transparent;
  background-image: url(${ImgBackgroundPrimary});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    padding: 60px 0;
    background-image: url(${ImgBackgroundTablet});
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-top: 60px;
    padding: 40px 0;
    background-image: url(${ImgBackgroundPhone});
  }
`;

const Container = styled(ContainerDefault)`
  display: grid;
  grid-template-areas:
    'play main'
    'info previewSlider'
    'controls previewSlider';
  grid-template-columns: 42% 58%;
  grid-auto-rows: max-content max-content max-content;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    position: relative;
    z-index: 1;
    grid-template-areas:
      'play'
      'info'
      'controls'
      'main'
      'previewSlider';
    grid-template-columns: 100%;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    grid-template-areas:
      'info'
      'main'
      'previewSlider'
      'controls'
      'play';
  }
`;

// const PlayBlock = styled.div`
//   grid-area: play;

//   @media (min-width: ${BREAKPOINTS.laptopSmallMin}px) {
//     position: relative;
//     left: -5px;
//   }

//   @media (min-width: ${BREAKPOINTS.tabletMin}px) and (max-width: ${BREAKPOINTS.tabletMax}px) {
//     position: absolute;
//     z-index: 1;
//     right: 0;
//     top: 0;
//   }

//   @media (max-width: ${BREAKPOINTS.phoneMax}px) {
//     margin-top: -30px;
//     justify-self: end;
//   }
// `;

const InfoBlock = styled.div`
  grid-area: info;
  margin-top: 55px;
  align-self: start;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-top: 0;
    width: 360px;
    max-width: 100%;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    width: 260px;
  }
`;

const ControlsBlock = styled.div`
  position: relative;
  grid-area: controls;
  align-self: end;
  display: flex;
  align-items: center;
  top: -10px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    top: 0;
    margin-top: 60px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 40px;
  }
`;

const MainBlock = styled.div`
  grid-area: main;
  display: flex;
  align-items: flex-start;
  align-self: center;

  @media (min-width: ${BREAKPOINTS.laptopSmallMin}px) {
    position: relative;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-top: 70px;
    align-items: center;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-top: 40px;
  }
`;

const PreviewSliderBlock = styled.div`
  position: relative;
  grid-area: previewSlider;
  width: 85%;
  overflow: hidden;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    width: 100%;
  }
`;

const PreviewSliderArrow = styled.div`
  position: absolute;
  z-index: 100;
  width: 50%;
  height: 100%;
  top: 0;
  cursor: pointer;
`;

const PreviewSliderArrowPrev = styled(PreviewSliderArrow)`
  left: 0;
`;

const PreviewSliderArrowNext = styled(PreviewSliderArrow)`
  right: 0;
`;

// const shakeAnimationPosition = keyframes`
//   0%, 1%, 100% { right: 0rem; }
//   0.25%, 0.75% { right: -0.5rem; }
//   0.5% { right: 0.5rem; }
// `;

// const PlayButtonIcon = styled.span`
//   position: relative;
//   width: 96px;
//   height: 96px;
//   flex: 0 0 96px;
//   border-radius: 999px;
//   border: solid 1px #c3c4bd;
//   background-color: transparent;
//   background-image: url(${IconPlay});
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: 34px auto;
//   animation: ${shakeAnimationPosition} 100s ease-in-out infinite 4s;
//   transition: transform 0.2s ease-out, background-size 0.2s ease-out;

//   @media (max-width: ${BREAKPOINTS.tabletMax}px) {
//     width: 64px;
//     height: 64px;
//     flex: 0 0 64px;
//     order: 2;
//     background-size: 35% auto;
//   }

//   @media (max-width: ${BREAKPOINTS.phoneMax}px) {
//     margin-left: 0;
//   }

//   &:hover {
//     @media (min-width: ${BREAKPOINTS.laptopSmallMin}px) {
//       transform: scale(1.05);
//       background-size: 32px auto;
//     }
//   }
// `;

// const PlayButtonText = styled.span`
//   text-transform: uppercase;
//   font-size: 14px;
//   font-weight: 450;
//   margin-left: 40px;
//   opacity: 0.65;
//   color: ${COLORS.blacktech};
//   transition: opacity 0.2s ease-out;

//   @media (max-width: ${BREAKPOINTS.tabletMax}px) {
//     order: 1;
//     width: 70px;
//     text-align: right;
//     margin-left: 0;
//     margin-right: 30px;
//   }

//   @media (max-width: ${BREAKPOINTS.phoneMax}px) {
//     display: none;
//   }

//   &:hover {
//     @media (min-width: ${BREAKPOINTS.laptopSmallMin}px) {
//       opacity: 1;
//     }
//   }
// `;

// const PlayButton = styled.a`
//   display: inline-flex;
//   align-items: center;
// `;

const SubTitle = styled.h3`
  font-size: 22px;
  font-weight: 600;
  color: ${COLORS.yellowtech};
  text-transform: uppercase;

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    font-size: 18px;
  }
`;

const Title = styled.h2`
  margin-top: 15px;
  font-size: 58px;
  line-height: 1;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 42px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    font-size: 36px;
  }
`;

const CalcButton = styled(ButtonPrimary)`
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    width: auto;
  }
`;

const OurServicesButton = styled(ButtonGhost)`
  margin-right: 30px;

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    padding: 20px;
    margin-top: 15px;
    width: auto;
  }

  &:last-child {
    margin-right: 0;
  }

  &:first-child {
    margin-right: 0;

    @media (max-width: ${BREAKPOINTS.phoneMax}px) {
      margin-top: 0;
    }
  }
`;

const Text = styled(Highlight)`
  font-size: 16px;
  width: 385px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 20px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    font-size: 16px;
    width: 225px;
  }
`;

const PreviewSliderCounter = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 1;
  display: flex;
  justify-content: space-between;
  width: 70px;
  height: 76px;
  margin-left: auto;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    width: 50px;
    height: 105px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 20px;
    width: 50px;
    height: 25px;
    font-size: 12px;
  }

  &::before {
    content: '';
    display: block;
    width: 65px;
    height: 1px;
    background-color: #9a9c92;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);

    @media (max-width: ${BREAKPOINTS.phoneMax}px) {
      width: 30px;
      transform: translate(-50%, -50%) rotate(40deg);
    }
  }

  span:first-of-type {
    align-self: flex-start;
    font-weight: 700;
  }

  span:last-of-type {
    align-self: flex-end;
  }
`;

const Arrows = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (min-width: ${BREAKPOINTS.laptopSmallMin}px) {
    position: absolute;
    z-index: 0;
    top: calc(100% + 40px);
    right: 0;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-left: 80px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-left: auto;
  }
`;

const ArrowPrev = styled(SwiperArrowPrev)`
  margin-bottom: 15px;
  width: 64px;
  height: 64px;
  border-radius: 999px;
  background-size: 30% auto;
  border: solid 1px #c3c4bd;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-bottom: 10px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const ArrowNext = styled(SwiperArrowNext)`
  margin-bottom: 15px;
  width: 64px;
  height: 64px;
  border-radius: 999px;
  background-size: 30% auto;
  border: solid 1px #c3c4bd;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-bottom: 10px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const PreviewSlider = styled(TransitionGroup)<{ movingRight: boolean }>`
  display: block;
  margin-top: 80px;
  position: relative;

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    margin-top: 40px;
  }

  .slide-animation {
    &-enter {
      position: absolute;
      opacity: 0;
      ${(p) => (p.movingRight ? 'left' : 'right')}: -250px;
      bottom: 0;
      transition: opacity 300ms ease-in-out 200ms, left 300ms ease-in-out 200ms, right 300ms ease-in-out 200ms;
      &-active {
        position: absolute;
        opacity: 1;
        ${(p) => (p.movingRight ? 'left' : 'right')}: 0;
      }
    }
    &-exit {
      position: absolute;
      opacity: 1;
      ${(p) => (p.movingRight ? 'right' : 'left')}: 0;
      bottom: 0;
      transition: opacity 200ms ease-in, left 200ms ease-in, right 200ms ease-in;
      &-active {
        opacity: 0;
        ${(p) => (p.movingRight ? 'right' : 'left')}: -250px;
      }
    }
  }
`;

const PreviewSliderItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  position: absolute;
  top: 0;
  left: 0;

  max-width: 100%;
  max-height: 100%;
`;

const PreviewSliderImg = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const SpaceCreator = styled.img`
  position: relative;
  visibility: hidden;
  display: block;
  width: 100%;
  min-height: 250px;

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    min-height: 100px;
  }
`;

const IconButton = styled.div`
  position: fixed;
  z-index: 10;
  right: 95px;
  bottom: 60px;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  background-color: ${COLORS.yellowtech};
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: transform 0.2s ease-out;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    right: 30px;
    bottom: 30px;
    width: 64px;
    height: 64px;
  }
`;

const shakeAnimationMargin = keyframes`
  0% { transform: translate(1px, 1px) rotate(0deg); }
  0.71% { transform: translate(-1px, -2px) rotate(-1deg); }
  1.42% { transform: translate(-3px, 0px) rotate(1deg); }
  2.14% { transform: translate(3px, 2px) rotate(0deg); }
  2.85% { transform: translate(1px, -1px) rotate(1deg); }
  3.57% { transform: translate(-1px, 2px) rotate(-1deg); }
  4.28% { transform: translate(-3px, 1px) rotate(0deg); }
  5% { transform: translate(3px, 1px) rotate(-1deg); }
  5.7% { transform: translate(-1px, -1px) rotate(1deg); }
  6.42% { transform: translate(1px, 2px) rotate(0deg); }
  7.14% { transform: translate(1px, -2px) rotate(-1deg); }
`;

const IconButtonPhone = styled(IconButton)`
  background-image: url(${IconPhone});
  background-size: 33% auto;

  animation: ${shakeAnimationMargin} 7s ease-in-out infinite;
  
  &:hover{
    animation: none;
  }
`;
