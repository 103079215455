import React from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';

import { BREAKPOINTS, COLORS } from 'ui/constants';
import { Icon } from 'ui/atoms/Icon';
import { Container as ContainerDefault } from 'ui/atoms/Container';
import { StyledText as StyledTextDefault } from 'ui/atoms/StyledText';

export const MenuWarranty: React.FC = () => {
  const onCloseClick = () => {
    navigate('/');
  };

  const onBackClick = () => {
    navigate('/menu');
  };

  return (
    <Body>
      <ContainerDefault>
        <MainControls>
          <MainControlsBackButton onClick={onBackClick}>
            <Icon iconName="IconArrow" size={22} rotate={90} />
          </MainControlsBackButton>

          <MainControlsCloseButton onClick={onCloseClick}>
            Вернуться на главную
            <MainControlsCloseButtonIcon iconName="IconClose" size={18} />
          </MainControlsCloseButton>
        </MainControls>
      </ContainerDefault>

      <Container>
        <StyledText>
          <h2>Гарантийное обслуживание скважины</h2>

          <table>
            <tr>
              <th>Тип конструкции скважины</th>
              <th>Срок</th>
            </tr>
            <tr>
              <td>Труба Ø 133 - Стальная труба, сталь марка Ст-20, Диаметр 133 мм , толщина стенки 4,5 мм, труба шовная на резьбовом коническом соединении.</td>
              <td className="nowrap">5 лет</td>
            </tr>
            <tr>
              <td>Труба Ø 159 - Стальная труба, сталь марка Ст-20, Диаметр 159 мм, толщина стенки 4,5 мм, труба шовная на резьбовом коническом соединении.</td>
              <td className="nowrap">5 лет</td>
            </tr>
            <tr>
              <td>Труба Ø 133 + Пластиковая труба Ø 117 - Стальная труба внешняя, сталь марка Ст-20, Диаметр 133 мм , толщина стенки 4,5 мм, труба шовная на резьбовом коническом соединении + пластиковая труба ПНД внутренняя (полиэтилен низкого давления) д. 117 мм, толщина стенки 6,0 мм .</td>
              <td className="nowrap">10 лет</td>
            </tr>
            <tr>
              <td>Труба Ø 159 + Пластиковая труба Ø 125 - Стальная труба внешняя, сталь марка Ст-20, Диаметр 159 мм , толщина стенки 4,5 мм, труба шовная на резьбовом коническом соединении + пластиковая труба ПНД внутренняя (полиэтилен низкого давления) д. 125 мм, толщина стенки 6,0 мм.</td>
              <td className="nowrap">5 лет</td>
            </tr>
            <tr>
              <td>Труба Ø 125 - нПВХ,  (непластифицированный поливинилхлорид), Диаметр 125 мм , толщина стенки 6 мм, труба на резьбовом коническом соединении.</td>
              <td className="nowrap">5 лет</td>
            </tr>
          </table>

          <h3>ГАРАНТИЙНЫЙ РЕМОНТ</h3>
          <p>Установленное оборудование имеет гарантийные сроки. Гарантийный ремонт осуществляется на основании акта о причинах неисправности. «ГидроТех» берёт на себя расходы, связанные с их устранением. Под гарантийным ремонтом подразумеваются работы, проводимые при возникновении неисправностей или выхода из строя оборудования индивидуальной системы водоснабжения в течение срока гарантийного обслуживания.</p>
          <p>Компания «ГидроТех» предлагает услуги по гарантийному ремонту и сервисному обслуживанию: Скважина на известняк – от 5 года; На монтажные работы – 1 год. На оборудование и материалы - в соответствии с гарантийными сроками производителей, но не менее 1 года </p>

          <h3>НЕГАРАНТИЙНЫЕ ПРИЧИНЫ:</h3>
          <ul>
            <li>Несоблюдение руководства по эксплуатации;</li>
            <li>Вмешательство в работу оборудования постороннего лица;</li>
            <li>Самостоятельные действия заказчика по регулировке и ремонту, не оговоренные в инструкции по эксплуатации;</li>
            <li>Сбои в электроснабжении, отклонение напряжения электросети более чем на 10% от номинального значения (220 или 380 V).</li>
          </ul>

          <h3>СЕРВИСНОЕ ОБСЛУЖИВАНИЕ</h3>
          <p>Основная задача сервисной службы – это диагностика установленного оборудования, предупреждение возможных его поломок и оказание помощи заказчику в его эксплуатации. Для постановки смонтированного оборудования на сервисное обслуживание с заказчиком заключается договор, в котором оговариваются условия и сроки обслуживания, устанавливается размер абонентской платы.</p>
          <p>Под сервисным обслуживанием понимается проведение профилактических работ на объекте заказчика, предусмотренных изготовителем оборудования (осмотр, настройка и регулировка узлов автоматики) и устранение возможных дефектов. В случае необходимости, по согласованию с заказчиком, осуществляется замена изношенных или дефектных деталей и узлов новыми оригинальными деталями завода-изготовителя.</p>

          <h3>СТОИМОСТЬ СЕРВИСНОГО ОБСЛУЖИВАНИЯ</h3>
          <ul>
            <li>Разовый выезд специалиста на диагностику оборудования - от 3 500 руб.</li>
            <li>Разовый выезд специалиста на обслуживание - от 7 000 руб.</li>
            <li>Плановое годовое обслуживание (4 раза в год) - от 23800 руб.</li>
          </ul>
          <p>Согласно договору, фирма обязуется проводить профилактические работы не реже четырёх раз в год. Комплекс работ включает в себя профилактический осмотр, проверку и регулировку узлов управления, исполнительных органов системы водоснабжения, а также загрузку расходуемых материалов, предварительно оплаченных заказчиком.</p>

          <h3>ДОГОВОР НА ВЫПОЛНЕНИЕ РАБОТ:</h3>
          <p>Прибывший на Ваш участок инженера произведёт диагностику оборудования, составит акт осмотра или рекламационный акт. По результату осмотра и будут следовать дальнейшие действия - настройка, ремонт или гарантийный случай с устранением неполадки за счёт компании «ГидроТех».</p>
        </StyledText>
      </Container>
    </Body>
  );
};

const Body = styled.div`
  padding: 40px 0 80px;
`;

const Container = styled(ContainerDefault)`
  width: 720px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    width: 480px;
  }
`;

const StyledText = styled(StyledTextDefault)``;

const MainControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 80px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-bottom: 40px;
  }
`;

const MainControlsBackButton = styled.button`
  color: ${COLORS.blacktech};
  opacity: 0.65;
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 1;
  }
`;

const MainControlsCloseButtonIcon = styled(Icon)``;

const MainControlsCloseButton = styled.button`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  opacity: 0.65;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  opacity: 0.65;
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 1;
  }

  ${MainControlsCloseButtonIcon} {
    margin-left: 10px;
  }
`;
