import * as React from 'react';
import styled from 'styled-components';

import { navigate, RouteComponentProps } from '@reach/router';

import { useCalculations } from 'hooks/useCalculations';

import { ExplanationBreadcrumbsStep } from 'ui/molecules/ExplanationBreadcrumbs';
import { ExplanationControl } from 'ui/molecules/ExplanationControl';

import { formatCost, formatText } from 'utils/formatText';
import { Important, ListItem } from './atoms';
import { ExplanationStepTemplate } from './ExplanationStepTemplate';

export const ExplanationWaterSupply: React.FC<RouteComponentProps> = () => {
  const {
    calculations: { waterSupplyCost },
    explanationTexts: {
      waterSupply: { timing, procedures, materials },
    },
  } = useCalculations();

  const goToCalculator = () => {
    navigate('/calculator/water-supply/kesson');
  };

  const onCloseButtonClick = () => {
    navigate('/');
  };

  return (
    <ExplanationStepTemplate
      onCloseButtonClick={onCloseButtonClick}
      currentStep={ExplanationBreadcrumbsStep.waterSupply}
      goToCalculator={goToCalculator}
    >
      <CalculatorQuestion title="Срок исполнения">
        <Important>{timing === 1 ? '1 день' : '2 дня'}</Important>
      </CalculatorQuestion>
      <CalculatorQuestion title="Стоимость водоснабжения">
        <Important>{formatCost(waterSupplyCost)} руб.</Important>
      </CalculatorQuestion>
      <CalculatorQuestion title="Материалы и оборудование">
        {materials.map((s) => (
          <ListItem key={s}>{formatText(s)}</ListItem>
        ))}
      </CalculatorQuestion>
      <CalculatorQuestion title="Проводимые работы">
        <ListItem>{formatText(procedures)}</ListItem>
      </CalculatorQuestion>
    </ExplanationStepTemplate>
  );
};

const CalculatorQuestion = styled(ExplanationControl)``;
