export const COLORS = {
  yellowtech: '#ffa400',
  blacktech: '#222211',
  whitetech: '#fdfffc',
  navytech: '#28502e',
  lightgraytech: '#edefec',
  redtech: '#E83B24',
  bordertech: '#eaece7',
  transparent: 'transparent',
  secondaryBackground: '#f8faf7',
};

export const BREAKPOINTS = {
  phoneMax: 767,
  tabletMin: 768,
  tabletMax: 1151,
  laptopSmallMin: 1152,
  laptopSmallMax: 1365,
  laptopMin: 1366,
  laptopMax: 1600,
  desktopMin: 1601,
  desktopMax: 1919,
};
