import styled from 'styled-components';

import { BREAKPOINTS } from 'ui/constants';

export const Container = styled.div`
  width: 1380px;
  max-width: 100%;
  padding: 0 20px;
  margin: 0 auto;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    width: 690px;
  }
`;
