import { createEvent, createStore } from "effector";

const defaultState = {
  $orderFormVisible: false,
};

export const showOrderForm = createEvent();
export const hideOrderForm = createEvent();
export const $orderFormVisible = createStore(defaultState.$orderFormVisible)
  .on(showOrderForm, () => true)
  .on(hideOrderForm, () => false)
