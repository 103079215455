import {
  createEvent, restore, combine, forward,
} from "effector";
import {
  loadLocalStorageFx,
  saveLocalStorageFx,
} from "utils/localStorage";

const LOCAL_STORAGE_KEY = "sewerSystem";

export enum FamilyMembersCountType {
  p0 = 0,
  p3 = 3,
  p5 = 5,
  p6 = 6,
  p7 = 7,
  p8 = 8,
  p10 = 10,
};

export interface FamilyMembersCountOption {
  label: string,
  value: FamilyMembersCountType,
}

export const FAMILY_MEMBERS_COUNT_OPTIONS: FamilyMembersCountOption[] = [
  { label: '—', value: FamilyMembersCountType.p0 },
  { label: '1-3 человека', value: FamilyMembersCountType.p3 },
  { label: '3-5 человек', value: FamilyMembersCountType.p5 },
  { label: '5-6 человек', value: FamilyMembersCountType.p6 },
  { label: '6-7 человек', value: FamilyMembersCountType.p7 },
  { label: '7-8 человек', value: FamilyMembersCountType.p8 },
  { label: '9-10 человек', value: FamilyMembersCountType.p10 },
];

export enum AdditionalDrainageWellSizeType {
  w0 = 0,
  w1 = 1,
  w2 = 2,
  w3 = 3,
};

export const ADDITIONAL_DRAINAGE_WELL_SIZE_OPTIONS:
  { label: string, value: AdditionalDrainageWellSizeType }[] = [
    { label: 'Без колодца', value: AdditionalDrainageWellSizeType.w0 },
    { label: '1 кольцо', value: AdditionalDrainageWellSizeType.w1 },
    { label: '2 кольца', value: AdditionalDrainageWellSizeType.w2 },
    { label: '3 кольца', value: AdditionalDrainageWellSizeType.w3 },
  ];

loadLocalStorageFx(LOCAL_STORAGE_KEY);

export const resetSewerSystem = createEvent();

export const setFamilyMembersCount = createEvent<FamilyMembersCountType>();
export const $familyMembersCount = restore(
  setFamilyMembersCount, FAMILY_MEMBERS_COUNT_OPTIONS[0].value,
)
  .on(
    loadLocalStorageFx.doneData,
    (_, { familyMembersCount }) => familyMembersCount,
  )
  .reset(resetSewerSystem)

export const setDistanceToConnectionPoint = createEvent<number>();
export const $distanceToConnectionPoint = restore(setDistanceToConnectionPoint, 0)
  .on(
    loadLocalStorageFx.doneData,
    (_, { distanceToConnectionPoint }) => distanceToConnectionPoint,
  )
  .reset(resetSewerSystem)

export const setAdditionalDrainageWellSize = createEvent<AdditionalDrainageWellSizeType>();
export const $additionalDrainageWellSize = restore(
  setAdditionalDrainageWellSize, ADDITIONAL_DRAINAGE_WELL_SIZE_OPTIONS[0].value,
)
  .on(
    loadLocalStorageFx.doneData,
    (_, { additionalDrainageWellSize }) => additionalDrainageWellSize,
  )
  .reset(resetSewerSystem)

export const setDistanceToRainwaterSewerSystem = createEvent<number>();
export const $distanceToRainwaterSewerSystem = restore(setDistanceToRainwaterSewerSystem, 0)
  .on(
    loadLocalStorageFx.doneData,
    (_, { distanceToRainwaterSewerSystem }) => distanceToRainwaterSewerSystem,
  )
  .reset(resetSewerSystem)

export const $sewerSystem = combine({
  familyMembersCount: $familyMembersCount,
  distanceToConnectionPoint: $distanceToConnectionPoint,
  additionalDrainageWellSize: $additionalDrainageWellSize,
  distanceToRainwaterSewerSystem: $distanceToRainwaterSewerSystem,
});

forward({
  from: $sewerSystem,
  to: saveLocalStorageFx.prepend((value) => ({ value, localStorageKey: LOCAL_STORAGE_KEY })),
});
