import useFetch from 'react-fetch-hook';

export const API_ROOT = 'https://hydrotechpro.ru/apihost';

enum Endpoint {
  objects = 'objects',
  calculatorSettings = 'calculator-settings',
  faqs = 'faqs',
  faqPages = 'faq-pages',
  brandBanners = 'brand-banners',
  documents = 'documents',
  reviews = 'reviews',
  boringPrices = 'boring-prices',
  sewerSystemPrices = 'sewer-system-prices',
}

const useApiCall = <T>(endpoint: string) => useFetch<T>(`${API_ROOT}/${endpoint}`);

interface Image {
  id: number;
  url: string;
}

interface Object {
  id: number;
  objectNumber: number;
  name: string;
  feedbackText: string;
  feedbackLink: string | null;
  district: string;
  wasBoring: boolean;
  wasWaterSupply: boolean;
  wasSewerSystem: boolean;
  photos: Image[];
  boringCost: number;
  boringDuration: number;
  boringInstallation: string;
  boringProcedures: string;
  waterSupplyCost: number;
  waterSupplyDuration: number;
  waterSupplyInstallation: string;
  waterSupplyProcedures: string;
  sewerSystemCost: number;
  sewerSystemDuration: number;
  sewerSystemInstallation: string;
  sewerSystemProcedures: string;
}

export const useObjectsApi = () => useApiCall<Object[]>(Endpoint.objects);
export const useObjectApi = (id: string) => useApiCall<Object>(`${Endpoint.objects}/${id}`);

export interface FaqI {
  id: number;
  created_at: string;
  updated_at: string;
  faqPage: number;
  title: string;
  faqText: string;
}

export interface FaqPageI {
  id: number;
  pageName: string;
  faqs: FaqI[];
  created_at: string;
  updated_at: string;
}

export const useFaqPages = () => useApiCall<FaqPageI[]>(Endpoint.faqPages);

export interface BrandBannerI {
  id: number;
  title: string;
  subtitle: string;
  text: string;
  previewImg: Image;
  order: number;
}

export const useBrandBanners = () => useApiCall<BrandBannerI[]>(Endpoint.brandBanners);

export interface FileI {
  ext: string;
  size: number;
  url: string;
}

export interface DocumentI {
  id: number;
  name: string;
  type: 'document' | 'price';
  file: FileI[];
}

export const useDocuments = () => useApiCall<DocumentI[]>(Endpoint.documents);

export interface ReviewI {
  id: number;
  name: string;
  object?: Object;
  message?: string;
  photo?: Image;
}

export const useReviews = () => useApiCall<ReviewI[]>(Endpoint.reviews);

export interface BoringPriceI {
  id: number;
  region: string;
  depth: string;
  t1: string;
  t2: string;
  t3: string;
  t4: string;
  t5: string;
}

export const useBoringPrices = () => useApiCall<BoringPriceI[]>(`${Endpoint.boringPrices}?_sort=id:ASC`);

export interface SewerSystemPriceI {
  id: number;
  label: string;
  value: string;
  tableType:
    | 'autonomous_sewerage_station_models'
    | 'autonomous_sewerage_additionally_1'
    | 'autonomous_sewerage_additionally_2'
    | 'construction_of_wells_type_of_arrangement'
    | 'construction_of_wells_additionally_1'
    | 'construction_of_wells_work'
    | 'draining_well_type_of_arrangement';
}

export const useSewerSystemPrices = () => useApiCall<SewerSystemPriceI[]>(`${Endpoint.sewerSystemPrices}?_sort=id:ASC`);
