import React from 'react';

import { RouteComponentProps } from "@reach/router";

import { MenuFAQ } from "ui/organisms/MenuFAQ";
import { useFaqPages } from "hooks/useApiCall";

interface FaqProps extends RouteComponentProps {
  faqPageId?: string,
  faqId?: string,
}

export const FAQ = ({
  faqPageId,
  faqId,
}: FaqProps) => {
  const pageId = Number(faqPageId) || null;
  const faqItemId = Number(faqId) || null;

  const { data: faqPagesData, isLoading } = useFaqPages();

  const selectedPage = faqPagesData?.find(({ id }) => id === pageId) || faqPagesData?.[0];

  const menuItems = faqPagesData?.map((page) => ({
    ...page,
    selected: page.id === selectedPage?.id,
  }));

  const selectedFaq = selectedPage?.faqs?.find(({ id }) => id === faqItemId)
    || selectedPage?.faqs[0];

  const faqs = selectedPage?.faqs.map((faq) => ({
    ...faq,
    selected: faq.id === selectedFaq?.id,
  }));

  return (!isLoading && menuItems && faqs && selectedPage) ? (
    <MenuFAQ
      menuItems={menuItems}
      faqs={faqs}
      selectedPage={selectedPage}
    />
  ) : null;
};
