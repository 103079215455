import * as React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';
import { rgba } from 'polished';
import { Icon } from 'ui/atoms/Icon';
import { BREAKPOINTS, COLORS } from 'ui/constants';

export enum CalculatorBreadcrumbsStep {
  boring,
  waterSupply,
  sewerSystem,
}

interface CalculatorBreadcrumbsProps {
  currentStep: CalculatorBreadcrumbsStep;
}

export const CalculatorBreadcrumbs: React.FC<CalculatorBreadcrumbsProps> = ({ currentStep = 0 }) => (
  <Breadcrumbs>
    <Breadcrumb active={currentStep === 0} passed={currentStep >= 0}>
      <BreadcrumbLink to="/calculator/">Бурение</BreadcrumbLink>
      <Icon size={12} iconName="IconDropdown" />
    </Breadcrumb>
    <Breadcrumb active={currentStep === 1} passed={currentStep >= 1}>
      <BreadcrumbLink to="/calculator/water-supply">Водоснабжение</BreadcrumbLink>
      <Icon size={12} iconName="IconDropdown" />
    </Breadcrumb>
    <Breadcrumb active={currentStep === 2} passed={currentStep >= 2}>
      <BreadcrumbLink to="/calculator/sewer-system">Канализация</BreadcrumbLink>
    </Breadcrumb>
  </Breadcrumbs>
);

const Breadcrumbs = styled.ul``;

const Breadcrumb = styled.li<{ active: boolean; passed: boolean }>`
  display: inline-flex;
  font-size: 20px;
  font-weight: ${(p) => (p.active ? 450 : 300)};
  color: ${(p) => (p.passed ? COLORS.blacktech : rgba(COLORS.blacktech, 0.45))};
  align-items: center;

  span {
    margin: 0 24px;

    & > svg {
      display: block;
    }

    @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
      margin: 0 10px;
    }
  }

  @media (max-width: ${BREAKPOINTS.desktopMax}px) {
    font-size: 18px;
  }

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    font-size: 16px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    line-height: 28px;
  }
`;

const BreadcrumbLink = styled(Link)`
  font-weight: inherit;
  color: inherit;
`;
