import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper.scss';
import { navigate } from '@reach/router';

import { BREAKPOINTS, COLORS } from 'ui/constants';
import { Container as ContainerDefault } from 'ui/atoms/Container';
import { Icon } from 'ui/atoms/Icon';

import IconChevronRight from './static/icon-chevron-right.svg';
import IconChevronLeft from './static/icon-chevron-left.svg';
import ImgCertificate1 from './static/Aqua_Center_Hydrotech.jpg';
import ImgCertificate2 from './static/Aquario_Hydrotech.jpg';
import ImgCertificate3 from './static/Eco_Grand_Hydrotech.jpg';
import ImgCertificate4 from './static/TZPT_Hydrotech.jpg';
import ImgCertificate5 from './static/Unilos_Hydrotech.jpg';
import ImgCertificate6 from './static/Vologar_Hydrotech.jpg';

SwiperCore.use([Navigation, Pagination]);

export const MenuCertificates: React.FC = () => {
  const sliderSettings: Swiper = {
    slidesPerView: 3,
    spaceBetween: 100,
    navigation: {
      nextEl: `.${ArrowNext.styledComponentId}`,
      prevEl: `.${ArrowPrev.styledComponentId}`,
    },
    pagination: {
      el: `.${PreviewSliderCounter.styledComponentId}`,
      type: 'fraction',
      renderFraction(currentClass, totalClass) {
        return `<span class="${currentClass}"></span><span class="${totalClass}"></span>`;
      },
    },
  };

  const onCloseClick = () => {
    navigate('/');
  };

  const onBackClick = () => {
    navigate('/menu');
  };

  return (
    <Wrapper>
      <Container>
        <MainControls>
          <MainControlsBackButton onClick={onBackClick}>
            <Icon iconName="IconArrow" size={22} rotate={90} />
          </MainControlsBackButton>

          <MainControlsCloseButton onClick={onCloseClick}>
            Вернуться на главную
            <MainControlsCloseButtonIcon iconName="IconClose" size={18} />
          </MainControlsCloseButton>
        </MainControls>

        <Body>
          <Controls>
            <Arrows>
              <ArrowPrev />
              <ArrowNext />
            </Arrows>

            <PreviewSliderCounter />
          </Controls>

          <Main>
            <Slider {...sliderSettings}>
              <SwiperSlide>
                <SliderItem>
                  <SliderItemImg src={ImgCertificate3} />
                </SliderItem>
              </SwiperSlide>

              <SwiperSlide>
                <SliderItem>
                  <SliderItemImg src={ImgCertificate2} />
                </SliderItem>
              </SwiperSlide>

              <SwiperSlide>
                <SliderItem>
                  <SliderItemImg src={ImgCertificate5} />
                </SliderItem>
              </SwiperSlide>

              <SwiperSlide>
                <SliderItem>
                  <SliderItemImg src={ImgCertificate1} />
                </SliderItem>
              </SwiperSlide>

              <SwiperSlide>
                <SliderItem>
                  <SliderItemImg src={ImgCertificate4} />
                </SliderItem>
              </SwiperSlide>

              <SwiperSlide>
                <SliderItem>
                  <SliderItemImg src={ImgCertificate6} />
                </SliderItem>
              </SwiperSlide>
            </Slider>

            <SimpleList>
              <SliderItem>
                <SliderItemImg src={ImgCertificate3} />
              </SliderItem>
              <SliderItem>
                <SliderItemImg src={ImgCertificate2} />
              </SliderItem>
              <SliderItem>
                <SliderItemImg src={ImgCertificate5} />
              </SliderItem>
              <SliderItem>
                <SliderItemImg src={ImgCertificate1} />
              </SliderItem>
              <SliderItem>
                <SliderItemImg src={ImgCertificate4} />
              </SliderItem>
              <SliderItem>
                <SliderItemImg src={ImgCertificate6} />
              </SliderItem>
            </SimpleList>
          </Main>
        </Body>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 40px 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Container = styled(ContainerDefault)`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Body = styled.div`
  display: flex;
  margin-bottom: auto;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-bottom: 0;
  }
`;

const Controls = styled.div`
  flex: 0 0 170px;
  width: 170px;
  max-width: 170px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    display: none;
  }
`;

const Arrows = styled.div``;

const Arrow = styled.div`
  width: 96px;
  height: 96px;
  border-radius: 999px;
  background-color: transparent;
  background-position: center;
  background-size: 30% auto;
  background-repeat: no-repeat;
  border: solid 1px #c3c4bd;
  margin-bottom: 20px;
  opacity: 0.65;
  transition: opacity 0.2s ease-out;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    opacity: 1;
  }

  &.swiper-button-disabled {
    opacity: 0.3 !important;
  }
`;

const ArrowPrev = styled(Arrow)`
  background-image: url(${IconChevronLeft});
`;

const ArrowNext = styled(Arrow)`
  background-image: url(${IconChevronRight});
`;

const PreviewSliderCounter = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 1;
  display: flex;
  justify-content: space-between;
  width: 70px;
  height: 76px;
  margin-top: auto;

  &::before {
    content: '';
    display: block;
    width: 65px;
    height: 1px;
    background-color: #9a9c92;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  .swiper-pagination-current {
    align-self: flex-end;
    font-weight: 700;
  }

  .swiper-pagination-total {
    align-self: flex-start;
  }
`;

const Main = styled.div`
  flex: 0 0 calc(100% - 170px);
  width: calc(100% - 170px);
  max-width: calc(100% - 170px);

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
`;

const Slider = styled(Swiper)`
  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    display: none;
  }
`;

const SliderItem = styled.div``;

const SliderItemImg = styled.img`
  display: block;
  width: 100%;
`;

const SimpleList = styled.div`
  @media (min-width: ${BREAKPOINTS.laptopSmallMin}px) {
    display: none;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    width: 430px;
    max-width: 100%;
    margin: 0 auto;
  }

  ${SliderItem} {
    @media (max-width: ${BREAKPOINTS.tabletMax}px) {
      margin-bottom: 40px;
    }

    &:last-child {
      @media (max-width: ${BREAKPOINTS.tabletMax}px) {
        margin-bottom: 0;
      }
    }
  }
`;

const MainControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: auto;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-bottom: 40px;
  }
`;

const MainControlsBackButton = styled.button`
  color: ${COLORS.blacktech};
  opacity: 0.65;
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 1;
  }
`;

const MainControlsCloseButtonIcon = styled(Icon)``;

const MainControlsCloseButton = styled.button`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  opacity: 0.65;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  opacity: 0.65;
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 1;
  }

  ${MainControlsCloseButtonIcon} {
    margin-left: 10px;
  }
`;
