import React, { useState } from 'react';
import styled from 'styled-components';
import { lighten, rgba } from 'polished';
import { Router, Link, useLocation, navigate } from '@reach/router';
import orderBy from 'lodash/orderBy';

import { showCallbackForm } from 'stores/callbackForm';
import { BREAKPOINTS, COLORS } from 'ui/constants';
import { ButtonGhost, ButtonIcon } from 'ui/atoms/Button';
import { Icon } from 'ui/atoms/Icon';
import { MenuMobilePrimary, MenuMobileContacts, MenuMobileAbout } from 'ui/organisms/MenuMobile';
import * as СontractItem from 'ui/atoms/СontractItem';
import { MenuContacts } from './MenuContacts';
import { MenuBoring } from './MenuBoring';
import { MenuWaterSupplyLetnik } from './MenuWaterSupplyLetnik';
import { MenuWaterSupplyKesson } from './MenuWaterSupplyKesson';
import { MenuWaterSupplyAdapter } from './MenuWaterSupplyAdapter';
import { MenuSewerSystem } from './MenuSewerSystem';
import { PriceListBoring } from '../PriceList/PriceListBoring';
import { PriceListSewerSystem } from '../PriceList/PriceListSewerSystem';
import { useWindowSize } from 'hooks/useWindowSize';
import { useDocuments, API_ROOT, DocumentI } from 'hooks/useApiCall';

import ImgBackgroundPrimary from './static/background-primary.svg';
import IconClose from './static/icon-close.svg';
import IconCloseSmall from './static/icon-close-small.svg';

export const Menu = () => {
  const { pathname } = useLocation();
  const windowSize = useWindowSize();
  let { data: documents } = useDocuments();
  documents = documents?.length ? orderBy(documents, 'id', 'desc') : documents;

  const [pricesExpanded, setPricesExpanded] = useState(false);
  const togglePrices = () => {
    setContractsExpanded(false);
    setPricesExpanded((v) => !v);
  };

  const [contractsExpanded, setContractsExpanded] = useState(false);
  const toggleContracts = () => {
    setPricesExpanded(false);
    setContractsExpanded((v) => !v);
  };

  const onCloseClick = () => {
    navigate('/');
  };

  const documentsJSX = (type: DocumentI['type']) => {
    if (!Array.isArray(documents) || !documents.length) return;

    const documentsByType = documents.filter((i) => i.type === type);
    return documentsByType.map((document) => {
      if (!Array.isArray(document.file) || !document.file.length) return null;
      const file = document.file[0];

      return (
        <СontractItem.Item>
          <СontractItem.Name>{document.name}</СontractItem.Name>
          <СontractItem.Meta>{`${file.ext.toUpperCase()}, ${file.size} Кб`}</СontractItem.Meta>

          <СontractItem.DownloadButton href={`${API_ROOT}${file.url}`} target="_blank" />
        </СontractItem.Item>
      );
    });
  };

  const isContacts = pathname.startsWith('/menu/contacts') || pathname === '/menu' || pathname === '/menu/';
  const showAsideInTablet =
    pathname.startsWith('/menu/contacts') ||
    pathname.startsWith('/menu/prices') ||
    pathname === '/menu' ||
    pathname === '/menu/';

  return (
    <Body height={windowSize.height}>
      <Controls>
        <CloseButton onClick={onCloseClick} />

        <ControlsContactUsButton
          onClick={() => {
            showCallbackForm();
          }}
        >
          Связаться с нами
          <ButtonIcon>
            <Icon iconName="IconArrow45" size={18} rotate={90} />
          </ButtonIcon>
        </ControlsContactUsButton>
      </Controls>

      <Aside isContacts={isContacts} showAsideInTablet={showAsideInTablet}>
        <AsideInner>
          <NavPrimary>
            <NavPrimaryLink to="/menu/boring" active={pathname.startsWith('/menu/boring')}>
              Бурение скважин
            </NavPrimaryLink>
            <NavPrimaryLink to="/menu/water-supply/kesson" active={pathname.startsWith('/menu/water-supply')}>
              Водоснабжение
            </NavPrimaryLink>
            <NavPrimaryLink to="/menu/sewer-system" active={pathname.startsWith('/menu/sewer-system')}>
              Канализация
            </NavPrimaryLink>
          </NavPrimary>

          <NavSecondary>
            <DropLink>
              <DropLinkLabel active={pricesExpanded} onClick={togglePrices}>
                Прайс лист
              </DropLinkLabel>

              {pricesExpanded && (
                <DropLinkDropdown>
                  <СontractItem.Item as={Link} to="/menu/prices">
                    <СontractItem.Name>Бурение скважины</СontractItem.Name>
                  </СontractItem.Item>
                  <СontractItem.Item as={Link} to="/menu/prices/sewer-system">
                    <СontractItem.Name>Обустройство и канализация</СontractItem.Name>
                  </СontractItem.Item>
                </DropLinkDropdown>
              )}
            </DropLink>

            <NavSecondaryLink to="/warranty">Гарантийное обслуживание</NavSecondaryLink>
            <NavSecondaryLink to="/certificates">Сертификаты</NavSecondaryLink>

            <DropLink>
              <DropLinkLabel active={contractsExpanded} onClick={toggleContracts}>
                Шаблоны договоров
              </DropLinkLabel>

              {contractsExpanded && <DropLinkDropdown>{documentsJSX('document')}</DropLinkDropdown>}
            </DropLink>

            <NavSecondaryLink to="/faq">Вопросы и ответы</NavSecondaryLink>
          </NavSecondary>

          <Contacts>
            <ContactsLink to="/menu/contacts" active={pathname.startsWith('/menu/contacts') || pathname === '/menu'}>
              Наши контакты
            </ContactsLink>
          </Contacts>

          <Meta>
            <Copyright>©{new Date().getFullYear()} ООО “Гидротех”. Все права защищены</Copyright>
            <Socials>
              <SocialsItem target="_blank" href="https://www.facebook.com/1891168824535203">
                Фейсбук
              </SocialsItem>
              <SocialsItem target="_blank" href="https://www.instagram.com/hydrotechpro/">
                Инстаграм
              </SocialsItem>
              <SocialsItem target="_blank" href="https://vk.com/hydrotechpro">
                Вконтакте
              </SocialsItem>
              <SocialsItem target="_blank" href="https://www.youtube.com/channel/UC9GICyJoMn1MuDKznnPLwKA">
                Ютуб
              </SocialsItem>
            </Socials>
          </Meta>
        </AsideInner>
      </Aside>

      <MobileMenu>
        <MenuMobilePrimary path="/" />
        <MenuMobileAbout path="about" />
        <MenuMobileContacts path="contacts" />
        <PriceListBoring path="prices" />
        <PriceListSewerSystem path="prices/sewer-system" />
      </MobileMenu>

      <Main>
        <MenuContacts path="/" />
        <MenuContacts path="contacts" />
        <PriceListBoring path="prices" />
        <PriceListSewerSystem path="prices/sewer-system" />
      </Main>

      <MainInfo>
        <MenuBoring path="boring" />
        <MenuWaterSupplyLetnik path="water-supply/letnik" />
        <MenuWaterSupplyKesson path="water-supply/kesson" />
        <MenuWaterSupplyAdapter path="water-supply/adapter" />
        <MenuSewerSystem path="sewer-system" />
      </MainInfo>
    </Body>
  );
};

const Body = styled.div<{ height: number }>`
  position: relative;
  height: ${({ height }) => `${height}px`};
  max-height: -moz-available; /* WebKit-based browsers will ignore this. */
  max-height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  max-height: fill-available;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  background-image: url(${ImgBackgroundPrimary});
  background-position: center;
  background-size: cover;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

const Controls = styled.div`
  position: absolute;
  left: 0;
  top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 6vw;

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    padding: 20px 4vw;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    padding: 20px 60px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    display: none;
  }
`;

const CloseButton = styled.button`
  width: 22px;
  height: 22px;
  background: transparent url(${IconClose}) center no-repeat;
  background-size: contain;
  opacity: 0.65;
  transition: opacity 0.2s ease-out;

  @media (min-width: ${BREAKPOINTS.desktopMin}px) {
    width: 34px;
    height: 34px;
  }

  &:hover {
    opacity: 1;
  }
`;

const ControlsContactUsButton = styled(ButtonGhost)`
  @media (min-width: ${BREAKPOINTS.laptopSmallMin}px) {
    display: none;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-left: auto;
  }

  ${ButtonIcon} {
    margin-left: 10px;
  }
`;

const Aside = styled.div<{ isContacts?: boolean; showAsideInTablet?: boolean }>`
  flex: 0 0 38%;
  width: 38%;
  max-width: 38%;
  padding: 110px 20px 70px 6vw;
  background-color: ${({ isContacts }) => (isContacts ? rgba(COLORS.blacktech, 0.02) : '')};
  display: flex;
  flex-direction: column;

  @media (min-width: ${BREAKPOINTS.desktopMin}px) {
    flex: 0 0 33%;
    width: 33;
    max-width: 33%;
  }

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    padding: 80px 20px 40px 4vw;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    padding: 120px 60px 60px;
  }

  @media (min-width: ${BREAKPOINTS.tabletMin}px) and (max-width: ${BREAKPOINTS.tabletMax}px) {
    display: ${(p) => (p.showAsideInTablet ? '' : 'none')};
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    display: none;
  }
`;

const AsideInner = styled.div`
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto auto 1fr;
  grid-template-areas:
    'navPrimary'
    'navSecondary'
    'contacts'
    'meta';
  overflow-x: hidden;
  overflow-y: auto;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    grid-template-columns: 60% 40%;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'navPrimary navSecondary'
      'contacts navSecondary'
      'meta meta';
    overflow: auto;
  }
`;

const Main = styled(Router)`
  position: relative;
  z-index: 1;
  flex: 0 0 62%;
  width: 62%;
  max-width: 62%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    overflow: visible;
    max-height: none;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    display: none;
  }
`;

const MainInfo = styled(Router)`
  position: relative;
  z-index: 10;
  flex: 0 0 62%;
  width: 62%;
  max-width: 62%;
  overflow-y: auto;
  overflow-x: hidden;

  @media (min-width: ${BREAKPOINTS.desktopMin}px) {
    flex: 0 0 67%;
    width: 67%;
    max-width: 67%;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
`;

const NavLink = styled(Link)<{ active?: boolean }>`
  color: ${({ active }) => (active ? `${COLORS.yellowtech} !important` : COLORS.blacktech)};
  transition: color 0.2s ease-out, transform 0.2s ease-out, margin-left 0.2s ease-out;
  
  
  &:hover {
    margin-left: ${({ active }) => (active ? '0' : '2px')};
    color: ${lighten(0.15, COLORS.blacktech)};
  }
`;

const NavPrimary = styled.div`
  grid-area: navPrimary;
  display: grid;
  justify-items: start;
  grid-gap: 18px;
`;

const NavPrimaryLink = styled(NavLink)`
  font-size: 26px;

  @media (min-width: ${BREAKPOINTS.desktopMin}px) {
    font-size: 32px;
  }
  
  &:active {
    transform: translateY(2px);
  }
`;

const NavSecondary = styled.div`
  margin-top: 50px;
  grid-area: navSecondary;
  display: grid;
  justify-items: start;
  grid-gap: 15px;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-top: 0;
    align-self: start;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    display: none;
  }
`;

const NavSecondaryLink = styled(NavLink)`
  font-size: 16px;

  @media (min-width: ${BREAKPOINTS.desktopMin}px) {
    font-size: 20px;
  }
`;

const Contacts = styled.div`
  margin-top: 40px;
  grid-area: contacts;
  display: grid;
  justify-items: start;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    margin-top: 50px;
  }
`;

const ContactsLink = styled(NavLink)`
  font-size: 24px;

  @media (min-width: ${BREAKPOINTS.desktopMin}px) {
    font-size: 28px;
  }
`;

const Meta = styled.div`
  margin-top: 40px;
  grid-area: meta;
  align-self: end;

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    display: none;
  }
`;

const Copyright = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  opacity: 0.45;
`;

const Socials = styled.div`
  margin-top: 15px;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(4, auto);
  justify-content: start;
`;

const SocialsItem = styled.a`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${COLORS.blacktech};
  transition: color 0.2s ease-out, transform 0.2s ease-out;

  &:hover {
    color: ${lighten(0.15, COLORS.blacktech)};
    transform: translateY(-1px);
  }
`;

const MobileMenu = styled(Router)`
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;

  @media (min-width: ${BREAKPOINTS.tabletMin}px) {
    display: none;
  }
`;

const DropLink = styled.div`
  position: relative;
`;

const DropLinkLabel = styled.button<{ active?: boolean }>`
  font-size: 16px;
  color: ${({ active }) => (active ? COLORS.yellowtech : COLORS.blacktech)};
  padding-right: 30px;

  @media (min-width: ${BREAKPOINTS.desktopMin}px) {
    font-size: 20px;
  }

  &::after {
    content: '';
    display: ${({ active }) => (active ? 'block' : 'none')};
    width: 18px;
    height: 18px;
    background: transparent url(${IconCloseSmall}) center no-repeat;
    background-size: contain;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const DropLinkDropdown = styled(СontractItem.List)`
  position: absolute;
  z-index: 10;
  top: 40px;
  left: 0;
  width: 355px;
  max-height: 200px;
  box-shadow: 0 15px 25px ${rgba('#0a0908', 0.05)};
  background-color: #fff;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    width: 300px;
  }
`;
