import * as React from 'react';
import styled from "styled-components";
import { useStore } from "effector-react";

import { navigate, RouteComponentProps } from "@reach/router";

import {
  FAMILY_MEMBERS_COUNT_OPTIONS,
  ADDITIONAL_DRAINAGE_WELL_SIZE_OPTIONS,
  FamilyMembersCountType,
  AdditionalDrainageWellSizeType,
  $familyMembersCount,
  setFamilyMembersCount,
  $distanceToConnectionPoint,
  setDistanceToConnectionPoint,
  $additionalDrainageWellSize,
  setAdditionalDrainageWellSize,
  $distanceToRainwaterSewerSystem,
  setDistanceToRainwaterSewerSystem,
  resetSewerSystem,
} from "stores/sewerSystem";
import { CalculatorBreadcrumbsStep } from "ui/molecules/CalculatorBreadcrumbs";
import { NumberInput } from "ui/atoms/Input";
import { DropdownSelect } from "ui/molecules/DropdownSelect";
import { CalculatorControl } from "ui/molecules/CalculatorControl";
import { CalculatorStepTemplate } from "./CalculatorStepTemplate";

export const CalculatorSewerSystem: React.FC<RouteComponentProps> = () => {
  const familyMembersCount = useStore($familyMembersCount);
  const distanceToConnectionPoint = useStore($distanceToConnectionPoint);
  const additionalDrainageWellSize = useStore($additionalDrainageWellSize);
  const distanceToRainwaterSewerSystem = useStore($distanceToRainwaterSewerSystem);

  const goToNextStep = () => { navigate('/calculator/details') };

  const skipStep = () => {
    resetSewerSystem();
    goToNextStep();
  };

  const onCloseButtonClick = () => { navigate('/'); };

  return (
    <CalculatorStepTemplate
      currentStep={CalculatorBreadcrumbsStep.sewerSystem}
      goToNextStep={goToNextStep}
      skipStep={skipStep}
      onCloseButtonClick={onCloseButtonClick}
    >
      <CalculatorQuestion
        title="В доме одновременно будет проживать"
      >
        <DropdownSelect<FamilyMembersCountType>
          value={familyMembersCount}
          options={FAMILY_MEMBERS_COUNT_OPTIONS}
          onChange={setFamilyMembersCount}
        />
      </CalculatorQuestion>
      <CalculatorQuestion
        title="Планируемое расстояние от дома до канализации"
      >
        <NumberInput
          value={distanceToConnectionPoint}
          onChange={setDistanceToConnectionPoint}
        />
      </CalculatorQuestion>
      <CalculatorQuestion
        title="Дополнительный дренажный колодец"
      >
        <DropdownSelect<AdditionalDrainageWellSizeType>
          value={additionalDrainageWellSize}
          options={ADDITIONAL_DRAINAGE_WELL_SIZE_OPTIONS}
          onChange={setAdditionalDrainageWellSize}
        />
      </CalculatorQuestion>
      <CalculatorQuestion
        title="Планируемое расстояние от канализации до слива"
      >
        <NumberInput
          value={distanceToRainwaterSewerSystem}
          onChange={setDistanceToRainwaterSewerSystem}
        />
      </CalculatorQuestion>
    </CalculatorStepTemplate>
  );
};

const CalculatorQuestion = styled(CalculatorControl)`
  :not(:first-of-type) {
    margin-top: 20px;
  }
`;
