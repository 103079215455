import * as React from 'react';
import { Router, RouteComponentProps } from "@reach/router";

import { ExplanationSewerSystem } from "./ExplanationSewerSystem";
import { ExplanationBoring } from "./ExplanationBoring";
import { ExplanationWaterSupply } from "./ExplanationWaterSupply";

export const Explanation: React.FC<RouteComponentProps> = () => (
  <Router>
    <ExplanationBoring path="/" />
    <ExplanationWaterSupply path="water-supply" />
    <ExplanationSewerSystem path="sewer-system" />
  </Router>
);
