import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from '@reach/router';

import { BREAKPOINTS } from 'ui/constants';
import { MenuContent } from 'ui/templates/MenuContent';
import { MenuInformationPic } from 'ui/molecules/MenuInformationPic';
import { ToolTipDot, ToolTipText, ToolTipGuide } from 'ui/atoms/IllustrationToolTip';

import SewerSystemBackURL from 'ui/molecules/MenuInformationPic/static/sewer-system-bg.jpg';
import SewerSystemFrontURL from 'ui/molecules/MenuInformationPic/static/sewer-system-fg.png';
import { ReactComponent as SewerSystemC1 } from 'ui/molecules/MenuInformationPic/static/sewer-system-c1.svg';
import { ReactComponent as SewerSystemC2 } from 'ui/molecules/MenuInformationPic/static/sewer-system-c2.svg';
import { ReactComponent as SewerSystemC3 } from 'ui/molecules/MenuInformationPic/static/sewer-system-c3.svg';

import BGGuideLine5 from './static/guide-line-05.svg';
import BGGuideLine6 from './static/guide-line-06.svg';
import BGGuideLine7 from './static/guide-line-07.svg';

export const MenuSewerSystem: React.FC<RouteComponentProps> = () => (
  <MenuContent pic={<SewerSystemPic />}>
    <Text>
      Наиболее эффективными канализационными системами являются станции глубокой биологической очистки стоков. Одни из
      наиболее популярных станций – автономная канализация «Астра» и «Топас». Они обеспечивают сбор и очистку стоков,
      которые затем можно безо всяких опасений сливать на грунт.
    </Text>
  </MenuContent>
);

export const SewerSystemPic = () => (
  <MenuInformationPic backgroundURL={SewerSystemBackURL} foregroundURL={SewerSystemFrontURL}>
    <SewerSystemC1Styled />
    <SewerSystemC2Styled />
    <SewerSystemC3Styled />

    <ToolTipDot5>
      <ToolTipText5>
        <p>Септик состоит из нескольких камер, жидкость в которых переливается по системе эрлифтов.</p>
        <p>
          <strong>В отсеке №1</strong> канализационные воды отстаиваются и выпадают в осадок крупных фракций.
        </p>
        <p>
          <strong>В отсеке №2</strong> участвуют биоактивные бактерии, перерабатывающие органические вещества.
        </p>
        <p>
          <strong>Отсек №3</strong> - это вторичный отстойник.
        </p>
        <p>
          <strong>Отсек №4</strong> – отстойник, в котором происходит очищение стоков до состояния технической воды со
          степенью очистки более 90%.
        </p>
      </ToolTipText5>
      <ToolTipGuide5 />
    </ToolTipDot5>

    <ToolTipDot6>
      <ToolTipText6>
        Из септика, чистая вода попадает вфильтрующий колодец, на дне которогорасположен гравий.
      </ToolTipText6>
      <ToolTipGuide6 />
    </ToolTipDot6>

    <ToolTipDot7>
      <ToolTipText7>
        Остаток воды, который не впитал гравий, отводится через дополнительную трубу в канаву или пруд.
      </ToolTipText7>
      <ToolTipGuide7 />
    </ToolTipDot7>
  </MenuInformationPic>
);

const SewerSystemC1Styled = styled(SewerSystemC1)`
  animation-delay: 0s;
  top: 40%;
  left: 62%;
  width: calc(164 / 1008 * 100%);
  height: calc(101 / 510 * 100%);
`;

const SewerSystemC2Styled = styled(SewerSystemC2)`
  animation-delay: 0.2s;
  top: 58%;
  left: 69%;
  width: calc(120 / 1008 * 100%);
  height: calc(75 / 510 * 100%);
`;

const SewerSystemC3Styled = styled(SewerSystemC3)`
  animation-delay: 0.4s;
  top: 68%;
  left: 82%;
  width: calc(60 / 1008 * 100%);
  height: calc(38 / 510 * 100%);
`;

const Text = styled.p`
  font-size: 16px;
  width: 390px;
  max-width: 100%;

  @media (min-width: ${BREAKPOINTS.desktopMin}px) {
    font-size: 18px;
    width: 520px;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 18px;
    line-height: 1.4;
    width: 100%;
  }
`;

const LocalToolTipDot = styled(ToolTipDot)`
  @media (max-width: ${BREAKPOINTS.laptopMax}px) {
    transform: scale(0.9);
  }

  @media (max-width: ${BREAKPOINTS.laptopSmallMax}px) {
    transform: scale(0.62);
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    transform: scale(0.8);
  }
`;

const ToolTipDot5 = styled(LocalToolTipDot)`
  left: 53%;
  top: 41%;
`;

const ToolTipText5 = styled(ToolTipText)`
  width: 361px;
  height: 296px;
  clip-path: polygon(100% 0, 100% 85%, 90% 100%, 0 100%, 0 0);
  bottom: -125px;
  right: 148px;
`;

const ToolTipGuide5 = styled(ToolTipGuide)`
  background-image: url(${BGGuideLine5});
  width: 153px;
  height: 150px;
  bottom: 0;
  right: 0;
`;

const ToolTipDot6 = styled(LocalToolTipDot)`
  left: 62.5%;
  top: 60%;
`;

const ToolTipText6 = styled(ToolTipText)`
  width: 303px;
  height: 102px;
  clip-path: polygon(100% 0, 100% 60%, 85% 100%, 0 100%, 0 0);
  top: 80px;
  right: 148px;
`;

const ToolTipGuide6 = styled(ToolTipGuide)`
  background-image: url(${BGGuideLine6});
  width: 155px;
  height: 102px;
  top: 0;
  right: 0;
`;

const ToolTipDot7 = styled(LocalToolTipDot)`
  left: 85%;
  top: 72%;
`;

const ToolTipText7 = styled(ToolTipText)`
  width: 315px;
  height: 102px;
  clip-path: polygon(100% 0, 100% 60%, 85% 100%, 0 100%, 0 0);
  top: 50px;
  right: 45px;
`;

const ToolTipGuide7 = styled(ToolTipGuide)`
  background-image: url(${BGGuideLine7});
  width: 49px;
  height: 95px;
  top: 0;
  right: 0;
`;
