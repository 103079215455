import React, { useRef, useLayoutEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { BREAKPOINTS } from 'ui/constants';

interface MenuInformationPicProps {
  backgroundURL: string;
  foregroundURL?: string;
}

export const MenuInformationPic: React.FC<MenuInformationPicProps> = ({ children, backgroundURL, foregroundURL }) => {
  const containerEl = useRef(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const scrollTimeout = useRef(0);

  const scrollImage = () => {
    if (wrapperRef.current) {
      const el = wrapperRef.current;
      if (el.scrollLeft === 0) {
        el.scrollTo({
          top: 0,
          left: (el.scrollWidth - el.clientWidth) / 2,
          behavior: 'smooth',
        });
      }
    }
  };

  useLayoutEffect(() => {
    scrollTimeout.current = setTimeout(scrollImage, 500);

    return () => {
      clearTimeout(scrollTimeout.current);
    };
  }, []);

  return (
    <InfoWrapper ref={wrapperRef}>
      <Info ref={containerEl}>
        <InformationBackImg src={backgroundURL} onLoad={scrollImage} />
        {children}
        {foregroundURL && <InformationImg src={foregroundURL} />}
      </Info>
    </InfoWrapper>
  );
};

const scale = keyframes`
  from {
    transform: translate(-50%, -50%) scale(1);
  }

  to {
    transform: translate(-50%, -50%) scale(0.97);
  }
`;

const InfoWrapper = styled.div`
  overflow-x: auto;
  overflow-y: hidden;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    height: 100%;
  }
`;

const Info = styled.div`
  position: relative;
  display: block;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    min-width: 700px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    width: fit-content;
    height: 100%;
    min-width: 0;
  }

  > * {
    position: absolute;
  }

  > svg {
    transform: translate(-50%, -50%);

    @media (min-width: ${BREAKPOINTS.laptopSmallMin}px) {
      animation-name: ${scale};
      animation-duration: 0.8s;
      animation-direction: alternate;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }
  }
`;

const InformationBackImg = styled.img`
  position: relative;
  width: 100%;
  display: block;
  mix-blend-mode: multiply;
  pointer-events: none;

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    height: 100%;
    width: auto;
    max-width: none;
  }
`;

const InformationImg = styled.img`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;
