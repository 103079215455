import React from 'react';

import styled from 'styled-components';

import { ReactComponent as IconArrow45 } from './static/Arrow45.svg';
import { ReactComponent as IconArrow } from './static/Arrow.svg';
import { ReactComponent as IconCalc } from './static/Calc.svg';
import { ReactComponent as IconCheckbox } from './static/Checkbox.svg';
import { ReactComponent as IconClock } from './static/Clock.svg';
import { ReactComponent as IconClose } from './static/Close.svg';
import { ReactComponent as IconDocument } from './static/Document.svg';
import { ReactComponent as IconDone } from './static/Done.svg';
import { ReactComponent as IconDownload } from './static/Download.svg';
import { ReactComponent as IconDropdown } from './static/Dropdown.svg';
import { ReactComponent as IconExitFullscreen } from './static/Exit fullscreen.svg';
import { ReactComponent as IconExitTheatreMode } from './static/Exit theatre mode.svg';
import { ReactComponent as IconFatArrow } from './static/FatArrow.svg';
import { ReactComponent as IconFullscreen } from './static/Fullscreen.svg';
import { ReactComponent as IconMap } from './static/Map.svg';
import { ReactComponent as IconMenu } from './static/Menu.svg';
import { ReactComponent as IconMove } from './static/Move.svg';
import { ReactComponent as IconNext } from './static/Next.svg';
import { ReactComponent as IconPhone } from './static/Phone.svg';
import { ReactComponent as IconPlay } from './static/Play.svg';
import { ReactComponent as IconPlus } from './static/Plus.svg';
import { ReactComponent as IconQuestion } from './static/Question.svg';
import { ReactComponent as IconRadioButton } from './static/Radio button.svg';
import { ReactComponent as IconSettings } from './static/Settings.svg';
import { ReactComponent as IconSoundoff } from './static/Soundoff.svg';
import { ReactComponent as IconDots } from './static/Dots.svg';
import { ReactComponent as IconFiveDotsMenu } from './static/FiveDotsMenu.svg';
import { ReactComponent as IconChevron } from './static/chevron.svg';
import { ReactComponent as IconFacebook } from './static/Facebook.svg';
import { ReactComponent as IconInstagram } from './static/Instagram.svg';
import { ReactComponent as IconVkontakte } from './static/Vkontakte.svg';
import { ReactComponent as IconYoutube } from './static/Youtube.svg';

const ICONS = {
  IconArrow45,
  IconArrow,
  IconCalc,
  IconCheckbox,
  IconClock,
  IconClose,
  IconDocument,
  IconDone,
  IconDownload,
  IconDropdown,
  IconExitFullscreen,
  IconExitTheatreMode,
  IconFatArrow,
  IconFullscreen,
  IconMap,
  IconMenu,
  IconMove,
  IconNext,
  IconPhone,
  IconPlay,
  IconPlus,
  IconQuestion,
  IconRadioButton,
  IconSettings,
  IconSoundoff,
  IconDots,
  IconFiveDotsMenu,
  IconChevron,
  IconFacebook,
  IconInstagram,
  IconVkontakte,
  IconYoutube,
};

type ICONSKeys = keyof typeof ICONS;

interface IconPropsI {
  iconName: ICONSKeys;
  rotate?: number;
  size?: number;
  lighten?: boolean;
  darken?: boolean;
  color?: string;
  hoverColor?: string;
  className?: string;
}

export const Icon = styled.span.attrs<React.PropsWithChildren<IconPropsI>>(({ iconName }) => {
  const IconComponent = ICONS[iconName];
  return {
    children: <IconComponent />,
  };
})<React.PropsWithChildren<IconPropsI>>`
  height: ${(p) => p.size}px;
  width: ${(p) => p.size}px;
  transform: rotate(${(p) => p.rotate}deg);
  display: inline-block;

  color: ${(p) => p.color};
  :hover {
    color: ${(p) => p.hoverColor};
  }
  filter: ${(p) =>
    ({
      true: 'none',
      [String(p.lighten)]: 'brightness(10) saturate(0)',
      [String(p.darken)]: 'brightness(0) saturate(1)',
    }.true)};
`;

Icon.defaultProps = {
  rotate: 0,
  size: 32,
  lighten: false,
  darken: false,
};
