import React from 'react';
import styled from 'styled-components';
import { Router, Location } from '@reach/router';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { ScrollToTop } from 'utils/ScrollToTop';
import { CallbackForm } from 'ui/molecules/CallbackForm';

import { MainPage } from 'components/MainPage';
import { MenuPage } from 'components/MenuPage';
import { Calculator } from 'components/Calculator';
import { Certificates } from 'components/Certificates';
import { FAQ } from 'components/FAQ';
import { Warranty } from 'components/Warranty';
import { ObjectPage } from 'components/ObjectPage';
import { isSupportedBrowser } from 'utils/isSupportedBrowser';
import { UnsupportedBrowser } from 'components/UnsupportedBrowser';

const isExcluded = (pathname: string) =>
  pathname.startsWith('/calculator/details') ||
  (pathname.startsWith('/faq') && pathname !== '/faq') ||
  (pathname.startsWith('/menu') && pathname !== '/menu');

const supportedBrowser = isSupportedBrowser();

const TransitionRouter: React.FC = ({ children }) => (
  <TransitionStyled>
    <Location>
      {({ location }) => (
        <TransitionGroup>
          <CSSTransition
            key={location.key}
            classNames={isExcluded(location.pathname) ? 'no-transition' : 'router-transition'}
            timeout={isExcluded(location.pathname) ? 0 : 500}
          >
            {/* the only difference between a router animation and
                any other animation is that you have to pass the
                location to the router so the old screen renders
                the "old location" */}
            <Router location={location}>{children}</Router>
          </CSSTransition>
        </TransitionGroup>
      )}
    </Location>
  </TransitionStyled>
);

const WrappedApp: React.FC = ({ children }) => <>{children}</>;

export const App = () => (
  <WrappedApp>
    <TransitionRouter>
      <ScrollToTop path="/">
        <ObjectPage path="/objects/:objectId" />
        {supportedBrowser ? (
          <>
            <Calculator path="/calculator/*" />
            <MenuPage path="/menu/*" />
          </>
        ) : (
          <>
            <UnsupportedBrowser path="/calculator/*" />
            <UnsupportedBrowser path="/menu/*" />
          </>
        )}
        <Certificates path="/certificates" />
        <FAQ path="/faq" />
        <FAQ path="/faq/:faqPageId" />
        <FAQ path="/faq/:faqPageId/:faqId" />
        <Warranty path="/warranty" />
        <MainPage default />
      </ScrollToTop>
    </TransitionRouter>
    <Router>
      <CallbackForm path="*" />
    </Router>
  </WrappedApp>
);

const TransitionStyled = styled.div`
  position: relative;

  .no-transition {
    &-enter {
      &-active {
      }
    }

    &-exit {
      display: none;
      &-active {
      }
    }
  }

  .router-transition {
    &-enter {
      position: relative;
      opacity: 0.2;
      z-index: 1;
      left: -3vw;

      &-active {
        opacity: 1;
        left: 0;
        transition: opacity 450ms ease-in, left 300ms ease-in-out;
      }
    }

    &-exit {
      display: none;
      &-active {
      }
    }
  }
`;
