import styled from 'styled-components';

import { BREAKPOINTS } from 'ui/constants';

export const Title = styled.h2`
  font-size: 32px;
  font-weight: 400;
  line-height: 1;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 20px;
  }

  @media (max-width: ${BREAKPOINTS.phoneMax}px) {
    font-size: 16px;
  }
`;

export const TitleSecondary = styled.h3`
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  color: #98998f;

  @media (max-width: ${BREAKPOINTS.tabletMax}px) {
    font-size: 14px;
  }
`;
